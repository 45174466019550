import { createSlice } from "@reduxjs/toolkit";

import { LONG, SHORT } from "stateConstants";
import {
  BlotterState,
  SpotDeal,
  SpotDealPayload,
  Option,
  OptionPayload,
} from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";
import { spotDealDone } from "../spot/actions";
import { optionCreated, optionReachedMaturity, optionUpdated } from "./actions";

const initialState: BlotterState = {
  spot_blotter: [],
  options_blotter: [],
};

const blotterSlice = createSlice({
  initialState,
  name: "blotter",
  reducers: {},
  extraReducers: (builder) => {
    const parseOption: (option: OptionPayload) => Option = (option) => {
      return {
        ...option,
        initial_unit_premium: Number(option.initial_unit_premium),
        strike: Number(option.strike),
        volatility: Number(option.volatility),
      };
    };
    const parseDeal: (deal: SpotDealPayload) => SpotDeal = (deal) => {
      return {
        ...deal,
        price: Number(deal.price),
      };
    };

    const dealSorter = (a: SpotDeal, b: SpotDeal) => b.room_time - a.room_time;
    const optionSorter = (a: Option, b: Option) =>
      b.created_at_room_time - a.created_at_room_time;
    const insertSorted = <T>(
      arr: T[],
      item: T,
      sorter: (a: T, b: T) => number
    ) => {
      let i = 0;
      while (i < arr.length && sorter(item, arr[i]) > 0) {
        i += 1;
      }
      arr.splice(i, 0, item);
    };

    builder
      .addCase(retrieveInitialData, (state, action) => {
        const {
          spot_blotter,
          options_blotter: [swiftOption],
        } = action.payload;

        state.spot_blotter = spot_blotter.map(parseDeal).sort(dealSorter);
        state.options_blotter = [
          ...swiftOption.options[SHORT],
          ...swiftOption.options[LONG],
        ]
          .map(parseOption)
          .sort(optionSorter);
      })

      .addCase(spotDealDone, (state, action) => {
        const parsedDeal = parseDeal(action.payload.deal);
        insertSorted(state.spot_blotter, parsedDeal, dealSorter);
      })

      .addCase(optionCreated, (state, action) => {
        const parsedOption = parseOption(action.payload.option);
        insertSorted(state.options_blotter, parsedOption, optionSorter);
        // state.options_blotter.push(parsedOption)
      })

      .addCase(optionUpdated, (state, action) => {
        const { option } = action.payload;
        const index = state.options_blotter.findIndex(
          (o) => o.id === option.id
        );
        if (index === -1) {
          return;
        }

        state.options_blotter[index] = parseOption(option);
      })

      .addCase(optionReachedMaturity, (state, action) => {
        const { option } = action.payload;
        const index = state.options_blotter.findIndex(
          (o) => o.id === option.id
        );
        if (index === -1) {
          return;
        }

        state.options_blotter[index] = parseOption(option);
      })

      .addCase(clearRoom, () => {
        return initialState;
      });
  },
});

export default blotterSlice.reducer;

import { DeltaHedge, SpotState } from "types";

export const initialState: () => SpotState = () => ({
  swiftId: 0,
  askOffers: [],
  bidOffers: [],
  ebOrders: [],
  volEbOrders: [],
  spotChartValues: [],
  spotChartOHLC: {},
  sticky_model: 0,
  selectedDeltaKey: 0.4999,
  delta_strike_values: [0.1, 0.25, 0.5, 0.75, 0.9],
  tradableVols: {},
  selectedDelta: DeltaHedge.ATM,
  selectedSellTenor: undefined,
  selectedBuyTenor: undefined,
  spotQuantity: 100,
  validSpotQuantity: true,
  volQuantity: 100,
  validVolQuantity: true,
  initial_state_loaded: false,
  tenors: {},
  visible_tenors: {},
  visible_vol_eb_tenors: {},
  visible_delta_strike_values: {},
  visible_vol_eb_delta_strike_values: {},
  swifts: [],
});

import { Skeleton } from "antd";
import { Row } from "components/reusable/GenericStyledComponents";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectBestBidAndAsk } from "store/newpricer/selectors";
import { RootState } from "store/rootReducer";
import { setDeltaHedge } from "store/spot/reducers";
import { useAppDispatch, getState } from "store/store";
import styled, { css } from "styled-components";
import { precision } from "utils/numbers";

const OptionStrikeButtons: React.FC = () => {
  type DeltaStrikeButtonsProps = {
    type: "Buy" | "Sell";
  };

  const DeltaStrikeButtons: React.FC<DeltaStrikeButtonsProps> = ({ type }) => {
    const {
      delta_strike_values,
      selectedDelta,
      visible_vol_eb_delta_strike_values,
      initial_state_loaded,
      swiftId,
    } = useSelector(
      (state: RootState) => ({
        delta_strike_values: state.spot.delta_strike_values,
        selectedDelta: state.spot.selectedDelta,
        visible_vol_eb_delta_strike_values:
          state.spot.visible_vol_eb_delta_strike_values,
        initial_state_loaded: state.spot.initial_state_loaded,
        swiftId: state.spot.swiftId,
      }),
      shallowEqual
    );
    const { bestBid, bestAsk } = selectBestBidAndAsk(getState());

    const dispatch = useAppDispatch();
    const middlePoint =
      bestBid && bestAsk ? (bestBid?.price + bestAsk?.price) / 2 : 0;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".125rem",
        }}
      >
        {visible_vol_eb_delta_strike_values[swiftId]
          .slice()
          .sort((a, b) => a - b)
          .filter((index: any) => {
            const deltaValue = delta_strike_values[index];
            return type === "Sell"
              ? deltaValue < middlePoint
              : deltaValue >= middlePoint;
          })
          .map((index: any) => {
            const deltaValue = delta_strike_values[index];
            if (deltaValue && initial_state_loaded)
              return (
                <DeltaButton
                  isCall={middlePoint > deltaValue}
                  key={deltaValue}
                  selected={deltaValue === selectedDelta}
                  onClick={() => {
                    dispatch(setDeltaHedge({ selectedDelta: deltaValue }));
                  }}
                >
                  {deltaValue}
                </DeltaButton>
              );
            return <Skeleton />;
          })}
      </div>
    );
  };

  return (
    <>
      <DeltaContainer type="Sell">
        <Title type="Sell">PUTS</Title>
        <DeltaRow>
          <DeltaStrikeButtons type="Sell" />
        </DeltaRow>
      </DeltaContainer>
      <DeltaContainer type="Buy">
        <Title type="Buy">CALLS</Title>
        <DeltaRow>
          <DeltaStrikeButtons type="Buy" />
        </DeltaRow>
      </DeltaContainer>
    </>
  );
};

const Title = styled.div<{ type: "Sell" | "Buy" }>`
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  cursor: default;

  ${(props) => {
    if (props.type === "Buy") {
      return css`
        text-align: right;
      `;
    }
  }}
`;

const DeltaRow = styled.div`
  display: flex;
  gap: 10px;
  direction: ltr;
`;

const DeltaButton = styled.div<{ selected: boolean; isCall: boolean }>`
  padding: 1px 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  ${(props) => {
    if (props.selected) {
      return css`
        border-color: #4d96c1;
        background: ${props.isCall ? "#c0354890" : "#007e4d90"};
      `;
    }
    return css`
      background: ${props.isCall ? "#c0354830" : "#007e4d30"};
    `;
  }}

  &:hover {
    ${(props) => {
      if (props.selected) {
        return css`
          background: ${props.isCall ? "#c03548" : "#007e4d"};
        `;
      }
      return css`
        background: ${props.isCall ? "#c0354850" : "#007e4d50"};
      `;
    }}
  }
`;

const DeltaContainer = styled.div<{ type: "Buy" | "Sell" }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 16px;
  gap: 0.75rem;

  ${(props) => {
    if (props.type === "Buy") {
      return css`
        direction: rtl;
      `;
    }
  }}
`;

export default OptionStrikeButtons;

import React, { useEffect, useState } from "react";
import { getFromLS, setToLS } from "utils";
import styled from "styled-components";
import { Row } from "components/reusable/GenericStyledComponents";
import AdminScenario from "components/Admin/Scenario/AdminScenario";
import AdminNav from "components/Admin/AdminNav";
import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import { adminService } from "services";
import { getTrainerScenarios } from "store/trainer/reducers";
import AdminCustomersPanel from "components/Admin/Customers/AdminCustomersPanel";
import { AdminScenarioBE, Customer } from "types";

const initNav = () => {
  const nav = getFromLS("adminnav");
  return nav !== null ? nav : 0;
};

const Admin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [customers, setCustomers] = useState<Customer[]>([]);

  // const { scenarios } = useSelector((state: RootState) => state.trainer);
  const [scenarios, setScenarios] = useState<AdminScenarioBE[]>([]);
  const fetchScenarios = async () => {
    const sc = await adminService.getAllScenarios();
    setScenarios(sc);
    // dispatch(getTrainerScenarios(sc));
  };

  const fetchCustomers = async () => {
    const _customers = await adminService.getAllCustomers();
    setCustomers(_customers);
  };

  useEffect(() => {
    fetchScenarios();
    fetchCustomers();
  }, []);

  const [selectedNav, setSelectedNav] = useState(initNav());
  const changeNav = (idx: number) => {
    setToLS("adminnav", idx);
    setSelectedNav(idx);
  };

  return (
    <Row style={{ height: "100vh" }}>
      <AdminNav activeNav={selectedNav} changeNav={changeNav} />

      <PanelContainer>
        {selectedNav === 0 && (
          <AdminScenario
            scenarios={scenarios}
            fetchScenarios={fetchScenarios}
          />
        )}
        {selectedNav === 1 && customers.length && (
          <AdminCustomersPanel customers={customers} />
        )}
      </PanelContainer>
    </Row>
  );
};

const PanelContainer = styled(Row)`
  padding: 36px 21px;
  height: 100%;
  overflow: auto;
`;
export default Admin;

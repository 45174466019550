import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { roomsService } from "services/rooms.service";
import { ROOM_TYPES, Room, RoomStatus, TrainerScenarioBE } from "types";
import SmallLogo from "assets/small_logo.svg";
import MultiPlayerSessionCard from "./MultiPlayerSessionCard";
import { roomService, trainerService } from "services";
import { Button, Card, Space, Table, Tabs, message } from "antd";
import StyledText from "components/reusable/StyledText";
import { useHistory } from "react-router";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import SinglePlayerSessionCard from "./SinglePlayerSessionCard";
import LobbyTournaments from "components/Tournament/LobbyTournaments";
import { Column } from "components/reusable/GenericStyledComponents";

const { TabPane } = Tabs;

const SessionsList: React.FC = () => {
  const history = useHistory();

  const [rooms, setRooms] = useState<Room[]>([]);
  const [scenarios, setScenarios] = useState<TrainerScenarioBE[]>([]);
  const [loadingNewSession, setLoadingNewSession] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("lobbyActiveTab") || "1"
  );
  const handleTabChange = (key: string) => {
    localStorage.setItem("lobbyActiveTab", key);
    setActiveTab(key);
  };
  const getAvailableRooms = async () => {
    const availableRooms = await roomsService.getRooms();
    setRooms(availableRooms);
  };

  const getAvailableScenarios = async () => {
    const availableScenarios = await trainerService.getTrainerScenarios();
    setScenarios(availableScenarios);
  };

  useEffect(() => {
    getAvailableRooms();
    getAvailableScenarios();
    const roomsInterval = setInterval(getAvailableRooms, 15 * 1000);

    return () => {
      clearInterval(roomsInterval);
    };
  }, []);

  return (
    <Container>
      <StyledTabs defaultActiveKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Your sessions" key="1">
          <Column style={{ height: "70vh", overflowY: "auto" }}>
            {rooms
              .filter((room) => room.status !== RoomStatus.FINISHED)
              .sort((a, b) => {
                const dateA = new Date(a.created);
                const dateB = new Date(b.created);
                // eslint-disable-next-line no-nested-ternary
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
              })
              .map((room) => {
                if (room.room_type === ROOM_TYPES.MULTIPLAYER)
                  return (
                    <MultiPlayerSessionCard
                      key={room.uuid}
                      room={room}
                      teacherPhoto={SmallLogo}
                    />
                  );
                return (
                  <SinglePlayerSessionCard
                    key={room.uuid}
                    room={room}
                    teacherPhoto={SmallLogo}
                  />
                );
              })}
          </Column>
        </TabPane>
        <TabPane tab="Scenarios" key="2">
          {loadingNewSession ? (
            <LoadingSpinner />
          ) : (
            <ScenariosContainer>
              <SessionsTable
                loading={!scenarios}
                rowKey="created"
                style={{ width: "100%" }}
                columns={[
                  {
                    dataIndex: "name",
                    title: "Scenario name",
                  },
                  {
                    // filters: assetClasses.map((ac) => {
                    //   return { text: ac, value: ac };
                    // }),
                    // onFilter: (value: string | number | boolean, record) => {
                    //   return record.swift[0].asset_class === value;
                    // },
                    dataIndex: "asset_class",
                    title: "Asset class",
                    render: (_, record: any) => (
                      <Space size="middle">{record.swift[0].asset_class}</Space>
                    ),
                  },
                  {
                    // filters: instruments.map((ac) => {
                    //   return { text: ac, value: ac };
                    // }),
                    // onFilter: (value: string | number | boolean, record) => {
                    //   return record.swift[0].code === value;
                    // },
                    dataIndex: "instrument",
                    title: "Instrument",
                    render: (_, record: any) => (
                      <Space size="middle">{record.swift[0].code}</Space>
                    ),
                  },
                  {
                    dataIndex: "duration",
                    title: "Duration",
                    render: (_, record: any) => (
                      <Space size="middle">{`${
                        record.duration / 3600
                      }h`}</Space>
                    ),
                  },
                  {
                    title: "Actions",
                    render: (_, record: any) => (
                      <Space size="middle">
                        <Button
                          onClick={async (e) => {
                            e.stopPropagation();
                            try {
                              setLoadingNewSession(true);
                              const roomId =
                                await roomService.createSinglePlayerSession(
                                  record.id
                                );
                              history.push("/game/" + roomId);
                            } catch (error) {
                              setLoadingNewSession(false);
                              message.warning("Could not start the session!");
                            }
                          }}
                        >
                          <StyledText
                            fontSize={14}
                            fontWeight={400}
                            color="#FFFFFF"
                            fontFamily="IBMPlexSans"
                          >
                            Play
                          </StyledText>
                        </Button>
                      </Space>
                    ),
                  },
                ]}
                dataSource={scenarios}
                pagination={false}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <Space size="middle" style={{ margin: 0 }}>
                      {record.description}
                    </Space>
                  ),
                }}
              />
            </ScenariosContainer>
          )}
        </TabPane>
        <TabPane tab="Tournaments" key="3" style={{ height: "100%" }}>
          <LobbyTournaments />
        </TabPane>
      </StyledTabs>
    </Container>
  );
};
const SessionsTable = styled(Table)`
  /* Add a border around the table */
  // border: 1px solid #e8e8e8;

  /* Style for the table header */
  .ant-table-thead > tr > th {
    background-color: #00000090;
    font-weight: bold;
  }

  /* Style for each table row */
  .ant-table-tbody > tr > td {
    background-color: #00000090;
  }

  /* Style for the table footer, if you have one */
  .ant-table-footer {
    background-color: #00000090;
  }

  /* Style for hover state on table rows */
  .ant-table-tbody > tr:hover > td {
    background-color: #00000080;
  }
`;

const StyledTabs = styled(Tabs)`
  height: 70%;
  // Add your styles here
  .ant-tabs-nav {
    // Styles for the tabs header
  }
  .ant-tabs-tab {
    // Styles for each tab
  }
  .ant-tabs-tab-active {
    // Styles for active tab
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 45rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.7);
  padding: 1rem;
  background: ${(props) => props.theme.color0};
`;
const ScenariosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export default SessionsList;

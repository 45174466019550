import React, { ChangeEvent, useEffect, useState } from "react";

import styled from "styled-components";

import { RFQ_WAITING, SELL, BUY, VOLATILITY_QUOTE } from "stateConstants";
import { useAppDispatch } from "store/store";
import { rfqAnswerCall, rfqNtgCall } from "store/rfq/actions";
import { div10, precision, randint } from "utils/numbers";
import { CommonPayloadRFQ, RFQCall } from "types";
import NumberInput from "../../reusable/NumberInput";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { roomsService } from "services/rooms.service";
import { timeAbsoluteTenorToUnix } from "utils/time";
import { linkCallToPricer, setPricer } from "store/newpricer/actions";
import ToPricerButton from "components/reusable/ToPricerButton";

interface Props {
  rfq: RFQCall;
}

const IncomingActions: React.FC<Props> = ({ rfq }) => {
  const dispatch = useAppDispatch();
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const [bid, setBid] = useState(
    rfq.quote_type === VOLATILITY_QUOTE
      ? rfq.strategy_volatility_bid * 100
      : rfq.strategy_price_bid
  );
  const [ask, setAsk] = useState(
    rfq.quote_type === VOLATILITY_QUOTE
      ? rfq.strategy_volatility_ask * 100
      : rfq.strategy_price_ask
  );
  const [callSent, setCallSent] = useState(false);

  const inputsAreInvalid = !bid || !ask;
  const isWaiting = rfq.status === RFQ_WAITING;

  const handleKeyPress = (
    key: string,
    setter: any,
    value: any,
    enterFn?: any
  ) => {
    let power = 0;
    if (rfq.quote_type === VOLATILITY_QUOTE) power = 2;
    else power = sticky_model ? SPOT_DECIMAL_PLACES : 2;
    switch (key) {
      case "ArrowUp":
        setter(precision(parseFloat(value) + 5 / 10 ** power, power));
        break;
      case "ArrowDown":
        setter(precision(parseFloat(value) - 5 / 10 ** power, power));
        break;
      case "ArrowLeft":
        setter(precision(parseFloat(value) - 1 / 10 ** power, power));
        break;
      case "ArrowRight":
        setter(precision(parseFloat(value) + 1 / 10 ** power, power));
        break;
      case "Enter":
        if (enterFn) enterFn();
        break;
    }
  };

  return (
    <Container>
      {callSent && (
        <Info>
          <Group>
            <Label>Bid:</Label>
            {rfq.quote_type === VOLATILITY_QUOTE
              ? `${bid}%`
              : `${rfq.strategy_price_bid}$`}
          </Group>
          <Group>
            <Label>Ask:</Label>
            {rfq.quote_type === VOLATILITY_QUOTE
              ? `${ask}%`
              : `${rfq.strategy_price_ask}$`}
          </Group>
        </Info>
      )}

      <ActionsContainer>
        <ToPricerButton
          call={rfq}
          callId={`${rfq.id}_rfq`}
          isWaiting={rfq.status === RFQ_WAITING}
          sticky_model={sticky_model}
        />
        <RedButton
          disabled={!isWaiting || callSent}
          onClick={() => {
            dispatch(rfqNtgCall({ callId: rfq.id }));
          }}
        >
          NTG
        </RedButton>
      </ActionsContainer>
    </Container>
  );
};

const Container = styled.div`
  // background: #272e33; // layout
  padding: 10px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Label = styled.span`
  color: #ebebeb80;
`;

const ActionButton = styled.button`
  width: 2rem;
  margin: 0;
  padding: 4px;
  border-radius: initial;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.2);

  &:disabled {
    color: #ebebeb80;
    border-color: #ebebeb80;
  }
`;

const RedButton = styled(ActionButton)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: #ff7875 !important;
    border: 1px solid #ff7875 !important;
  }
`;

const Input = styled(NumberInput)`
  background-color: #000;
  padding-right: 5px;
  position: relative;
`;

const Info = styled.div`
  display: flex;
  gap: 10px;
`;

export default IncomingActions;

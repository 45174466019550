import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Close from "assets/close.svg";
import GenericModal from "./GenericModal";
import { Column, Row } from "../reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import { RootState } from "store/rootReducer";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import { MATURITY, Option } from "types";
import { MONTHS, timeBEtoPretty, timeUnixToDatePretty } from "utils/time";
import { CALL, PUT } from "stateConstants";
import { selectBestBidAndAsk } from "store/newpricer/selectors";
import { getState } from "store/store";
import { useRoomTimeContext } from "contexts/RoomTimeProvider";
import { numberWithCommas } from "utils/numbers";

type Props = {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};
const convertDelta: any = {
  0.1: "10dP",
  0.25: "25dP",
  0.5: "ATM",
  0.75: "25dC",
  0.9: "10dC",
};
type parsedOptions = {
  [key: string]: {
    [key: string]: { amount: string; nb_options: number };
  };
};

type newOptionsType = {
  PUT: parsedOptions;
  CALL: parsedOptions;
};

const tenorToYearMonth = (tenor: string) => {
  const date = new Date(tenor);

  return `${MONTHS[date.getMonth()]}/${date.getFullYear()}`;
};

// const dltskColumnNotEmpty = (options: parsedOptions, dltsk: any) => {
//   const col = Object.keys(options).filter((tenor) => {
//     if (options[tenor][delta_strike].amount === "-") return false;
//     return true;
//   });
//   return col.length !== 0;
// };
const tenorRowNotEmpty = (options: parsedOptions, tenor: string) => {
  if (!options[tenor]) return false;
  const row = Object.keys(options[tenor]).filter((delta_strike) => {
    if (options[tenor][delta_strike].amount === "-") return false;
    return true;
  });
  return row.length !== 0;
};
const getBin = (bins: number[][], strike: number, strikes: number[]) => {
  for (let i = 0; i < bins.length; i += 1) {
    if (strike >= bins[i][0] && strike < bins[i][1]) {
      return strikes[i];
    }
  }
  if (strike < bins[0][0]) return strikes[0];
  return strikes[strikes.length - 1];
};

const getClosestTenor = (goal: number, tenors: number[]) => {
  if (goal < 0) return -1;
  const tenor = tenors.reduce((prev: number, curr: number) => {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });
  return tenor;
};

const updateNewOptionsValue = (
  currentValue: string,
  change: number
): string => {
  const initialNumberValue =
    currentValue === "-" ? 0 : parseInt(currentValue, 10);
  const changedNumberValue = initialNumberValue + change;
  if (currentValue === "-" && changedNumberValue === 0) return "-";
  return changedNumberValue === 0 ? "0" : changedNumberValue.toString();
};

const PositionGridModal: React.FC = () => {
  const { options_blotter } = useSelector((state: RootState) => state.blotter);
  const { sticky_model, initial_state_loaded } = useSelector(
    (state: RootState) => ({
      sticky_model: state.spot.sticky_model,
      initial_state_loaded: state.spot.initial_state_loaded,
    }),
    shallowEqual
  );
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const { bestBid, bestAsk } = selectBestBidAndAsk(getState());
  const { traderId } = useSelector((state: RootState) => state.user);
  const { gameTime, getRoomDays } = useRoomTimeContext();

  const { REAL_SECONDS_TO_GAME_DAYS_RATIO } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const nb_bins = 11;

  const strikes = sticky_model
    ? [...Array(nb_bins).keys()].map((n) =>
        Math.trunc(n - Math.floor(nb_bins / 2))
      )
    : [...new Set(options_blotter.map((o: Option) => o.strike))];
  const tenors = sticky_model
    ? [
        ...(Object.values(MATURITY).filter(
          (v) => typeof v === typeof 0
        ) as number[]),
      ]
    : [...new Set(options_blotter.map((o) => o.tenor))];

  const atm = ((bestBid?.price || 0) + (bestAsk?.price || 0)) / 2;
  const unit = atm / 100;
  const currentTime = Math.floor(gameTime / REAL_SECONDS_TO_GAME_DAYS_RATIO);

  let bins: number[][] = [];
  if (sticky_model) {
    bins = strikes
      .map((s) => [atm + unit * s, atm + unit * (s + 1)])
      .sort((a, b) => a[0] - b[0]);

    if (bins.length) {
      // eslint-disable-next-line
      const middle = nb_bins >> 1;
      bins[middle] = [atm - atm / 500, atm + atm / 500];
      bins[middle - 1][1] = atm - atm / 500;
      bins[middle + 1][0] = atm + atm / 500;
    }
  } else {
    bins = [];
  }

  const parseOptions = (opt: Option[]) => {
    if (!strikes.length || !tenors.length) {
      return { PUT: {}, CALL: {} };
    }

    const newOptions: newOptionsType = { PUT: {}, CALL: {} };
    tenors.forEach((tenor) => {
      newOptions.PUT[tenor] = {};
      newOptions.CALL[tenor] = {};

      strikes.forEach((strike) => {
        newOptions.PUT[tenor][strike] = { amount: "-", nb_options: 0 };
        newOptions.CALL[tenor][strike] = { amount: "-", nb_options: 0 };
      });
    });

    opt.forEach((option) => {
      const {
        market_maker_id,
        put_or_call,
        amount, // this is a number
        strike,
        room_time_tenor,
      } = option;
      let { tenor } = option;

      if (sticky_model) {
        tenor = getClosestTenor(
          Math.floor(room_time_tenor / REAL_SECONDS_TO_GAME_DAYS_RATIO) -
            currentTime,
          tenors as number[]
        );
        if (tenor === -1) return;
        const idx = getBin(bins, strike, strikes);
        if (put_or_call === PUT) {
          newOptions.PUT[tenor][idx] = {
            amount: updateNewOptionsValue(
              newOptions.PUT[tenor][idx].amount,
              traderId === market_maker_id ? amount * -1 : amount
            ),
            nb_options: newOptions.PUT[tenor][idx].nb_options + 1,
          };
        } else {
          newOptions.CALL[tenor][idx] = {
            amount: updateNewOptionsValue(
              newOptions.CALL[tenor][idx].amount,
              traderId === market_maker_id ? amount * -1 : amount
            ),
            nb_options: newOptions.CALL[tenor][idx].nb_options + 1,
          };
        }
      } else {
        // eslint-disable-next-line
        if (put_or_call === PUT) {
          newOptions.PUT[tenor][strike] = {
            amount: updateNewOptionsValue(
              newOptions.PUT[tenor][strike].amount,
              traderId === market_maker_id ? amount * -1 : amount
            ),
            nb_options: newOptions.PUT[tenor][strike].nb_options + 1,
          };
        } else {
          newOptions.CALL[tenor][strike] = {
            amount: updateNewOptionsValue(
              newOptions.CALL[tenor][strike].amount,
              traderId === market_maker_id ? amount * -1 : amount
            ),
            nb_options: newOptions.CALL[tenor][strike].nb_options + 1,
          };
        }
      }
    });

    return newOptions;
  };
  const [parsedOptions, setParsedOptions] = useState(
    parseOptions(options_blotter)
  );

  useEffect(() => {
    if (initial_state_loaded) {
      setParsedOptions({
        ...parseOptions([
          ...options_blotter.filter(
            (o) =>
              o.room_time_tenor / REAL_SECONDS_TO_GAME_DAYS_RATIO >
              getRoomDays()
          ),
        ]),
      });
    }
  }, [options_blotter.length, strikes.length, tenors.length, getRoomDays()]);

  return (
    <Column style={{ gap: "2rem" }}>
      <PositionGridTable
        title="PUT"
        strikes={strikes}
        tenors={tenors}
        parsedOptions={parsedOptions.PUT}
        sticky_model={sticky_model}
        SPOT_DECIMAL_PLACES={SPOT_DECIMAL_PLACES}
      />
      <PositionGridTable
        title="CALL"
        strikes={strikes}
        tenors={tenors}
        parsedOptions={parsedOptions.CALL}
        sticky_model={sticky_model}
        SPOT_DECIMAL_PLACES={SPOT_DECIMAL_PLACES}
      />
    </Column>
  );
};

const PositionGridTable = ({
  strikes,
  tenors,
  parsedOptions,
  sticky_model,
  SPOT_DECIMAL_PLACES,
  title,
  bins,
}: {
  strikes: number[];
  tenors: (string | number)[];
  parsedOptions: parsedOptions;
  sticky_model: any;
  SPOT_DECIMAL_PLACES: number;
  title: string;
  bins?: number;
}) => {
  return (
    <div>
      <Row
        style={{
          marginLeft: "1rem",
        }}
      >
        <StyledText
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
          fontSize={18}
          fontWeight={400}
        >
          {title}
        </StyledText>
      </Row>
      <Table>
        <thead>
          <tr>
            <TableHeader>Maturity/Strike</TableHeader>
            {React.Children.toArray(
              sticky_model
                ? strikes.map((d) => {
                    return (
                      <TableHeader>{d === 0 ? "ATM" : `${d}%`}</TableHeader>
                    );
                  })
                : strikes
                    // .filter(d => dltskColumnNotEmpty(parsedOptions, d))
                    .sort((a, b) => a - b)
                    .map((d) => {
                      return (
                        <TableHeader>
                          {d.toFixed(SPOT_DECIMAL_PLACES)}
                        </TableHeader>
                      );
                    })
            )}
          </tr>
        </thead>
        <tbody>
          {sticky_model
            ? React.Children.toArray(
                tenors
                  .filter((t) => tenorRowNotEmpty(parsedOptions, t as string))
                  .map((tenor) => {
                    return (
                      <TableRow>
                        <TableData style={{ background: "#00000030" }}>
                          {MATURITY[tenor as number]}
                        </TableData>
                        {React.Children.toArray(
                          strikes.map((delta_strike) => {
                            return (
                              <TableData>
                                {parsedOptions[tenor][delta_strike].amount ===
                                "-"
                                  ? "-"
                                  : `${numberWithCommas(
                                      parsedOptions[tenor][delta_strike].amount
                                    )} (${
                                      parsedOptions[tenor][delta_strike]
                                        .nb_options
                                    })`}
                              </TableData>
                            );
                          })
                        )}
                      </TableRow>
                    );
                  })
              )
            : React.Children.toArray(
                tenors
                  .sort((a: any, b: any) => {
                    return new Date(a).getTime() - new Date(b).getTime();
                  })
                  .filter((t) => tenorRowNotEmpty(parsedOptions, t as string))
                  .map((tenor) => {
                    return (
                      <TableRow>
                        <TableData style={{ background: "#00000030" }}>
                          {timeUnixToDatePretty(tenor as number)}
                        </TableData>
                        {React.Children.toArray(
                          strikes
                            .sort((a, b) => a - b)
                            .map((delta_strike) => {
                              return (
                                <TableData key={`${tenor}${delta_strike}`}>
                                  {parsedOptions[tenor][delta_strike].amount ===
                                  "-"
                                    ? "-"
                                    : `${numberWithCommas(
                                        parsedOptions[tenor][delta_strike]
                                          .amount
                                      )} (${
                                        parsedOptions[tenor][delta_strike]
                                          .nb_options
                                      })`}
                                </TableData>
                              );
                            })
                        )}
                      </TableRow>
                    );
                  })
              )}
        </tbody>
      </Table>
    </div>
  );
};

const Table = styled.table`
  background: transparent;
  width: 100%;
  padding: 11px 18px;

  & tbody tr:nth-child(odd) {
    // background: #192025; // layout
  }

  & tr th:first-child,
  & tbody tr td:first-child {
    padding-left: 18px;
  }

  & tr th {
    padding-bottom: 10px;
  }
`;

const TableRow = styled.tr`
  padding: 10px;
  text-align: center;
`;

const TableData = styled.td`
  padding: 5px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid gray;
  background: #00000010;
`;

const TableHeader = styled.th`
  padding: 5px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid gray;
  font-weight: 800;
  background: #00000030;
`;
export default PositionGridModal;

import { message } from "antd";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { TraderReportsType } from "types/reports";

export default class ReportsService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.httpClient.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  };

  private handleResponse = (response: AxiosResponse) => response;

  private handleError = (error: AxiosError) => {
    // Handle the error as needed
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      message.error(error.response.data?.error || error.response.statusText);
    } else if (error.request) {
      // The request was made but no response was received
      message.error("No response was received from the server.");
    } else {
      // Something happened in setting up the request that triggered an Error
      message.error("Error in setting up the request.");
    }

    return Promise.reject(error);
  };

  async getRoomReports(room_id: string): Promise<any[]> {
    const response = await this.httpClient.get<any[]>(
      `/api/reports/${room_id}`
    );
    return response.data;
  }

  async getTraderReports(
    room_id: string,
    trader_id: string
  ): Promise<TraderReportsType> {
    const response = await this.httpClient.get<TraderReportsType>(
      `/api/reports/${room_id}/${trader_id}`
    );
    return response.data;
  }
}

export const reportsService = new ReportsService(axios);

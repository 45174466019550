import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { retrieveInitialData } from "store/room/actions";
import { InitialDataPayload, Tournament } from "types";

type LayoutState = {
  selectedTournament: Tournament | null;
};

const initialState: LayoutState = {
  selectedTournament: null,
};

const lobbySlice = createSlice({
  initialState,
  name: "lobby",
  reducers: {
    setSelectedTournament(state, action: PayloadAction<Tournament>) {
      state.selectedTournament = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      retrieveInitialData,
      (state, action: PayloadAction<InitialDataPayload>) => {}
    );
  },
});

export const { setSelectedTournament } = lobbySlice.actions;
export default lobbySlice.reducer;

import { Button, Card, Form, Input, Typography, message } from "antd";
import { Column } from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { authService } from "services";
import { refreshToken } from "store/auth/actions";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { userSuccessAuth } from "store/user/reducers";
import styled from "styled-components";
import { User } from "types";

const EmailVerification: React.FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [newEmail, setNewEmail] = useState<string>("");
  const [loadingResend, setLoadingResend] = useState<boolean>(false);
  const [successResend, setSuccesResend] = useState<boolean>(false);
  const [failResend, setFailResend] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user);
  console.log({ user });

  // const = location.state as {
  //   from: { pathname: string };
  //   user: User;
  // } | null;

  if (!user) return <Redirect to="/" />;

  return (
    <Column>
      <LogoLink to="/" style={{ padding: "1rem" }}>
        <img src="/logo.png" alt="logo" />
      </LogoLink>
      <Container>
        <Card
          style={{
            width: "50%",
            height: "70%",
          }}
        >
          <Column
            style={{
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Typography.Text>
              Dear {user.username}, Please check your email ({user.email}) in
              order to activate your account.
            </Typography.Text>
            <Typography.Text>
              In case you did not receive the email, click the button bellow to
              send it again.
            </Typography.Text>
            <Button
              disabled={successResend}
              loading={loadingResend}
              danger={failResend}
              type={successResend ? "primary" : "default"}
              style={{ marginTop: "1rem" }}
              onClick={async () => {
                setLoadingResend(true);
                try {
                  await authService.sendEmailVerification();
                  setSuccesResend(true);
                  message.info("Email sent!");
                } catch {
                  setSuccesResend(false);
                  setFailResend(true);
                }
                setLoadingResend(false);
              }}
            >
              {successResend ? "Email sent!" : "Resend email"}
            </Button>

            <Typography.Text>
              In case you want to change your email, type in the box bellow and
              press "Change email". The verification email will be sent
              automatically.
            </Typography.Text>
            <Form form={form} layout="vertical">
              <Form.Item
                name="email"
                label="Email"
                // initialValue={user.email}
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Enter new email"
                />
              </Form.Item>
            </Form>
            <Button
              loading={loadingResend}
              style={{ marginTop: "1rem" }}
              onClick={async () => {
                try {
                  setLoadingResend(true);
                  await authService.editUserEmailVerification({
                    email: newEmail,
                  });

                  console.log("refresh", user.refreshToken);
                  // dispatch(refreshToken(user.refreshToken));
                  // await dispatch(userSuccessAuth({ access, refresh, name: "" }));
                  message.info(`Your email is set to ${newEmail}!`);
                  history.push("/");
                } catch {
                  history.push("/");
                }
              }}
            >
              Change email
            </Button>
            <Button
              style={{ marginTop: "1rem" }}
              onClick={async () => {
                await authService.logout();
                history.push("/");
              }}
            >
              Cancel
            </Button>
          </Column>
        </Card>
      </Container>
    </Column>
  );
};

const Container = styled(Column)`
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const LogoLink = styled(Link)`
  margin-right: auto;
`;

export default EmailVerification;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "types";
import { accessTokenKey, refreshTokenKey } from "utils/constants";

const initialState: AuthState = {
  authError: "",
  registerError: "",
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    userFailAuth(state, action: PayloadAction<{ error: string }>) {
      state.authError = action.payload.error;
      localStorage.removeItem(accessTokenKey);
      localStorage.removeItem(refreshTokenKey);
    },
    userFailRegister(state, action: PayloadAction<{ error: string }>) {
      state.registerError = action.payload.error;
    },
  },
});

export const { userFailAuth, userFailRegister } = authSlice.actions;
export default authSlice.reducer;

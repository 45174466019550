import { createAction } from "@reduxjs/toolkit";
import { OptionCreatedPayload, OptionTogglePayload } from "types";
import {
  OPTION_CREATED,
  OPTION_REACHED_MATURITY,
  OPTION_TOGGLE,
  OPTION_UPDATED,
} from "./constants";

export const optionCreated = createAction<OptionCreatedPayload>(OPTION_CREATED);
export const optionUpdated = createAction<OptionCreatedPayload>(OPTION_UPDATED);
export const optionReachedMaturity = createAction<OptionCreatedPayload>(
  OPTION_REACHED_MATURITY
);
export const optionToggle = createAction<OptionTogglePayload>(OPTION_TOGGLE);

import { createAction } from "@reduxjs/toolkit";
import {
  // VLTSurfaceUpdatedPayload,
  VolatilityChartsUpdatedPayload,
} from "types";
import { VLT_SURFACE_UPDATED } from "./constants";

// export const volatilitySurfaceUpdated =
//   createAction<VLTSurfaceUpdatedPayload>(VLT_SURFACE_UPDATED);
export const volChartUpdated = createAction<any>(
  "volatility/ChartVolatilityUpdated"
);
export const volChartsUpdated = createAction<VolatilityChartsUpdatedPayload>(
  "volatility/ChartsVolatilityUpdated"
);

import React, {
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";

import { NumberInputWithArrows } from "components/reusable/NumberInput";
import { spotPlaceOrder } from "store/spot/actions";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { selectBestBidAndAsk } from "store/newpricer/selectors";
import { BID, ASK } from "stateConstants";
import { Column, Row } from "../reusable/GenericStyledComponents";
import StyledText from "../reusable/StyledText";
import { Button } from "antd";

type Props = {
  onClose: () => void;
  mode: typeof BID | typeof ASK;
};

const SpotOrderModal: React.FC<Props> = ({ onClose, mode }) => {
  const dispatch = useAppDispatch();
  const { spotQuantity: lotQuantity, swiftId } = useSelector(
    (state: RootState) => ({
      spotQuantity: state.spot.spotQuantity,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );
  const { SPOT_DECIMAL_PLACES, EB_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET } =
    useSelector((state: RootState) => state.settings.parameters);
  const [quantity, setQuantity] = useState("0");
  const [price, setPrice] = useState("0");
  const [error, setError] = useState("");
  const { bestBid, bestAsk } = useSelector(selectBestBidAndAsk);

  const marketPrice = useMemo(() => {
    const bestPrice = mode === BID ? bestBid : bestAsk;
    return bestPrice?.price || 0;
  }, [mode, bestAsk, bestBid]);

  useEffect(() => {
    setPrice(`${marketPrice}`);
  }, [marketPrice]);

  useEffect(() => {
    setQuantity(`${lotQuantity}`);
  }, [lotQuantity]);

  const handlePlaceClicked = () => {
    const result = {
      swift_id: swiftId,
      direction: mode,
      amount: Number(quantity.replaceAll(/[ ,A-Za-z]/g, "")),
      price: Number(price.replaceAll(/[ ,A-Za-z]/g, "")),
    };
    dispatch(spotPlaceOrder(result));
    onClose();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp":
        setPrice(
          `${(parseFloat(price) + 5 / 10 ** SPOT_DECIMAL_PLACES).toFixed(
            SPOT_DECIMAL_PLACES
          )}`
        );
        break;
      case "ArrowDown":
        setPrice(
          `${(parseFloat(price) - 5 / 10 ** SPOT_DECIMAL_PLACES).toFixed(
            SPOT_DECIMAL_PLACES
          )}`
        );
        break;
      case "ArrowLeft":
        setPrice(
          `${(parseFloat(price) - 1 / 10 ** SPOT_DECIMAL_PLACES).toFixed(
            SPOT_DECIMAL_PLACES
          )}`
        );
        break;
      case "ArrowRight":
        setPrice(
          `${(parseFloat(price) + 1 / 10 ** SPOT_DECIMAL_PLACES).toFixed(
            SPOT_DECIMAL_PLACES
          )}`
        );
        break;
      case "Enter":
        handlePlaceClicked();
        break;
    }
    if (
      Math.abs(
        parseFloat(
          (parseFloat(price) - marketPrice).toFixed(SPOT_DECIMAL_PLACES)
        )
      ) *
        10 ** SPOT_DECIMAL_PLACES >
      EB_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET
    ) {
      setError("Too many points off market");
    } else {
      setError("");
    }
  };

  return (
    <Column>
      <Row>
        <QuantityInput
          fontSize={28}
          fontWeight={400}
          placeholder="Quantity"
          thousandSeparator
          value={quantity}
          incrementValue={1}
          allowNegative={false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value === "") return;
            setQuantity(value);
          }}
        />
      </Row>

      <Row>
        <PriceInput
          fontSize={28}
          fontWeight={400}
          value={price}
          placeholder="Price"
          thousandSeparator
          autoFocus
          allowNegative={false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value === "") return;
            setPrice(value);
          }}
          onKeyDown={handleKeyPress}
        />
      </Row>

      <Row style={{ marginTop: ".5rem", gap: "1rem", justifyContent: "left" }}>
        <StyledText fontSize={14} fontWeight={600}>
          <span style={{ color: "#9a9a9a" }}>Expires in:</span> Never
        </StyledText>
        <StyledText fontSize={14} fontWeight={600}>
          <span style={{ color: "#9a9a9a" }}>Order Type:</span> Limit Order
        </StyledText>
        {error && <ErrorText>{error}</ErrorText>}
      </Row>

      <Row
        style={{
          gap: ".125rem",
          marginTop: ".5rem",
          justifyContent: "space-around",
        }}
      >
        <PlaceButton
          style={{ background: "#00AB68" }}
          onClick={handlePlaceClicked}
        >
          <StyledText fontSize={16} fontWeight={400}>
            Place {mode === BID ? "Bid" : "Ask"}
          </StyledText>
        </PlaceButton>

        <PlaceButton style={{ background: "#C03548" }} onClick={onClose}>
          <StyledText fontSize={16} fontWeight={400}>
            Cancel
          </StyledText>
        </PlaceButton>
      </Row>
    </Column>
  );
};

const popup = keyframes`
  from {
    transform: scale(140%);
  }

  to {
    transform: scale(100%);
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  font-weight: 600;
  animation: ${popup} 0.25s ease-in-out;
`;
const BaseNumberInputWithArrow = styled(NumberInputWithArrows)`
  background: #262626 !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
`;

const PriceInput = styled(BaseNumberInputWithArrow)`
  margin-top: 0.5rem;
  width: 100%;
`;
const QuantityInput = styled(BaseNumberInputWithArrow)`
  margin-top: 0.5rem;
  width: 100%;
  padding-right: 10px;
`;

const SpotButton = styled(Button)`
  border-color: transparent;
  border-radius: 3px;
  flex: 1;
`;

const PlaceButton = styled(SpotButton)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:hover {
    color: #b7eb8f !important;
    border: 1px solid #b7eb8f !important;
  }
`;

export default SpotOrderModal;

import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { Button } from "antd";
import styled from "styled-components";

import { NumberInputWithArrows } from "components/reusable/NumberInput";
import { BID, ASK, PUT, CALL } from "stateConstants";
import { useAppDispatch, getState } from "store/store";
import { RootState } from "store/rootReducer";
import { spotPlaceVolEbOrder } from "store/spot/actions";
import { Tenor } from "types";
import StyledText from "../reusable/StyledText";
import { Row } from "../reusable/GenericStyledComponents";
import { precision } from "../../utils/numbers";
import { selectBestBidAndAsk } from "store/newpricer/selectors";

type Props = {
  onClose: () => void;
  mode: typeof BID | typeof ASK;
};

const OptionOrderModal: React.FC<Props> = ({ onClose, mode }) => {
  const dispatch = useAppDispatch();
  const {
    selectedSellTenor,
    selectedBuyTenor,
    volQuantity: lotQuantity,
    swiftId,
    selectedDelta,
    tradableVols,
    sticky_model,
  } = useSelector(
    (state: RootState) => ({
      selectedSellTenor: state.spot.selectedSellTenor,
      selectedBuyTenor: state.spot.selectedBuyTenor,
      volQuantity: state.spot.volQuantity,
      swiftId: state.spot.swiftId,
      selectedDelta: state.spot.selectedDelta,
      tradableVols: state.spot.tradableVols,
      sticky_model: state.spot.sticky_model,
    }),
    shallowEqual
  );
  const { bestBid, bestAsk } = selectBestBidAndAsk(getState());

  const [quantity, setQuantity] = useState("0");
  const [volatility, setVolatility] = useState("0");
  const [put_or_call, setPutOrCall] = useState<0 | 1>(0);

  const selectedTenor = (
    mode === BID ? selectedSellTenor : selectedBuyTenor
  ) as Tenor;

  const volumeKey = mode === BID ? "sell" : "buy";
  const tvolVolatility = tradableVols?.[selectedTenor]?.[selectedDelta]?.[
    volumeKey
  ] as number | undefined;

  useEffect(() => {
    if (tvolVolatility) {
      const value = precision(tvolVolatility * 100, 2);
      setVolatility(`${value}`);
      if (sticky_model) {
        setPutOrCall(selectedDelta < 0.5 ? PUT : CALL);
      } else {
        const middlePoint =
          bestBid && bestAsk ? (bestBid?.price + bestAsk?.price) / 2 : 0;
        setPutOrCall(selectedDelta < middlePoint ? PUT : CALL);
      }
    }
  }, [tvolVolatility]);

  useEffect(() => {
    setQuantity(`${lotQuantity}`);
  }, [lotQuantity]);

  const handlePlaceClicked = () => {
    const result = {
      swift_id: swiftId,
      direction: mode,
      amount: Number(quantity.replaceAll(/[ ,A-Za-z]/g, "")),
      volatility: precision(Number(parseFloat(volatility)) / 100, 4),
      delta_strike: selectedDelta,
      tenor: selectedTenor,
      put_or_call,
    };
    dispatch(spotPlaceVolEbOrder(result));
    onClose();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp":
        setVolatility(`${(parseFloat(volatility) + 0.05).toFixed(2)}`);
        break;
      case "ArrowDown":
        setVolatility(`${(parseFloat(volatility) - 0.05).toFixed(2)}`);
        break;
      case "ArrowLeft":
        setVolatility(`${(parseFloat(volatility) - 0.01).toFixed(2)}`);
        break;
      case "ArrowRight":
        setVolatility(`${(parseFloat(volatility) + 0.01).toFixed(2)}`);
        break;
      case "Enter":
        handlePlaceClicked();
        break;
    }
  };

  return (
    <>
      <Row style={{ marginTop: ".5rem" }}>
        <QuantityInput
          fontSize={28}
          fontWeight={400}
          placeholder="Quantity"
          thousandSeparator
          value={quantity}
          incrementValue={1}
          allowNegative={false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value === "") return;
            setQuantity(value);
          }}
        />
      </Row>

      <Row style={{ marginTop: ".5rem" }}>
        <PriceInput
          fontSize={28}
          fontWeight={400}
          value={volatility}
          placeholder="Volatility"
          thousandSeparator
          allowNegative={false}
          suffix="%"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value === "") return;
            setVolatility(value);
          }}
          onKeyDown={handleKeyPress}
          autoFocus
        />
      </Row>

      <Row style={{ marginTop: ".5rem", gap: "1rem", justifyContent: "left" }}>
        <StyledText fontSize={14} fontWeight={600}>
          <span style={{ color: "#9a9a9a" }}>Expires in:</span> Never
        </StyledText>
        <StyledText fontSize={14} fontWeight={600}>
          <span style={{ color: "#9a9a9a" }}>Order Type:</span> Option
        </StyledText>
      </Row>

      <Row
        style={{
          gap: ".125rem",
          marginTop: ".5rem",
          justifyContent: "space-around",
        }}
      >
        <PlaceButton
          style={{ background: "#00AB68" }}
          onClick={handlePlaceClicked}
        >
          <StyledText fontSize={16} fontWeight={400}>
            Place {mode === BID ? "Bid" : "Ask"}
          </StyledText>
        </PlaceButton>

        <PlaceButton style={{ background: "#C03548" }} onClick={onClose}>
          <StyledText fontSize={16} fontWeight={400}>
            Cancel
          </StyledText>
        </PlaceButton>
      </Row>
    </>
  );
};

const BaseNumberInputWithArrow = styled(NumberInputWithArrows)`
  background: #3a454b !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
`;

const PriceInput = styled(BaseNumberInputWithArrow)`
  width: 100%;
`;
const QuantityInput = styled(BaseNumberInputWithArrow)`
  width: 100%;
  padding-right: 10px;
`;

const SpotButton = styled(Button)`
  border-color: transparent;
  border-radius: 6px;
`;

const PlaceButton = styled(SpotButton)`
  width: 220px;
  height: 45px;
`;

export default OptionOrderModal;

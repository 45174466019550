/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { InputNumber, Select } from "antd";
import {
  CallCalendarSpreadOptions,
  IndividualOptionOptions,
  MATURITY,
  PutCalendarSpreadOptions,
  RFQMakeCallPayload,
  StraddleOptions,
  StranggleOptions,
  StrategyOptions,
  StrategyType,
} from "types";
import { Column } from "components/reusable/GenericStyledComponents";
import { timeUnixToDatePretty } from "utils/time";
import { RootState } from "store/rootReducer";
import { shallowEqual, useSelector } from "react-redux";
import { CALL, PUT } from "stateConstants";
import styled from "styled-components";

const useStrategyState = () => {
  const {
    delta_strike_values,
    sticky_model,
    visible_tenors,
    visible_delta_strike_values,
    tenors,
    swiftId,
  } = useSelector(
    (state: RootState) => ({
      delta_strike_values: state.spot.delta_strike_values,
      sticky_model: state.spot.sticky_model,
      visible_tenors: state.spot.visible_tenors,
      visible_delta_strike_values: state.spot.visible_delta_strike_values,
      tenors: state.spot.tenors,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );

  const [maturityOptions, setMaturityOptions] = useState<
    { label: string; value: number }[]
  >(
    visible_tenors[swiftId].map((k) => {
      return {
        value: tenors[swiftId][k],
        label: sticky_model
          ? MATURITY[tenors[swiftId][k]]
          : timeUnixToDatePretty(tenors[swiftId][k]),
      };
    })
  );

  const [strikeOptions, setStrikeOptions] = useState<
    { label: number; value: number }[]
  >(
    visible_delta_strike_values[swiftId].map((v) => {
      return { value: delta_strike_values[v], label: delta_strike_values[v] };
    })
  );
  useEffect(() => {
    setMaturityOptions(
      visible_tenors[swiftId].map((k) => {
        return {
          value: tenors[swiftId][k],
          label: sticky_model
            ? MATURITY[tenors[swiftId][k]]
            : timeUnixToDatePretty(tenors[swiftId][k]),
        };
      })
    );
  }, [tenors, visible_tenors]);

  useEffect(() => {
    setStrikeOptions(
      visible_delta_strike_values[swiftId].map((v) => {
        return { value: delta_strike_values[v], label: delta_strike_values[v] };
      })
    );
  }, [delta_strike_values, visible_delta_strike_values]);

  return {
    maturityOptions,
    strikeOptions,
    sticky_model,
    delta_strike_values,
    tenors,
    visible_tenors,
    visible_delta_strike_values,
    swiftId,
  };
};

type IndividualOptionProps = {
  options: IndividualOptionOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const IndividualOption: React.FC<IndividualOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    maturityOptions,
    sticky_model,
  } = useStrategyState();
  useEffect(() => {
    onChange(
      {
        amount: 1000,
        strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
        tenor: tenors[swiftId][visible_tenors[swiftId][0]],
        put_or_call: CALL,
        short_or_long: 0,
      },
      StrategyType.INDIVIDUAL_OPTION
    );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label style={{ margin: 0, padding: 0 }}>Call / Put</Label>

      <Select
        dropdownStyle={{ zIndex: 10000 }}
        style={{ width: "15ch" }}
        options={[
          { label: "PUT", value: PUT },
          { label: "CALL", value: CALL },
        ]}
        value={options.put_or_call}
        onChange={(value) =>
          onChange(
            { ...options, put_or_call: value },
            StrategyType.INDIVIDUAL_OPTION
          )
        }
      />
      <Label>Notional</Label>

      <InputNumber
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange(
            { ...options, amount: value },
            StrategyType.INDIVIDUAL_OPTION
          )
        }
      />
      <Label>Strike</Label>

      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value || 0 },
              StrategyType.INDIVIDUAL_OPTION
            )
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value },
              StrategyType.INDIVIDUAL_OPTION
            )
          }
        />
      )}
      <Label>Tenor</Label>

      <Select
        style={{ width: "15ch" }}
        dropdownStyle={{ zIndex: 10000 }}
        options={maturityOptions}
        value={options.tenor}
        onChange={(value) =>
          onChange({ ...options, tenor: value }, StrategyType.INDIVIDUAL_OPTION)
        }
      />
    </Column>
  );
};

type StraddleOptionProps = {
  options: StraddleOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const StraddleOption: React.FC<StraddleOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    // strikeOptions,
    // sticky_model,
    maturityOptions,
  } = useStrategyState();
  useEffect(() => {
    onChange(
      {
        amount: 1000,
        strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
        tenor: tenors[swiftId][visible_tenors[swiftId][0]],
        short_or_long: 0,
      },
      StrategyType.STRADDLE
    );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange({ ...options, amount: value }, StrategyType.STRADDLE)
        }
      />
      {/* Strike will be ATM (BE handled) */}
      {/* <Label>Strike</Label>
      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange({ ...options, strike: value || 0 }, StrategyType.STRADDLE)
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            value &&
            onChange({ ...options, strike: value }, StrategyType.STRADDLE)
          }
        />
      )} */}

      <Label>Tenor</Label>
      <Select
        style={{ width: "15ch" }}
        dropdownStyle={{ zIndex: 10000 }}
        options={maturityOptions}
        value={options.tenor}
        onChange={(value) =>
          onChange({ ...options, tenor: value }, StrategyType.STRADDLE)
        }
      />
    </Column>
  );
};

type StranggleOptionProps = {
  options: StranggleOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const StranggleOption: React.FC<StranggleOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    sticky_model,
    maturityOptions,
  } = useStrategyState();

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          amount: 1000,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.STRANGLE
      );
    else
      onChange(
        {
          amount: 1000,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.STRANGLE
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange({ ...options, amount: value }, StrategyType.STRANGLE)
        }
      />
      {sticky_model ? (
        <>
          <Label>Strike 1</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike1}
            onChange={(value) =>
              onChange(
                { ...options, strike1: value || 0 },
                StrategyType.STRANGLE
              )
            }
          />
          <Label>Strike 2</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike2}
            onChange={(value) =>
              onChange(
                { ...options, strike2: value || 0 },
                StrategyType.STRANGLE
              )
            }
          />
        </>
      ) : (
        <>
          <Label>Strike 1</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions}
            value={options.strike1}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike1: value }, StrategyType.STRANGLE)
            }
          />

          <Label>Strike 2</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions}
            value={options.strike2}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike2: value }, StrategyType.STRANGLE)
            }
          />
        </>
      )}

      <Label>Tenor</Label>
      {sticky_model ? (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.STRANGLE)
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.STRANGLE)
          }
        />
      )}
    </Column>
  );
};

type CallCalendarOptionProps = {
  options: CallCalendarSpreadOptions;
  onChange: (
    options:
      | IndividualOptionOptions
      | StraddleOptions
      | StranggleOptions
      | CallCalendarSpreadOptions,
    strategy: StrategyType
  ) => void;
};

export const CallCalendarOption: React.FC<CallCalendarOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    sticky_model,
    maturityOptions,
  } = useStrategyState();

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          amount: 1000,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.CALL_CALENDAR_SPREAD
      );
    else
      onChange(
        {
          amount: 1000,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.CALL_CALENDAR_SPREAD
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange(
            { ...options, amount: value },
            StrategyType.CALL_CALENDAR_SPREAD
          )
        }
      />
      <Label>Strike</Label>
      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value || 0 },
              StrategyType.CALL_CALENDAR_SPREAD
            )
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            value &&
            onChange(
              { ...options, strike: value },
              StrategyType.CALL_CALENDAR_SPREAD
            )
          }
        />
      )}
      {sticky_model ? (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor1}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor2}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
        </>
      ) : (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor1}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor2}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
        </>
      )}
    </Column>
  );
};

type PutCalendarOptionProps = {
  options: PutCalendarSpreadOptions;
  onChange: (
    options:
      | IndividualOptionOptions
      | StraddleOptions
      | StranggleOptions
      | PutCalendarSpreadOptions,
    strategy: StrategyType
  ) => void;
};

export const PutCalendarOption: React.FC<PutCalendarOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    sticky_model,
    visible_tenors,
    visible_delta_strike_values,
    tenors,
    swiftId,
  } = useSelector(
    (state: RootState) => ({
      delta_strike_values: state.spot.delta_strike_values,
      sticky_model: state.spot.sticky_model,
      visible_tenors: state.spot.visible_tenors,
      visible_delta_strike_values: state.spot.visible_delta_strike_values,
      tenors: state.spot.tenors,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );
  const [maturityOptions, setMaturityOptions] = useState<
    { label: string; value: number }[]
  >(
    visible_tenors[swiftId].map((k) => {
      return {
        value: tenors[swiftId][k],
        label: sticky_model
          ? MATURITY[tenors[swiftId][k]]
          : timeUnixToDatePretty(tenors[swiftId][k]),
      };
    })
  );

  const [strikeOptions, setStrikeOptions] = useState<
    { label: number; value: number }[]
  >(
    visible_delta_strike_values[swiftId].map((v) => {
      return { value: delta_strike_values[v], label: delta_strike_values[v] };
    })
  );
  useEffect(() => {
    setMaturityOptions(
      visible_tenors[swiftId].map((k) => {
        return {
          value: tenors[swiftId][k],
          label: sticky_model
            ? MATURITY[tenors[swiftId][k]]
            : timeUnixToDatePretty(tenors[swiftId][k]),
        };
      })
    );
  }, [tenors, visible_tenors]);

  useEffect(() => {
    setStrikeOptions(
      visible_delta_strike_values[swiftId].map((v) => {
        return { value: delta_strike_values[v], label: delta_strike_values[v] };
      })
    );
  }, [delta_strike_values, visible_delta_strike_values]);

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          amount: 1000,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.PUT_CALENDAR_SPREAD
      );
    else
      onChange(
        {
          amount: 1000,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.PUT_CALENDAR_SPREAD
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange(
            { ...options, amount: value },
            StrategyType.PUT_CALENDAR_SPREAD
          )
        }
      />
      <Label>Strike</Label>
      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value || 0 },
              StrategyType.PUT_CALENDAR_SPREAD
            )
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            value &&
            onChange(
              { ...options, strike: value },
              StrategyType.PUT_CALENDAR_SPREAD
            )
          }
        />
      )}

      {sticky_model ? (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={maturityOptions}
            value={options.tenor1}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={maturityOptions}
            value={options.tenor2}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
        </>
      ) : (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            options={maturityOptions}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor1}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={maturityOptions}
            value={options.tenor2}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
        </>
      )}
    </Column>
  );
};

const Label = styled.label`
  margin: 0;
  padding: 0;
`;

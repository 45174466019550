import React, { useEffect, useMemo, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useSelector } from "react-redux";

import LoadingSpinner from "components/reusable/LoadingSpinner";
import BrokerDealer from "./BrokerDealer";
import ChartsSection from "./Charts/ChartsSection";
import ClientCalls from "./ClientCalls";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import PortfolioInfo from "./GeneralInfo/PortfolioInfo";
import BrokerArea from "./BrokerArea/BrokerArea";
import { RootState } from "store/rootReducer";
import NewPricerSection from "./Pricer/NewPricerSection";
import ExpandRightSide from "./ExpandRightSide";
import QuoteSection from "./QuoteSection";
import GridLayout, { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import _ from "lodash";
import { selectActiveLayout } from "store/layout/selectors";
import { setLayout } from "store/layout/reducers";
import { useAppDispatch } from "store/store";
import { LayoutComponentKey } from "types";

const FullWidthGridLayout = WidthProvider(GridLayout);

const componentMap: Record<LayoutComponentKey, JSX.Element> = {
  PortfolioInfo: <PortfolioInfo />,
  ChartsSection: <ChartsSection />,
  BrokerArea: <BrokerArea />,
  GeneralInfo: <GeneralInfo />,
  NewPricerSection: <NewPricerSection />,
  BrokerDealer: <BrokerDealer />,
  QuoteSection: <QuoteSection />,
};

const GameLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const cb = window.location.pathname.split("/")[1] === "centralbank";
  const { editing } = useSelector((state: RootState) => state.layout);
  const layout = useSelector(selectActiveLayout);
  const { initial_state_loaded, visible_delta_strike_values } = useSelector(
    (state: RootState) => state.spot
  );
  const { connected: socketConnected } = useSelector(
    (state: RootState) => state.socket
  );
  const { settingsLoaded } = useSelector((state: RootState) => state.settings);
  const { cb_state_loaded } = useSelector(
    (state: RootState) => state.centralbank
  );
  const { largePricer } = useSelector((state: RootState) => state.newpricer);
  const [collapsed, setCollapsed] = useState<boolean>(largePricer);
  const [willColapse, setWillColapse] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = _.throttle(() => {
      setWidth(window.innerWidth);
    }, 100); // Throttle with lodash or similar

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (largePricer) {
      setWillColapse(true);
      setTimeout(() => {
        setCollapsed(true);
        setWillColapse(false);
      }, 300);
    } else {
      setCollapsed(false);
    }
  }, [largePricer]);

  const onLayoutChange = (newLayout: GridLayout.Layout[]) => {
    console.log(newLayout);
    dispatch(setLayout(newLayout));
  };

  if (!socketConnected)
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner message="Connecting to services..." />
      </div>
    );

  if (!initial_state_loaded || !settingsLoaded || (cb && !cb_state_loaded))
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner message="Room data is loading..." />
      </div>
    );

  // The number of columns in the grid, row height, and container padding
  const cols = 6;
  const rowHeight = 100;
  const margin: [number, number] = [10, 10]; // Margin between items [horizontal, vertical]

  return (
    <FullWidthGridLayout
      resizeHandles={editing ? ["se", "ne", "sw", "nw"] : []}
      className="layout"
      layout={layout}
      cols={cols}
      rowHeight={rowHeight}
      width={width}
      margin={margin}
      isDraggable={editing}
      isResizable={editing}
      onLayoutChange={onLayoutChange}
    >
      {layout.map((item) => {
        const Component = componentMap[item.i as LayoutComponentKey];
        return Component ? <div key={item.i}>{Component}</div> : null; // in case BE sends non existing key
      })}
    </FullWidthGridLayout>
  );
};

export default GameLayout;

import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Typography,
  Select,
  Row,
  message,
  DatePicker,
} from "antd";
import {
  TournamentPayload,
  TournamentStagePayload,
  TournamentSession,
  StageMetricPayload,
  createEmptyTournamentPayload,
  createEmptyTournamentStagePayload,
  createEmptyStageMetricPayload,
  User,
  AdminScenarioBE,
  TournamentSessionPayload,
} from "types";
import { Column } from "components/reusable/GenericStyledComponents";
import { adminService } from "services";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const metricMap: { [key: string]: string } = {
  SPOT_PNL: "Spot PnL",
  OPTIONS_PNL: "Options PnL",
  SPOT_VOTE_ACCURACY: "Spot vote accuracy",
  VOL_VOTE_ACCURACY: "VOL vote accuracy",
};

const CreateTournament: React.FC = () => {
  const [tournament, setTournament] = useState<TournamentPayload>(
    createEmptyTournamentPayload()
  );
  const [trainers, setTrainers] = useState<(User & { id: number })[]>([]);
  const [scenarios, setScenarios] = useState<AdminScenarioBE[]>([]);
  const [metrics, setMetrics] = useState<any[]>([]);

  const fetchData = async () => {
    const _trainers = await adminService.fetchAllTrainers();
    const _scenarios = await adminService.getAllScenarios();
    const _metrics = await adminService.getAllMetrics();
    setMetrics(_metrics);
    setTrainers(_trainers);
    setScenarios(_scenarios);
    console.log({ _metrics });
    console.log(
      _metrics.map((currentMetric, idx) => {
        return {
          label: metricMap[currentMetric],
          value: { field: currentMetric },
        };
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createTournament = async () => {
    try {
      await adminService.createTournament(tournament);
      message.success(`Tournament ${tournament.name} was created successfuly!`);
    } catch (e: any) {
      message.error(e.toString());
    }
  };

  // Tournament field change handler
  const handleTournamentChange = (key: keyof TournamentPayload, value: any) => {
    setTournament({ ...tournament, [key]: value });
  };

  // Stage field change handler
  const handleStageChange = (
    stageIndex: number,
    key: keyof TournamentStagePayload,
    value: any
  ) => {
    const updatedStages = [...tournament.stages];

    if (key === "start_date") {
      // Assuming 'date' is your key for the date field in the stage
      const dateValue = moment(value).unix(); // Converts to Unix timestamp
      updatedStages[stageIndex] = {
        ...updatedStages[stageIndex],
        [key]: dateValue,
      };
    } else {
      updatedStages[stageIndex] = {
        ...updatedStages[stageIndex],
        [key]: value,
      };
    }

    setTournament({ ...tournament, stages: updatedStages });
  };

  // Session field change handler
  const handleSessionChange = (
    stageIndex: number,
    sessionIndex: number,
    key: keyof TournamentSessionPayload,
    value: any
  ) => {
    const updatedStages = [...tournament.stages];
    const updatedSessions = [...updatedStages[stageIndex].sessions];
    updatedSessions[sessionIndex] = {
      ...updatedSessions[sessionIndex],
      [key]: value,
    };
    updatedStages[stageIndex].sessions = updatedSessions;
    setTournament({ ...tournament, stages: updatedStages });
  };

  // Metric field change handler
  const handleMetricChange = (
    stageIndex: number,
    sessionIndex: number,
    metricIndex: number,
    key: keyof StageMetricPayload,
    value: any
  ) => {
    const updatedStages = [...tournament.stages];
    const updatedSessions = [...updatedStages[stageIndex].sessions];
    const updatedMetrics = [...updatedSessions[sessionIndex].metrics];
    updatedMetrics[metricIndex] = {
      ...updatedMetrics[metricIndex],
      [key]: value,
    };
    updatedSessions[sessionIndex].metrics = updatedMetrics;
    updatedStages[stageIndex].sessions = updatedSessions;
    setTournament({ ...tournament, stages: updatedStages });
  };

  // Add a new stage
  const addStage = () => {
    setTournament({
      ...tournament,
      stages: [...tournament.stages, createEmptyTournamentStagePayload()],
    });
  };

  // Add a new session to a stage
  const addSession = (stageIndex: number) => {
    const updatedStages = [...tournament.stages];
    updatedStages[stageIndex].sessions.push({
      scenario: 0,
      status: 0, // Assuming 0 as default status
      metrics: [],
    });
    setTournament({ ...tournament, stages: updatedStages });
  };

  // Add a new metric to a session
  const addMetric = (stageIndex: number, sessionIndex: number) => {
    const updatedStages = [...tournament.stages];
    const newMetric = createEmptyStageMetricPayload();
    updatedStages[stageIndex].sessions[sessionIndex].metrics.push(newMetric);
    setTournament({ ...tournament, stages: updatedStages });
  };

  // Remove a stage
  const removeStage = (stageIndex: number) => {
    const updatedStages = tournament.stages.filter((_, i) => i !== stageIndex);
    setTournament({ ...tournament, stages: updatedStages });
  };

  // Remove a session from a stage
  const removeSession = (stageIndex: number, sessionIndex: number) => {
    const updatedStages = [...tournament.stages];
    updatedStages[stageIndex].sessions = updatedStages[
      stageIndex
    ].sessions.filter((_, i) => i !== sessionIndex);
    setTournament({ ...tournament, stages: updatedStages });
  };

  // Remove a metric from a session
  const removeMetric = (
    stageIndex: number,
    sessionIndex: number,
    metricIndex: number
  ) => {
    const updatedStages = [...tournament.stages];
    updatedStages[stageIndex].sessions[sessionIndex].metrics = updatedStages[
      stageIndex
    ].sessions[sessionIndex].metrics.filter((_, i) => i !== metricIndex);
    setTournament({ ...tournament, stages: updatedStages });
  };

  if (!scenarios.length || !trainers.length) return <LoadingSpinner />;

  return (
    <Column
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: "60%",
        marginTop: "3rem",
        border: "1px solid #ffffff30",
        borderRadius: "1rem",
        padding: "1rem 3rem",
      }}
    >
      <Typography.Title level={2}>Create a New Tournament</Typography.Title>
      <Input
        style={{ marginBottom: "10px" }}
        placeholder="Tournament Name"
        value={tournament.name}
        onChange={(e) => handleTournamentChange("name", e.target.value)}
      />
      <TextArea
        style={{ marginBottom: "10px" }}
        placeholder="Tournament description"
        value={tournament.description}
        onChange={(e) => handleTournamentChange("description", e.target.value)}
      />
      <Row
        style={{
          width: "100%",
          margin: "1rem 0",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Typography.Text>Trainer:</Typography.Text>
        <Select
          placeholder="Select a trainer..."
          showSearch
          dropdownMatchSelectWidth={false} // Make the dropdown width independent from the parent
          dropdownStyle={{ zIndex: 100001 }}
          style={{ width: "fit-content" }}
          //   defaultValue={trainers[0].id}
          options={trainers.map((trainer, idx) => {
            return { label: trainer.username, value: idx };
          })}
          onChange={(idx) => {
            setTournament({ ...tournament, trainer: trainers[idx].id });
          }}
          filterOption={(input, option) =>
            ((option?.label.toLocaleLowerCase() ?? "") as string).includes(
              input.toLocaleLowerCase()
            )
          }
          filterSort={(optionA: any, optionB: any) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
        />
      </Row>
      {React.Children.toArray(
        tournament.stages.map((stage, stageIndex) => (
          <Column
            style={{
              width: "70%",
              border: "1px solid #ffffff50",
              borderRadius: "1rem",
              padding: "2rem",
              marginBottom: "20px",
              background: "#ffffff10",
            }}
          >
            <Typography.Title level={4}>
              Stage {stageIndex + 1}
            </Typography.Title>
            <Row
              style={{
                width: "100%",
                margin: "1rem 0",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <Typography.Text>Pass rate:</Typography.Text>
              <Input
                type="number"
                style={{ width: "9ch" }}
                min={0}
                max={100}
                suffix="%"
                placeholder="Pass Rate"
                value={stage.pass_rate}
                onChange={(e) => {
                  // if (!Number.isNaN(parseInt(e.target.value))) return;
                  handleStageChange(
                    stageIndex,
                    "pass_rate",
                    parseFloat(e.target.value)
                  );
                }}
              />
            </Row>
            <Row
              style={{
                width: "100%",
                margin: "1rem 0",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <Typography.Text>Start date:</Typography.Text>
              <DatePicker
                format="YYYY-MM-DD"
                onChange={(value) =>
                  handleStageChange(stageIndex, "start_date", value)
                }
              />
            </Row>
            {React.Children.toArray(
              stage.sessions.map((session, sessionIndex) => (
                <Column
                  style={{
                    border: "1px solid #ffffff70",
                    borderRadius: ".5rem",
                    margin: "1rem .5rem",
                    padding: "2rem",
                    background: "#ffffff15",
                  }}
                >
                  <Row
                    style={{
                      width: "100%",
                      margin: "1rem 0",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <Typography.Text>Scenario:</Typography.Text>
                    <Select
                      placeholder="Select a scenario..."
                      showSearch
                      dropdownMatchSelectWidth={false} // Make the dropdown width independent from the parent
                      dropdownStyle={{ zIndex: 100001 }}
                      style={{ width: "fit-content" }}
                      //   defaultValue={scenarios[0].id}
                      options={scenarios.map((scenario, idx) => {
                        return { label: scenario.name, value: idx };
                      })}
                      onChange={(idx) => {
                        handleSessionChange(
                          stageIndex,
                          sessionIndex,
                          "scenario",
                          scenarios[idx].id
                        );
                      }}
                      filterOption={(input, option) =>
                        (
                          (option?.label.toLocaleLowerCase() ?? "") as string
                        ).includes(input.toLocaleLowerCase())
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                    />
                  </Row>

                  {React.Children.toArray(
                    session.metrics.map((metric, metricIndex) => (
                      <Column
                        style={{
                          border: "1px solid #ffffff70",
                          borderRadius: ".5rem",
                          margin: "1rem .5rem",
                          padding: "2rem",
                          background: "#ffffff20",
                        }}
                      >
                        <Select
                          placeholder="Select a metric..."
                          style={{ marginBottom: "5px" }}
                          dropdownMatchSelectWidth={false} // Make the dropdown width independent from the parent
                          value={metricMap[metric.field]}
                          options={metrics.map((currentMetric, idx) => {
                            return {
                              label: metricMap[currentMetric],
                              value: currentMetric,
                            };
                          })}
                          onChange={(m) => {
                            handleMetricChange(
                              stageIndex,
                              sessionIndex,
                              metricIndex,
                              "field",
                              m
                            );
                          }}
                        />
                        <Button
                          onClick={() =>
                            removeMetric(stageIndex, sessionIndex, metricIndex)
                          }
                        >
                          Remove Metric
                        </Button>
                      </Column>
                    ))
                  )}
                  <Button
                    type="dashed"
                    onClick={() => addMetric(stageIndex, sessionIndex)}
                    style={{ marginBottom: "10px" }}
                  >
                    Add Metric
                  </Button>

                  <Button
                    onClick={() => removeSession(stageIndex, sessionIndex)}
                  >
                    Remove Session
                  </Button>
                </Column>
              ))
            )}
            <Button
              type="dashed"
              onClick={() => addSession(stageIndex)}
              style={{ marginBottom: "10px" }}
            >
              Add Session
            </Button>

            <Button onClick={() => removeStage(stageIndex)}>
              Remove Stage
            </Button>
          </Column>
        ))
      )}
      <Button
        type="primary"
        onClick={addStage}
        style={{ marginBottom: "20px" }}
      >
        Add Stage
      </Button>
      <Button onClick={createTournament} style={{ marginBottom: "20px" }}>
        Publish tournament
      </Button>
    </Column>
  );
};

export default CreateTournament;

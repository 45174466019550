import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { useCallback, useEffect, useState } from "react";

import { roomsService } from "services/rooms.service";
import { Tournament } from "types";
import LobbyTournamentCard from "./LobbyTournamentCard";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import { Button, Input, Modal, Typography, message } from "antd";
import { roomService } from "services";
import { PlusCircleFilled } from "@ant-design/icons";

const useFetchTournaments = () => {
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [fetched, setFetched] = useState<boolean>(false);

  const fetchTournaments = useCallback(async () => {
    // setFetched(false);
    const newTournaments = await roomsService.getTournaments();
    // Compare new tournaments with the current state before updating
    if (JSON.stringify(newTournaments) !== JSON.stringify(tournaments)) {
      setTournaments(newTournaments);
    }
    setFetched(true);
  }, [tournaments]);

  useEffect(() => {
    fetchTournaments();
    const req = setInterval(fetchTournaments, 4000);
    return () => clearInterval(req);
  }, [fetchTournaments]);

  return { tournaments, fetched, fetchTournaments };
};

const LobbyTournaments: React.FC = () => {
  const { tournaments, fetched, fetchTournaments } = useFetchTournaments();
  const [isJoinModalOpen, setIsJoinModalOpen] = useState<boolean>(false);
  const [joinCode, setJoinCode] = useState<string>("");

  if (!fetched)
    return (
      <Column style={{ marginTop: "1rem", alignItems: "center" }}>
        <LoadingSpinner />
      </Column>
    );

  return (
    <Column
      style={{
        gap: ".5rem",
        overflow: "auto",
        paddingBottom: "5rem",
        height: "700px",
      }}
    >
      <Button
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
        onClick={() => {
          setIsJoinModalOpen(true);
        }}
      >
        Join tournament <PlusCircleFilled />
      </Button>
      {tournaments.length === 0 ? (
        <Column style={{ marginTop: "1rem", alignItems: "center" }}>
          <Typography.Text>
            You are not enrolled in any tournament.
          </Typography.Text>
        </Column>
      ) : (
        React.Children.toArray(
          tournaments.map((tournament) => {
            return (
              <LobbyTournamentCard
                fetchTournaments={fetchTournaments}
                tournament={tournament}
              />
            );
          })
        )
      )}
      <Modal
        onOk={async () => {
          await roomService.joinTournament(joinCode);
          message.success("You have joined the tournament!");
          fetchTournaments();
          setIsJoinModalOpen(false);
        }}
        onCancel={() => {
          setIsJoinModalOpen(false);
        }}
        okText="Join"
        open={isJoinModalOpen}
        title="Paste the provided tournament code in order to join"
      >
        <Row
          style={{
            margin: "2rem 2rem",
            justifyContent: "center",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <Typography.Text>Join code:</Typography.Text>
          <Input
            value={joinCode}
            onChange={(e) => {
              setJoinCode(e.target.value);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              navigator.clipboard.readText().then(
                (text) => {
                  setJoinCode(text);
                },
                () => {
                  message.error("Failed to paste text");
                }
              );
            }}
          >
            Paste
          </Button>
        </Row>
      </Modal>
    </Column>
  );
};

export default LobbyTournaments;

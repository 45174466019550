import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";

import { ROOM_STATUS_STR } from "stateConstants";
import { RootState } from "store/rootReducer";
import { Permission, Room } from "types";
import { secondsToTimestampPretty } from "utils/time";

const { Text } = Typography;

interface Props {
  room: Room;
  teacherPhoto: string;
}

const MultiPlayerSessionCard: React.FC<Props> = ({ room, teacherPhoto }) => {
  const { permission } = useSelector((state: RootState) => state.user);
  const { uuid, owner, status, training_class, timestamp } = room;

  return (
    <Container>
      <TeacherImage src={teacherPhoto} alt="teacher" />

      <Col1>
        <PropText>
          <Highlight>Class:</Highlight>
          {training_class.name}
        </PropText>
        <PropText>
          <Highlight>Playing:</Highlight>
          {room.name}
        </PropText>
        <PropText>
          <Highlight>Trainer:</Highlight>
          {owner}
        </PropText>
      </Col1>

      <Col2>
        <PropText>
          <Highlight>Status: </Highlight>
          {ROOM_STATUS_STR[status]}
        </PropText>
        <PropText>
          <Highlight>Difficulty:</Highlight>
          Beginner
        </PropText>
        <PropText>
          <Highlight>Elapsed time:</Highlight>
          {secondsToTimestampPretty(timestamp)}
        </PropText>
      </Col2>

      <Actions>
        <JoinLink
          to={`/${
            [Permission.Trainer, Permission.Admin].includes(permission)
              ? "centralbank"
              : "game"
          }/${uuid}`}
        >
          <PlayCircleOutlined />
        </JoinLink>
      </Actions>
    </Container>
  );
};

const breathe = keyframes`
  0% {  
    transform: scale(110%);
    color: #cca20e;
  }
  50% {   
    transform: scale(95%);
    color: #e5b50c;
  }
  100% {   
    transform: scale(110%);
    color: #cca20e;
  }
`;

const Container = styled.div`
  background-color: #000;
  border-radius: 0;
  padding: 10px 15px;
  display: grid;
  align-items: center;
  grid-template-columns: 4rem 1fr 13rem max-content;
  column-gap: 0.5rem;
  transition: 0.1s ease-in-out;
  opacity: 0.9;

  & svg {
    animation: none;
  }

  &:hover {
    opacity: 1;

    & svg {
      animation: ${breathe} 1s ease-in-out infinite;
    }
    background: rgba(255, 255, 255, 0.1);
  }
`;

const TeacherImage = styled.img`
  grid-area: 1 / 1 / 2 / 2;
`;

const Col1 = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
`;

const Col2 = styled.div`
  grid-area: 1 / 3 / 2 / 4;
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  grid-area: 1 / 4 / 2 / 5;
`;

const PropText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
`;

const Highlight = styled(PropText)`
  color: rgba(248, 247, 247, 0.8);
  margin-right: 5px;
`;

const JoinLink = styled(Link)`
  font-size: 1.5rem;

  &,
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.color3};
    outline-color: ${(props) => props.theme.color3};
  }

  &:hover svg {
    animation: none;
  }
`;

export default MultiPlayerSessionCard;

import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import * as selectors from "store/rfq/selectors";
import Header from "./Header";
import OutgoingRFQ from "./OutgoingRFQ";
import IncomingRFQ from "./IncomingRFQ";
import ClientCalls from "../ClientCalls";
import { RootState } from "store/rootReducer";

const BrokerDealer: React.FC = () => {
  const incomingList = useSelector(selectors.incomingRFQs);
  const outgoingList = useSelector(selectors.outgoingRFQs);
  const selectedCall = useSelector(
    (state: RootState) => state.callQuotes.selectedCall,
    shallowEqual
  );
  const [tab, setTab] = useState<"incoming" | "outgoing" | "cc">("incoming");
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(!animate);
  }, [outgoingList.length, incomingList.length]);

  useEffect(() => {
    if (!outgoingList.length && tab === "outgoing") {
      setTab("incoming");
    }
  }, [outgoingList, tab]);

  return (
    <Container large={!selectedCall}>
      <Header tab={tab} setTab={setTab} />
      {tab === "incoming" && (
        <CallsList>
          {incomingList.map((rfq) => (
            <IncomingRFQ key={rfq.id} rfq={rfq} />
          ))}
        </CallsList>
      )}

      {tab === "outgoing" && (
        <CallsList>
          {outgoingList.map((rfq) => {
            return <OutgoingRFQ key={rfq.id} rfq={rfq} />;
          })}
        </CallsList>
      )}
      {tab === "cc" && <ClientCalls />}
    </Container>
  );
};

const flash = keyframes`
  0% {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  50% {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.90);
  }

  100% {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const Container = styled.div<{ animate?: boolean; large: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  gap: 10px;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${flash} 1s cubic-bezier(0.52, 0.24, 0.37, 0.85);
        `
      : null}
`;

const CallsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  height: 100%;
  width: 100%;
`;

export default BrokerDealer;

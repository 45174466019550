import { AxiosInstance } from "axios";
import {
  LoginDTO,
  LoginResponse,
  ModifiedUserType,
  RegisterDTO,
  RegisterResponse,
} from "types";
import { accessTokenKey, refreshTokenKey } from "utils/constants";

export default class AuthService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async login(data: LoginDTO): Promise<LoginResponse> {
    const response = await this.httpClient.post<LoginResponse>(
      "/api/o/token",
      data
    );
    return response.data;
  }
  async refreshToken(data: string): Promise<{ access: string }> {
    console.log({ data });

    const response = await this.httpClient.post<{ access: string }>(
      "/api/o/token/refresh",
      { refresh: data }
    );
    return response.data;
  }

  async register(data: RegisterDTO): Promise<RegisterResponse> {
    const response = await this.httpClient.post<RegisterResponse>(
      "/api/o/self_register",
      data
    );
    return response.data;
  }

  async editUser(data: ModifiedUserType): Promise<any> {
    const response = await this.httpClient.put<any>(
      `/api/user/${data.userId}`,
      data
    );
    return response.data;
  }
  async editUserEmailVerification(data: { email: string }): Promise<{ access: string, refresh: string }> {
    const response = await this.httpClient.put<{ access: string, refresh: string }>(
      `/api/user/edit_email`,
      data
    );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async logout() {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
    return true;
  }
  async verifyEmail(token: string) {
    const response = await this.httpClient.post<any>('/api/email-verify', { token })
    return response.data;
  }

  async sendEmailVerification() {
    const response = await this.httpClient.post<any>('/api/send_verificaiton_email')
    return response.data;
  }
}

import { TraderStat, TraderStatsPayload } from "./trainer";

export type CentralBankState = {
  settings: CentralBankSettings;
  traders_stats: TraderStat[];
  leaderboard?: any;
  events: CBEvent[];
  cb_state_loaded: boolean;
  parameter_tags: string[];
};

export type GroupsParameters = {
  code: ParameterTypes;
  description: string;
  id: number;
  name: string;
  room_id: string;
  value: number;
  tag: string;
  display_index: number;
};

export type GeneralParameters = {
  code: ParameterTypes;
  id: number;
  room_id: string;
  swift_id: string;
  value: number;
  min_value: number;
  max_value: number;
  tag: string;
  display_index: number;
};

export enum ParameterTypes {
  CLIENT_CALLS_ENABLED,
  CLIENT_CALL_LIFETIME_SECONDS,
  IMPACT_VOTE_SECONDS,
  RFQ_ENABLED,
  RFQ_USER_DECIDES_SECONDS,
  PLAYERS_SPOT_MAX_TICKET,
  PLAYERS_SPOT_MIN_TICKET,
  PLAYERS_VOL_MAX_TICKET,
  PLAYERS_VOL_MIN_TICKET,
  PLAYERS_TOTALORDERS_LIMIT,
  PLAYERS_RFQ_PLAYERS_QUOTE_MAX_SPREAD,
  AUTO_EXERCISE,
  TRADABLE_VOLS_PUT_CALL_OR_STRADDLE,
  PNL_DISPLAY_DIVIDER,
}

export type SettingsTable = {
  [key in ParameterTypes]?: GeneralParameters | GroupsParameters;
};

export type CentralBankSettings = {
  groups: GroupsParameters[];
  parameters: GeneralParameters[];
};

export type CBEvent = {
  event_data: { headline: string; body: string };
  id: number;
  room_id: string;
  room_time: number;
  delta_seconds: number;
  passed: boolean;
  published: boolean;
  market_impact: {
    [key: number]: {
      call_skew: number;
      lt_steep: number;
      put_skew: number;
      spot: number;
      st_steep: number;
      vol_parallel: number;
    };
  };
};

export type CentralBankPayload = {
  expected_price_curves: any;
  macro_events: any[];
  market_prices: any;
  morning_brief: any;
  price_curves: any;
  room: any;
  room_id: any;
  scope: any;
  settings: CentralBankSettings;
  swifts: any;
  traders: string[];
  traders_stats: any; // { [key: number]: TraderStatsPayload };
  leaderboard?: any;
};

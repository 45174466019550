import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  RFQCall,
  StateIndividualOptionOptions,
  StateOptionsBase,
  StateStraddleOptions,
  StateStranggleOptions,
  StrategyType,
} from "types";
import RfqQuote from "../BrokerDealer/RfqQuote";
import { useAppDispatch, getState } from "store/store";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import NumberInput from "components/reusable/NumberInput";
import { Button, Typography } from "antd";
import { div10, numberWithCommas, precision } from "utils/numbers";
import { rfqAnswerStrategyCall } from "store/rfq/actions";
import { RFQ_STATUS_STR, RFQ_WAITING, VOLATILITY_QUOTE } from "stateConstants";
import { generateOptionHeader } from "../ClientCalls/utils";
import { roomService } from "services";
import { selectBestBidAndAsk } from "store/newpricer/selectors";
import { convertDelta } from "utils/delta";
import { timeUnixToDatePretty } from "utils/time";

type Props = {
  call: RFQCall;
};

const calendarSpreadLongShortTerm = (options: any, sticky_model: number) => {
  const optionIds = Object.keys(options);
  const firstOption = options[optionIds[0]];
  const secondOption = options[optionIds[1]];

  const formatter = sticky_model
    ? (tenor: number) => convertDelta[tenor]
    : timeUnixToDatePretty;

  if (firstOption.tenor < secondOption.tenor) {
    return {
      shortTerm: formatter(firstOption.tenor),
      longTerm: formatter(secondOption.tenor),
    };
  }
  return {
    shortTerm: formatter(secondOption.tenor),
    longTerm: formatter(firstOption.tenor),
  };
};

const QuoteRFQ: React.FC<Props> = ({ call: rfq }) => {
  const dispatch = useAppDispatch();

  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  const vol_ticket_unit = useSelector(
    (state: RootState) => state.swifts.selectedSwift.vol_ticket_unit
  );
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const [quotedCall, setQuotedCall] = useState<RFQCall>(rfq);
  const originalRfqStatus = useSelector(
    (state: RootState) => state.rfq[rfq.id].status,
    shallowEqual
  );

  useEffect(() => {
    setQuotedCall(rfq);
  }, [rfq]);

  const handleAnswerCall = () => {
    const parsedQuotes = { ...quotedCall.strategy.options };
    Object.keys(parsedQuotes).forEach((q) => {
      parsedQuotes[parseInt(q)] = {
        ...parsedQuotes[parseInt(q)],
        volatility_ask: parsedQuotes[parseInt(q)].volatility_ask / 100,
        volatility_bid: parsedQuotes[parseInt(q)].volatility_bid / 100,
      };
    });
    dispatch(
      rfqAnswerStrategyCall({
        callId: quotedCall.id,
        quotes: parsedQuotes,
      })
    );
  };

  const handleKeyPress = (
    key: string,
    setter: any,
    value: any,
    update_key:
      | "volatility_bid"
      | "volatility_ask"
      | "premium_bid"
      | "premium_ask",
    id: number,
    enterFn?: any
  ) => {
    let power = 0;
    if (rfq.quote_type === VOLATILITY_QUOTE) power = 2;
    else power = sticky_model ? SPOT_DECIMAL_PLACES : 2;
    switch (key) {
      case "ArrowUp":
        setter(
          update_key,
          precision(parseFloat(value) + 50 / 10 ** power, power),
          id
        );
        break;
      case "ArrowDown":
        setter(
          update_key,
          precision(parseFloat(value) - 50 / 10 ** power, power),
          id
        );
        break;
      case "ArrowLeft":
        setter(
          update_key,
          precision(parseFloat(value) - 5 / 10 ** power, power),
          id
        );
        break;
      case "ArrowRight":
        setter(
          update_key,
          precision(parseFloat(value) + 5 / 10 ** power, power),
          id
        );
        break;
      case "Enter":
        if (enterFn) enterFn();
        break;
    }
  };
  const updateOption = (
    key: "volatility_bid" | "volatility_ask" | "premium_bid" | "premium_ask",
    e: string,
    id: number
  ) => {
    const value = parseFloat(e || "0") || 0;
    setQuotedCall((prev) => {
      const newStrategy = JSON.parse(JSON.stringify(prev.strategy));
      if (newStrategy.options) {
        const option = newStrategy.options[id];
        if (option) {
          option[key] = value;
        }
      }
      return {
        ...prev,
        strategy: newStrategy,
      };
    });
  };

  return (
    <Column
      style={{
        justifyContent: "center",
        alignItems: "center",
        gap: ".5rem",
        width: "100%",
        padding: "1rem",
        overflow: "auto",
      }}
    >
      {(quotedCall.strategy.strategy_name ===
        StrategyType.CALL_CALENDAR_SPREAD ||
        quotedCall.strategy.strategy_name ===
          StrategyType.PUT_CALENDAR_SPREAD) && (
        <>
          {" "}
          <Typography.Text>
            Long: BUY{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).longTerm
            }{" "}
            & SELL{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).shortTerm
            }
          </Typography.Text>
          <Typography.Text>
            Short: BUY{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).shortTerm
            }{" "}
            & SELL{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).longTerm
            }
          </Typography.Text>
        </>
      )}
      {Object.values(quotedCall.strategy.options).map((option, idx) => {
        return (
          <OptionContainer key={option.id}>
            {generateOptionHeader(
              option.tenor,
              option.strike,
              option.amount,
              sticky_model,
              option.put_or_call
            )}
            <Group>
              <Label>Bid</Label>
              <BidInput
                autoFocus
                fontSize={14}
                fontWeight={500}
                color="#F4F4F4"
                value={
                  rfq.quote_type === VOLATILITY_QUOTE
                    ? precision(option.volatility_bid, 2)
                    : option.premium_bid
                }
                suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.replaceAll(/[ %]/g, "");
                  updateOption(
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? "volatility_bid"
                      : "premium_bid",
                    value,
                    option.id
                  );
                }}
                onKeyDown={(e: any) => {
                  e.stopPropagation();
                  handleKeyPress(
                    e.key,
                    updateOption,
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? option.volatility_bid
                      : option.premium_bid,
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? "volatility_bid"
                      : "premium_bid",
                    option.id,
                    () => {}
                  );
                }}
              />
            </Group>

            <Group>
              <Label>Ask</Label>
              <AskInput
                fontSize={14}
                fontWeight={500}
                color="#F4F4F4"
                value={
                  rfq.quote_type === VOLATILITY_QUOTE
                    ? precision(option.volatility_ask, 2)
                    : option.premium_ask
                }
                suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  handleKeyPress(
                    e.key,
                    updateOption,
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? option.volatility_ask
                      : option.premium_ask,
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? "volatility_ask"
                      : "premium_ask",
                    option.id,
                    () => {}
                  );
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.replaceAll(/[ %]/g, "");
                  updateOption(
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? "volatility_ask"
                      : "premium_ask",
                    value,
                    option.id
                  );
                }}
              />
            </Group>

            {option.premium_ask !== 0 && option.premium_bid !== 0 && (
              <Column>
                <Typography.Paragraph
                  style={{ margin: 0, padding: 0, lineHeight: 1 }}
                >
                  {`Premium Bid: ${
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? numberWithCommas(precision(option.premium_bid, 2))
                      : numberWithCommas(
                          precision(
                            option.premium_bid *
                              vol_ticket_unit *
                              option.amount,
                            2
                          )
                        )
                  }$
              `}
                </Typography.Paragraph>
                <Typography.Paragraph
                  style={{ margin: 0, padding: 0, lineHeight: 1 }}
                >
                  {`Premium Ask: ${
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? numberWithCommas(precision(option.premium_ask, 2))
                      : numberWithCommas(
                          precision(
                            option.premium_ask *
                              vol_ticket_unit *
                              option.amount,
                            2
                          )
                        )
                  }$
              `}
                </Typography.Paragraph>
              </Column>
            )}
          </OptionContainer>
        );
      })}
      {quotedCall.strategy_price_ask && quotedCall.strategy_price_bid && (
        <Column>
          <Typography.Paragraph
            style={{ margin: 0, padding: 0, lineHeight: 1 }}
          >
            {`Strategy price bid: ${numberWithCommas(
              precision(quotedCall.strategy_price_bid, 2)
            )}$
          `}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ margin: 0, padding: 0, lineHeight: 1 }}
          >
            {`Strategy price ask: ${numberWithCommas(
              precision(quotedCall.strategy_price_ask, 2)
            )}$
          `}
          </Typography.Paragraph>
        </Column>
      )}

      {rfq.quote_type === VOLATILITY_QUOTE && (
        <Button
          onClick={async () => {
            const {
              options: _premiums,
              strategy_price_ask,
              strategy_price_bid,
            } = await roomService.getQuotedPremium(quotedCall);
            setQuotedCall((prev) => {
              const newStrategy = JSON.parse(JSON.stringify(prev.strategy));
              Object.keys(_premiums).forEach((option_id) => {
                if (newStrategy.options) {
                  if (newStrategy.options[parseInt(option_id)]) {
                    newStrategy.options[parseInt(option_id)].premium_bid =
                      _premiums[option_id].premium_bid;
                    newStrategy.options[parseInt(option_id)].premium_ask =
                      _premiums[option_id].premium_ask;
                  }
                }
              });
              return {
                ...prev,
                strategy: newStrategy,
                strategy_price_ask,
                strategy_price_bid,
              };
            });
          }}
        >
          Compute premiums
        </Button>
      )}
      {originalRfqStatus === RFQ_WAITING && (
        <Button
          onClick={() => {
            handleAnswerCall();
          }}
          style={{ marginTop: "1rem" }}
        >
          Send
        </Button>
      )}
      {originalRfqStatus !== RFQ_WAITING && (
        <Typography.Text style={{ color: "#8c8c8c" }}>
          {RFQ_STATUS_STR[originalRfqStatus]}
        </Typography.Text>
      )}
    </Column>
  );
};
const Input = styled(NumberInput)`
  background-color: #000;
  padding-right: 5px;
`;

const BidInput = styled(Input)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: #ff7875 !important;
    border: 1px solid #ff7875 !important;
  }
`;

const AskInput = styled(Input)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: #b7eb8f !important;
    border: 1px solid #b7eb8f !important;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const Label = styled.span`
  color: #ebebeb80;
`;

const OptionContainer = styled.div`
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default QuoteRFQ;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";

import NewsTicker from "components/GameRoom/NewsTicker";
import { Column } from "components/reusable/GenericStyledComponents";
import PausedModal from "components/Modal/PausedModal";
import GameRoomHeader from "components/GameRoom/GameRoomHeader";
import { useAppDispatch } from "store/store";
import { RootState } from "store/rootReducer";
import { requestInitialData, connectToRoom } from "store/room/actions";
import { roomDisconnect } from "store/room/reducers";
import { useConnectToWS } from "hooks/useConnectToWS";
import RoomTimeProvider from "contexts/RoomTimeProvider";
import GameLayout from "components/GameRoom/GameLayout";

const GameRoom: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  // CONNECT TO WS
  useConnectToWS(id);

  const { connected: socketConnected } = useSelector(
    (state: RootState) => state.socket
  );
  const { connected: roomConnected } = useSelector(
    (state: RootState) => state.room
  );

  // CONNECT AND REQUEST INITIAL DATA
  useEffect(() => {
    if (socketConnected && !roomConnected) {
      dispatch(connectToRoom(id));
    }

    if (roomConnected) {
      dispatch(requestInitialData());
    }
  }, [socketConnected, roomConnected]);

  // DISCONNECT
  useEffect(() => {
    return () => {
      dispatch(roomDisconnect());
    };
  }, []);

  return (
    <RoomTimeProvider>
      <Container>
        <Column
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: ".125rem",
          }}
        >
          <GameRoomHeader />
          <NewsTicker width={1435} />
          {/* <Game /> */}
        </Column>
        <GameLayout />
      </Container>
      <PausedModal />
    </RoomTimeProvider>
  );
};

const Container = styled(Column)`
  margin: 0.5% 0 1% 2%;

  overflow: auto;
  height: 100vh;
`;

export default GameRoom;

import axios from "axios";
import { useEffect } from "react";
import AuthService from "./auth.service";
import TrainerService from "./trainer.service";
import jwtDecode from "jwt-decode";

import { accessTokenKey, refreshTokenKey } from "utils/constants";
import RoomsService from "./rooms.service";
import AdminService from "./admin";
import ReportsService from "./reports.service";

function isTokenExpired(token: string) {
  try {
    const { exp } = jwtDecode<{ exp: number }>(token);

    return Date.now() >= exp * 1000;
  } catch (e) {
    return true;
  }
}

export const useConfigureServices = (baseUrl = ""): void => {
  useEffect(() => {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.post.Accept =
      "application/json, text/plain, multipart/form-data, */*";

    axios.interceptors.request.use(
      (config) => {
        console.log("Intercepting request...");
        const token = localStorage.getItem(accessTokenKey);
        if (token) {
          if (isTokenExpired(token)) {
            console.log("Token expired, redirecting to /login");
            localStorage.clear(); // Clearing storage to ensure stale tokens are not used
            // window.location.href = "/login";
            return Promise.reject("Token expired");
          }
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.status === 200 && response.config.url === "/api/o/token") {
          console.log("token response: ", response.data);
          localStorage.setItem(accessTokenKey, response.data.access);
          localStorage.setItem(refreshTokenKey, response.data.refresh);
        }
        return response;
      },
      (error) => {
        console.log("Handling error response...");
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest.retry) {
          originalRequest.retry = true;
          return axios
            .post<{ accessToken: string; refreshToken: string }>(
              "/api/o/token/refresh",
              {
                refresh: localStorage.getItem(refreshTokenKey),
              }
            )
            .then((res) => {
              if (res.status === 200) {
                console.log("refresh response:", res.data);

                localStorage.setItem(accessTokenKey, res.data.accessToken);
                axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
                  accessTokenKey
                )}`;
                return axios(originalRequest);
              }
              console.log("Refreshing token failed, redirecting to /login");
              localStorage.clear(); // Clearing storage to ensure stale tokens are not used
              // window.location.href = "/login";
              return Promise.reject("Error while refreshing token");
            })
            .catch((refreshError) => {
              console.log("Error during token refresh, redirecting to /login");
              localStorage.clear(); // Clearing storage to ensure stale tokens are not used
              // window.location.href = "/login";
              return Promise.reject(refreshError);
            });
        }
        return Promise.reject(error);
      }
    );
  }, [baseUrl]);
};

export const authService = new AuthService(axios);
export const trainerService = new TrainerService(axios);
export const roomService = new RoomsService(axios);
export const adminService = new AdminService(axios);
export const reportsService = new ReportsService(axios);

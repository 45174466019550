import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import StyledText from "../reusable/StyledText";
import { RootState } from "store/rootReducer";
import GenericModal from "./GenericModal";
import { useAppDispatch } from "store/store";
import { Permission, ROOM_TYPES } from "../../types";
import { message } from "antd";

const PausedModal = () => {
  const { status, room_type } = useSelector((state: RootState) => state.room);
  const { permission } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  if (
    permission === Permission.Trainer ||
    permission === Permission.Admin ||
    room_type === ROOM_TYPES.SINGLEPLAYER
  ) {
    // if (status === 2) message.info("The game is currently paused.", 5);
    return <></>;
  }
  return (
    <GenericModal
      isShown={status === 2}
      customClassName="MiddleModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      overlayBackground="#00000060"
    >
      <ModalContentContainer>
        <StyledText fontSize={38} fontWeight={700}>
          Room Paused!
        </StyledText>
        {/* 
        {(permission === Permission.Trainer ||
          permission === Permission.Admin) && (
          <Button
            style={{ backgroundColor: '#007e4d' }}
            onClick={() => {
              dispatch(resumeRoom());
            }}
          >
            Resume
          </Button>
        )} */}
      </ModalContentContainer>
    </GenericModal>
  );
};

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding: 41px 58px 0 58px;
`;

export default PausedModal;

import { createAction } from "@reduxjs/toolkit";
import {
  SPOT_CANCEL_ALL_EB_ORDERS,
  SPOT_DEAL_DONE,
  SPOT_PLACE_ORDER,
  SPOT_POSITION_PNL_UPDATED,
  SPOT_POSITION_UPDATED,
  SPOT_HIT_ORDER,
  SPOT_CANCEL_EB_ORDER,
  SPOT_VOL_EB_HIT,
  VOL_EB_DEPTH_CHANGED,
  REMOVE_TENOR,
  CREATE_TENOR,
  STRIKES_UPDATED,
  SPOT_PLACE_VOL_EB_ORDER,
  VOL_EB_ORDER_ADDED,
  VOL_EB_ORDER_CANCELED,
  VOL_EB_ORDER_EXECUTED,
  VOL_EB_CANCEL_ORDER,
  VOL_EB_DEPTHS_CHANGED,
} from "./constants";
import {
  SpotPositionUpdatedPayload,
  SpotDealDonePayload,
  SpotPlaceOrderPayload,
  SpotHitOrderPayload,
  SpotCancelEbOrderPayload,
  SpotVolEbHitPayload,
  VolEbDepthUpdatedPayload,
  VolEbDepthsUpdatedPayload,
  RemoveTenorPayload,
  CreateTenorPayload,
  StrikeUpdatedPayload,
  SpotPlaceOrderVolEbPayload,
  VolEbOrderCanceledPayload,
  VolEbOrderAddedPayload,
  VolEbOrderExecutedPayload,
  VolEbCancelOrderPayload,
} from "types";

export const spotHitOrder = createAction<SpotHitOrderPayload>(SPOT_HIT_ORDER);
export const spotPlaceOrder =
  createAction<SpotPlaceOrderPayload>(SPOT_PLACE_ORDER);
export const spotPlaceVolEbOrder = createAction<SpotPlaceOrderVolEbPayload>(
  SPOT_PLACE_VOL_EB_ORDER
);
export const cancelAllEbOrders = createAction(SPOT_CANCEL_ALL_EB_ORDERS);
export const cancelEbOrder =
  createAction<SpotCancelEbOrderPayload>(SPOT_CANCEL_EB_ORDER);
export const spotPositionUpdated = createAction<SpotPositionUpdatedPayload>(
  SPOT_POSITION_UPDATED
);
export const spotPositionPnlUpdated = createAction<SpotPositionUpdatedPayload>(
  SPOT_POSITION_PNL_UPDATED
);
export const spotDealDone = createAction<SpotDealDonePayload>(SPOT_DEAL_DONE);
export const spotVolEbHit = createAction<SpotVolEbHitPayload>(SPOT_VOL_EB_HIT);
export const VolEbDepthChanged =
  createAction<VolEbDepthUpdatedPayload>(VOL_EB_DEPTH_CHANGED);
export const VolEbDepthsChanged = createAction<VolEbDepthsUpdatedPayload>(
  VOL_EB_DEPTHS_CHANGED
);
export const removeTenor = createAction<RemoveTenorPayload>(REMOVE_TENOR);
export const createTenor = createAction<CreateTenorPayload>(CREATE_TENOR);
export const strikesUpdated =
  createAction<StrikeUpdatedPayload>(STRIKES_UPDATED);

export const volEbOrderAdded =
  createAction<VolEbOrderAddedPayload>(VOL_EB_ORDER_ADDED);
export const volEbOrderExecuted = createAction<VolEbOrderExecutedPayload>(
  VOL_EB_ORDER_EXECUTED
);
export const volEbOrderCanceled = createAction<VolEbOrderCanceledPayload>(
  VOL_EB_ORDER_CANCELED
);

export const volEbCancelOrder =
  createAction<VolEbCancelOrderPayload>(VOL_EB_CANCEL_ORDER);

import { createAction } from "@reduxjs/toolkit";
import {
  GET_DIRECTION_CONFIDENCE_SPOT,
  GET_DIRECTION_CONFIDENCE_VOL,
  NEWS_RECEIVE,
} from "./constants";
import { EventPublishedPayload, NewsVotedConfimationPayload, Swift, VoteNewsPayload } from "types";

export type DirectionConfidenceSpotPayload = {
  payload: {
    direction_confidence_values: {
      [key: string]: string;
    };
  };
  room_id: string;
};

export type DirectionConfidenceVolPayload = {
  payload: {
    direction_confidence_values: {
      [key: string]: {
        [key: string]: { [key: string]: string };
      };
    };
  };
  room_id: string;
};

export type UpdateVolEbDirectionConfidencePayload = {
  swift_id: string;
  impact: number;
  tenor: number;
  delta_strike: number;
};

export type UpdateSpotDirectionConfidencePayload = {
  swift_id: string;
  impact: number;
};

export type AddNewsPayload = { room_id: string; swift: Swift; news: any };

export const eventPublished = createAction<EventPublishedPayload>(NEWS_RECEIVE);
export const addNews = createAction<AddNewsPayload>("macro/newsAdded");
export const voteNews = createAction<VoteNewsPayload>("macro/voteNews");
export const newsVotedAction = createAction<NewsVotedConfimationPayload & { spot_or_vol: "spot" | "vol" }>("macro/newsVoted");

export const directionConfidenceSpotRefreshed =
  createAction<DirectionConfidenceSpotPayload>("spot/direction_confidence");
export const directionConfidenceVolRefreshed =
  createAction<DirectionConfidenceVolPayload>("vol_eb/direction_confidence");

export const getDirectionConfidenceSpot = createAction<{
  room_id: string;
  swift_id: string;
}>("spot/request_direction_confidence");

export const getDirectionConfidenceVol = createAction<{
  room_id: string;
  swift_id: string;
}>("vol_eb/request_direction_confidence");

export const updateDirectionConfidenceSpot =
  createAction<UpdateSpotDirectionConfidencePayload>(
    "spot/update_direction_confidence"
  );
export const updateDirectionConfidenceVol =
  createAction<UpdateVolEbDirectionConfidencePayload>(
    "vol_eb/update_direction_confidence"
  );


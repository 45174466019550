import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import axios from "axios";
import { settings } from "cluster";

type State = {
  workersUp: boolean;
  nbWorkersUp: number;
  maxWorkers: number;
  loading: boolean;
  actionTaken: boolean;
  status: string;
  setActionTaken: React.Dispatch<React.SetStateAction<boolean>>;
  startServers: () => void;
  stopServers: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const ServerStatusContext = createContext<State | undefined>(undefined);

const getStatus = (
  loading: boolean,
  prevWorkers: number,
  currentWorkers: number,
  total: number
) => {
  if (loading) {
    return "Fetching...";
  }
  if (prevWorkers < currentWorkers) {
    return "Workers are starting";
  }
  if (prevWorkers > currentWorkers) {
    return "Workers are stopping";
  }
  if (prevWorkers === currentWorkers && currentWorkers === total) {
    return "All workers are up!";
  }
  if (prevWorkers === currentWorkers && currentWorkers === 0) {
    return "Workers are stopped";
  }
  return "";
};

const ServerStatusProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  const [workersUp, setWorkersUp] = useState(false);
  const [nbWorkersUp, setNbWorkersUp] = useState(0);
  const [maxWorkers, setMaxWorkers] = useState(24);
  const [loading, setLoading] = useState(true);
  const [actionTaken, setActionTaken] = useState(false);
  const [status, setStatus] = useState("Fetching...");

  useEffect(() => {
    setWorkersUp(workersUp);
  }, [workersUp]);

  const checkStatus = () => {
    if (isAuthenticated && process.env.REACT_APP_GOAL) {
      axios
        .get("api/backend/game_subsystem_status")
        .then((res) => {
          const { started_services } = res.data;
          const currentWorkers = parseInt(started_services.split("/")[0]);
          const allWorkers = parseInt(started_services.split("/")[1]);
          setStatus(
            getStatus(loading, nbWorkersUp, currentWorkers, allWorkers)
          );
          if (currentWorkers !== nbWorkersUp) setNbWorkersUp(currentWorkers);
          setMaxWorkers(allWorkers);
          setWorkersUp(currentWorkers === allWorkers);
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) checkStatus();
    const interval = setInterval(checkStatus, loading ? 500 : 3000);
    return () => {
      clearInterval(interval);
      mounted = false;
    };
  }, [checkStatus]);

  const startServers = () => {
    axios.post("api/backend/game_subsystem_start", { factor: 1 });
  };

  const stopServers = () => {
    axios.post("api/backend/game_subsystem_start", { factor: 0 });
  };

  const state = {
    workersUp,
    nbWorkersUp,
    maxWorkers,
    loading,
    status,
    startServers,
    stopServers,
    setLoading,
    actionTaken,
    setActionTaken,
  };

  return (
    <ServerStatusContext.Provider value={state}>
      {children}
    </ServerStatusContext.Provider>
  );
};

export default ServerStatusProvider;

export const useServerStatusContext = () => {
  const context = useContext(ServerStatusContext);
  if (context === undefined) {
    throw new Error(
      "Cannot use useServerStatusContext outside ServerStatusProvider"
    );
  }
  return context;
};

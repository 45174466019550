import AdminScenarioModal from "components/Modal/AdminScenarioModal";
import ConfirmationPromptModal from "components/Modal/ConfirmationPromptModal";
import CustomTable from "components/reusable/CustomTable";
import {
  Column,
  Row,
  DataContainer,
  ViewClassButton,
} from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useState } from "react";
import { adminService } from "services";
import styled from "styled-components";
import { DataRow, AdminScenarioBE } from "types";
import { formatTime } from "utils/time";
import AddNewScenario from "./AddNewScenario";
import { empty_scenario_defaults } from "./constants";
import { Button } from "antd";

const AdminScenario: React.FC<{
  scenarios: AdminScenarioBE[];
  fetchScenarios: () => Promise<void>;
}> = ({ scenarios, fetchScenarios }) => {
  const [selectedAdminScenario, setSelectedAdminScenario] = useState(
    empty_scenario_defaults
  );
  const [deletionId, setDeletionId] = useState<number>(-1);
  const [isShown, setIsShown] = useState(false);
  const [deletePromptShown, setDeletePromptShown] = useState(false);

  return (
    <Column style={{ height: "100%", overflow: "auto" }}>
      <ConfirmationPromptModal
        prompt="Are you sure you want to delete this scenario"
        isShown={deletePromptShown}
        onClose={() => {
          setDeletePromptShown(false);
        }}
        action={() =>
          adminService.deleteScenario(deletionId).then(async (res) => {
            if (res === "ok") {
              await fetchScenarios();
            }
          })
        }
      />
      <StyledText fontSize={20} fontWeight={600} fontFamily="IBMPlexSans">
        Create or edit scenarios
      </StyledText>
      <DataContainer>
        <Column
          style={{
            alignItems: "center",
            gap: ".75rem",
            height: "100%",
            overflow: "auto",
          }}
        >
          <CustomTable
            header={[
              "Scenario name",
              "Asset class",
              "Instrument",
              "Created",
              "Updated",
              "By",
              "Visible",
              "Actions",
            ]}
            data={[
              ...scenarios.map(
                (scenario): DataRow => ({
                  data: [
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={500}
                          color={scenario.visible ? "#FFFFFF90" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.name} --{" "}
                          {scenario.visible ? "visible" : "not visible"}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.swift[0].asset_class}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.swift[0].code}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {formatTime(scenario.created)}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.last_updated_on}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.last_updated_by}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.visible ? "Yes" : "No"}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Row style={{ gap: ".5rem" }}>
                        <ActionButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeletePromptShown(true);
                            setDeletionId(scenario.id);
                          }}
                        >
                          <StyledText
                            fontSize={14}
                            fontWeight={400}
                            color="#FFFFFF70"
                            fontFamily="IBMPlexSans"
                          >
                            Delete scenario
                          </StyledText>
                        </ActionButton>
                        <ActionButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAdminScenario(scenario);
                            setIsShown(true);
                          }}
                        >
                          <StyledText
                            fontSize={14}
                            fontWeight={400}
                            color="#FFFFFF70"
                            fontFamily="IBMPlexSans"
                          >
                            Edit scenario
                          </StyledText>
                        </ActionButton>
                      </Row>
                    ),
                  ],
                })
              ),
            ]}
          />
          <AddNewScenario
            setSelectedScenario={() => {
              setSelectedAdminScenario(empty_scenario_defaults);
            }}
            setIsShown={() => {
              setIsShown(true);
            }}
          />
        </Column>
      </DataContainer>
      <AdminScenarioModal
        setIsShown={setIsShown}
        selectedScenario={selectedAdminScenario}
        setSelectedScenario={setSelectedAdminScenario}
        scenarioModalShown={isShown}
        fetchScenarios={fetchScenarios}
      />
    </Column>
  );
};
const ActionButton = styled(Button)`
  border: 1px solid #ffffff40;
  border-radius: 6px;
  height: 50px;
  margin: 0;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color:{scenario.visible ?  white;
 : "red"}
    & p {
      color:{scenario.visible ?  white;
    } : "red"}
  }
`;

export default AdminScenario;

import {
  Column,
  FloatInput,
  FloatInputNumber,
  Row,
} from "components/reusable/GenericStyledComponents";
import React, { useEffect, useState } from "react";
import {
  MATURITY,
  NewTrainerScenarioBE,
  Pylons,
  TrainerScenarioBE,
  YieldCurvesBE,
} from "types";
import ScenarioListEdit from "./ScenarioListEdit";
import NewsComponent from "./NewsComponent";
import SwiftEdit from "./SwiftEdit";
import ParametersComponent from "./ParametersComponent";
import PriceCurves from "./PriceCurves";
import PylonsComponent from "./Pylons";
import { adminService } from "services";
import AddNews from "./AddNews";
import styled from "styled-components";
import ContinuousTenors from "./ContinuousTenors";
import { parsePylonsIndexKey, parsePylonsTenorKeys } from "../utils";
import { Checkbox, InputNumber, Input, Button } from "antd";
import FloatLabel from "components/reusable/FloatLabel";
import YieldCurvesComponent from "./YieldCurvesComponent";

const { TextArea } = Input;

const NewsDescription: React.FC = () => (
  <div>
    <p>SPOT = Spot movement: UP/+ or DOWN/-</p>

    <p>Vol / P = Parallel shift in the volatility surface</p>

    <p>CallSK = Skewness of CALLS</p>

    <p>PutSK = Skewness of PUTS</p>

    <p>SteepST = Steepness in the short end of the volatility surface</p>

    <p>SteepLT = Steepness in the long end of the volatility surface</p>
  </div>
);

const AdminScenarioModalContentForm: React.FC<{
  scenario: TrainerScenarioBE;
  fetchScenarios: () => Promise<void>;
  closeModal: () => void;
}> = ({ scenario, fetchScenarios, closeModal }) => {
  const [duration, setDuration] = useState(scenario.duration);
  const [morning_brief, setMorningBrief] = useState(scenario.morning_brief);
  const [name, setName] = useState(scenario.name);
  const [news, setNews] = useState(scenario.news);
  const [parameters, setParameters] = useState(scenario.parameters.parameters);
  const [swift, setSwift] = useState(scenario.swift[0]);
  const [price_curves, setPriceCurves] = useState<number[]>(
    scenario.price_curves[swift.code]
  );
  const [yield_curves, setYieldCurves] = useState<YieldCurvesBE>(
    scenario.yield_curves
  );
  const [pylons, setPylons] = useState<Pylons>(
    swift.sticky_model
      ? parsePylonsTenorKeys(scenario.pylons, scenario.relative_tenors)
      : scenario.pylons
  );
  const [description, setDescription] = useState(scenario.description);
  const [continuousTenors, setContinuousTenors] = useState<number[]>(
    scenario.relative_tenors
  );
  const isStickyDelta = swift.sticky_model === 1;
  const [visible, setVisible] = useState<boolean>(scenario.visible);
  return (
    <Column style={{ width: "100%", alignItems: "center", gap: "1rem" }}>
      <Checkbox
        checked={visible}
        onChange={(e) => setVisible(e.target.checked)}
      >
        Visible
      </Checkbox>
      <Row style={{ gap: "2rem", alignItems: "center" }}>
        <FloatLabel label="Name" value={name}>
          <FloatInput
            style={{ width: "60ch" }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FloatLabel>
        <FloatLabel label="Duration" value={duration}>
          <FloatInputNumber
            value={duration}
            onChange={(value) => {
              setDuration((value as number) || 0);
            }}
          />
        </FloatLabel>
      </Row>
      <div style={{ marginLeft: "20%", marginRight: "20%", width: "80%" }}>
        <FloatLabel label="Description" value={description}>
          <TextArea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            autoSize
          />
        </FloatLabel>
      </div>
      <div style={{ marginLeft: "20%", marginRight: "20%", width: "80%" }}>
        <FloatLabel label="Morning brief" value={morning_brief}>
          <TextArea
            value={morning_brief}
            onChange={(e) => {
              setMorningBrief(e.target.value);
            }}
            autoSize
          />
        </FloatLabel>
      </div>
      <SwiftEdit swift={swift} setSwift={setSwift} />
      <ScenarioListEdit
        field={news}
        setField={setNews}
        label="News"
        title="News"
        sorted
        sortKey="delta_seconds"
        Description={NewsDescription}
        Component={NewsComponent}
        AddNewComponent={AddNews}
        AddNewComponentProps={{ swift: swift.code }}
        unique_key="headline"
      />
      <ScenarioListEdit
        field={parameters}
        setField={setParameters}
        label="Parameters"
        title="Parameters"
        Component={ParametersComponent}
        unique_key="code"
      />
      <PriceCurves
        price_curves={price_curves}
        setPriceCurves={setPriceCurves}
      />
      <YieldCurvesComponent
        yield_curves={yield_curves}
        setYieldCurves={setYieldCurves}
      />
      <PylonsComponent
        pylons={pylons}
        setPylons={setPylons}
        sticky_model={swift.sticky_model}
      />
      <Line />
      {isStickyDelta && (
        <>
          <ContinuousTenors
            continuousTenors={continuousTenors}
            setContinuousTenors={setContinuousTenors}
            pylons={pylons}
            setPylons={setPylons}
          />
          <Line />
        </>
      )}
      <Button
        onClick={() => {
          const newScenario: NewTrainerScenarioBE = {
            id: scenario.id,
            visible,
            morning_brief,
            name,
            description,
            duration,
            parameters: { parameters },
            news,
            swift: [swift],
            pylons: parsePylonsIndexKey(pylons),
            price_curves: { [swift.code]: price_curves },
            yield_curves,
            created: scenario.created,
            forecasts: [],
            relative_tenors: continuousTenors,
          };
          if (scenario.id === -1) {
            adminService
              .uploadScenarioJson(newScenario)
              .then(async (res) => {
                await fetchScenarios();
                closeModal();
              })
              .catch((err) => {
                alert(err);
              });
          } else {
            adminService
              .updateScenarioJson(newScenario)
              .then(async (res) => {
                await fetchScenarios();
                closeModal();
              })
              .catch((err) => {
                alert(err);
              });
          }
        }}
      >
        Save changes
      </Button>
    </Column>
  );
};
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

export default AdminScenarioModalContentForm;

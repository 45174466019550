import { message } from "antd";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import {
  TrainerClassesRoom,
  TrainerLiveSession,
  TrainerScenarioBE,
} from "types";

export interface ClassDTO {
  id: number;
  name: string;
  assigned_students: number[];
}

export default class TrainerService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.httpClient.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  };

  private handleResponse = (response: AxiosResponse) => response;

  private handleError = (error: AxiosError) => {
    // Handle the error as needed
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      message.error(error.response.data?.error || error.response.statusText);
    } else if (error.request) {
      // The request was made but no response was received
      message.error("No response was received from the server.");
    } else {
      // Something happened in setting up the request that triggered an Error
      message.error("Error in setting up the request.");
    }

    return Promise.reject(error);
  };
  async getTrainerClasses(): Promise<TrainerClassesRoom[]> {
    const response = await this.httpClient.get<TrainerClassesRoom[]>(
      "/api/get_trainer_classes"
    );
    return response.data;
  }

  async getTrainerScenarios(): Promise<TrainerScenarioBE[]> {
    const response = await this.httpClient.get<TrainerScenarioBE[]>(
      "/api/get_scenarios"
    );
    return response.data;
  }

  async getTrainerLiveRooms(): Promise<TrainerLiveSession[]> {
    const response = await this.httpClient.get<TrainerLiveSession[]>(
      "/api/get_rooms/ongoing/contains_class/1"
    );
    return response.data;
  }
  async getTrainerHistoryRooms(): Promise<TrainerLiveSession[]> {
    const response = await this.httpClient.get<TrainerLiveSession[]>(
      "/api/get_rooms/history/contains_class/1"
    );
    return response.data;
  }

  async getAllTraders(roomId: any): Promise<any> {
    const response = await this.httpClient.get<any>(
      `/api/get_room_traders/room=${roomId}`
    );
    return response.data;
  }

  async historical_vols(roomId: any): Promise<any> {
    const response = await this.httpClient.get(
      `/api/historical_volatilities/room=${roomId}`,
      {
        responseType: "text",
      }
    );
    return response.data;
  }

  async advanceTournament(tournament_id: number): Promise<any> {
    const response = await this.httpClient.post(
      `/api/advance_tournament`,
      { tournament_id }
    );
    return response.data;
  }
}

export const trainerService = new TrainerService(axios);

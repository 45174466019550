import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { authenticate, register } from "store/auth/actions";
import { useAppDispatch } from "store/store";
import { RootState } from "store/rootReducer";
import { Input, Button, Typography, Tabs, Form } from "antd";
import { Column } from "./reusable/GenericStyledComponents";
import { useHistory, useLocation } from "react-router";

const { Paragraph } = Typography;
const { TabPane } = Tabs;

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const authError = useSelector((state: RootState) => state.auth.authError);
  const registerError = useSelector(
    (state: RootState) => state.auth.registerError
  );

  const [login, setLogin] = useState({
    username: "",
    password: "",
  });

  const [registerInfo, setRegisterInfo] = useState({
    first_name: "",
    last_name: "",
    username: "",
    password: "",
  });

  const handleLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleRegisterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterInfo({ ...registerInfo, [e.target.name]: e.target.value });
  };

  const onRegisterFinish = (values: any) => {
    dispatch(register(values));
  };

  return (
    <StyledTabWrapper>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Login" key="1">
          {/* Login Form */}
          <Column style={{ justifyContent: "center" }}>
            <LoginInput
              name="username"
              type="text"
              placeholder="Username"
              value={login.username}
              onChange={handleLoginChange}
            />
            <LoginInput
              name="password"
              type="password"
              placeholder="Password"
              value={login.password}
              onChange={handleLoginChange}
            />
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                dispatch(authenticate(login));
                setLoading(true);
                setTimeout(() => {
                  history.push("/lobby");
                }, 1000);
              }}
            >
              Login
            </Button>
            {authError && <Paragraph>{authError}</Paragraph>}
          </Column>
        </TabPane>
        <TabPane tab="Register" key="2">
          {/* Register Form */}
          <Column style={{ justifyContent: "center" }}>
            <Form
              name="register"
              onFinish={onRegisterFinish}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="first_name"
                label="First name"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>

              <Form.Item
                name="last_name"
                label="Last name"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>

              <Form.Item
                name="username"
                label="Username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>
            {/* <Button
              type="primary"
              onClick={() => {
                dispatch(register(registerInfo));
              }}
            >
              Register
            </Button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {registerError && <Paragraph>{registerError}</Paragraph>}
            </div>
          </Column>
        </TabPane>
      </Tabs>
    </StyledTabWrapper>
  );
};

const StyledTabWrapper = styled.div`
  .ant-tabs-nav {
    margin-bottom: 20px;
  }
  .ant-tabs-tab {
    padding: 12px 16px;
  }
`;

const LoginInput = styled(Input)`
  color: ${({ theme }) => theme.color3};
  text-align: center;
  font-size: 18px;
  background-color: #000;
  margin-bottom: 10px;
`;

export default LoginForm;

import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { getRoomData } from "utils/rooms";
import {
  CreateRoomPlayer,
  CreateRoomTrainer,
  RFQCall,
  Room,
  Tournament,
  TrainerScenarioBE,
} from "types";
import { message } from "antd";

export default class RoomsService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.httpClient.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  };

  private handleResponse = (response: AxiosResponse) => response;

  private handleError = (error: AxiosError) => {
    // Handle the error as needed
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      message.error(error.response.data?.error || error.response.statusText);
    } else if (error.request) {
      // The request was made but no response was received
      message.error("No response was received from the server.");
    } else {
      // Something happened in setting up the request that triggered an Error
      message.error("Error in setting up the request.");
    }

    return Promise.reject(error);
  };

  async createRoom(
    classId: number,
    players: CreateRoomPlayer[],
    trainers: CreateRoomTrainer[],
    ownerId: number,
    scenario: TrainerScenarioBE
  ): Promise<string> {
    const fswift = parseInt(Object.keys(scenario.swift)[0]);

    const roomData = getRoomData(classId, players, trainers, ownerId, {
      ...scenario,
      swifts: [scenario.swift[fswift]],
    });
    const response = await this.httpClient.post<{ id: string }>(
      "/api/create_room",
      roomData
    );
    return response.data.id;
  }

  async getVolatility(
    roomId: string,
    strike: number,
    tenor: number
  ): Promise<number> {
    const response = await this.httpClient.post<{ volatility: number }>(
      "/api/get_volatility",
      {
        room_id: roomId,
        strike,
        tenor,
        percent: null,
      }
    );
    return response.data.volatility * 100;
  }
  async getPremium(
    call_id: number
  ): Promise<{ strategy_bid: number; strategy_ask: number }> {
    const response = await this.httpClient.post<{
      strategy_bid: number;
      strategy_ask: number;
    }>("/api/get_strategy_price", {
      call_id,
    });
    return response.data;
  }
  async getQuotedPremium(call: RFQCall): Promise<{
    options: { [key: string]: { premium_bid: number; premium_ask: number } };
    strategy_price_bid: number;
    strategy_price_ask: number;
  }> {
    const response = await this.httpClient.post<{
      options: { [key: string]: { premium_bid: number; premium_ask: number } };
      strategy_price_bid: number;
      strategy_price_ask: number;
    }>("/api/get_strategy_price", {
      call,
    });

    return response.data;
  }

  async getRooms(): Promise<Room[]> {
    const response = await this.httpClient.get<Room[]>(
      "/api/get_rooms/ongoing"
    );
    return response.data;
  }
  async getTournaments(): Promise<Tournament[]> {
    const response = await this.httpClient.get<Tournament[]>(
      "/api/tournaments"
    );
    return response.data;
  }
  async createSinglePlayerSession(scenario: number): Promise<string> {
    const response = await this.httpClient.post<{ id: string }>(
      "/api/create_single_player_room",
      {
        scenario,
      }
    );
    return response.data.id;
  }
  async createTournamentSinglePlayerSession(tournament_session: number): Promise<string> {
    const response = await this.httpClient.post<{ id: string }>(
      "/api/create_single_player_room",
      {
        tournament_session,
      }
    );
    return response.data.id;
  }

  async joinTournament(join_code: string): Promise<any> {
    const response = await this.httpClient.post<{ join_code: string }>(
      "/api/join_tournament",
      {
        join_code,
      }
    );
    return response.data;
  }
  async getTournamentLeaderboard(tournament_id: number): Promise<{ formated: any, leaderboard: any, total_nb_players: number }> {
    const response = await this.httpClient.get(
      `api/get_tournament_leaderboard/${tournament_id}`
    );
    return response.data;
  }


}

export const roomsService = new RoomsService(axios);

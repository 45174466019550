import { Button, Card, Table, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import { stat } from "fs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { roomService } from "services";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import {
  Tournament,
  TournamentLeaderboardLineType,
  TournamentStatus,
} from "types";

type EtcLine = { id: number; etcCount: number; isEtc: boolean };

type TableLine =
  | (TournamentLeaderboardLineType & { passed: boolean })
  | EtcLine;

const LobbyLeaderboard: React.FC<{ tournament: Tournament }> = ({
  tournament,
}) => {
  const [leaderboard, setLeaderboard] = useState<
    TournamentLeaderboardLineType[]
  >([]);
  const [formattedLeaderboard, setFormattedLeaderboard] = useState<any>([]);
  const [nb_playing, setNbPlaying] = useState<number>(0);
  const { persistentId: currentUserId } = useSelector(
    (state: RootState) => state.user
  );
  const fetchLeaderboard = async () => {
    const response = await roomService.getTournamentLeaderboard(tournament.id);
    setNbPlaying(response.total_nb_players);
    // Calculate the threshold score index for passing
    const passRate =
      Number(tournament.current_stage?.pass_rate || 0) / 100 || 0;
    const passIndex = Math.ceil(leaderboard.length * passRate) - 1;
    setLeaderboard(
      response.leaderboard.map((usr: any, idx: number) => {
        return {
          ...usr,
          index: idx,
          passed: idx >= passIndex,
        };
      })
    );
    setFormattedLeaderboard(response.formated);
  };
  useEffect(() => {
    if (tournament.status !== TournamentStatus.Created) fetchLeaderboard();
    // const req = setInterval(() => {
    //   fetchLeaderboard();
    // }, 4000);
    // return () => {
    //   clearInterval(req);
    // };
  }, [tournament]);

  const parseLeaderboard = (_formattedLeaderboard: any[]): TableLine[] => {
    console.log({ formattedLeaderboard }, { leaderboard });

    return _formattedLeaderboard.map((item, idx) => {
      if (item.text && item.text.user) {
        // If the item is a user, return it as a UserLine.
        return {
          ...item.text.user,
        };
      }
      if (item.text && typeof item.text.text === "string") {
        // If the item is a text, return it as an EtcLine.
        return {
          id: Math.random(), // Assign a random ID for React keys.
          etcCount: parseInt(item.text.text.match(/\d+/)[0], 10),
          isEtc: true,
        };
      }

      throw new Error("Unknown leaderboard item type");
    });
  };

  // Custom row styling
  const rowClassName = (record: any) => {
    if (record.id === currentUserId) {
      return "highlighted-row";
    }
    if (record.isEtc) {
      return "etc-row";
    }
    return "";
  };

  if (!leaderboard || !leaderboard.length) return null;
  return (
    <Container>
      <CustomTable
        title={() => (
          <>
            <Column
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                {tournament.name} leaderboard
              </Typography.Text>
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "3rem",
                }}
              >
                <Button
                  onClick={() => {
                    fetchLeaderboard();
                  }}
                >
                  refresh
                </Button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  Total of {leaderboard.length} players
                </div>
              </Row>
            </Column>
          </>
        )}
        scroll={{ y: 500 }}
        sticky
        bordered={false}
        pagination={false}
        dataSource={parseLeaderboard(formattedLeaderboard)}
        columns={columns}
        rowKey="id"
        rowClassName={rowClassName}
      />
    </Container>
  );
};

const LeaderboardContainer = styled(Column)`
  padding: 1rem 2rem;
`;
const Container = styled(Card)`
  width: 30%;
  gap: 1rem;
`;

const CustomTable = styled(Table)`
  // height: 80%;
  width: 30vw;
  .highlighted-row {
    background-color: #ffd36910;
    box-shadow: 0 0 0.5rem #ffd36980, inset 0 0 1.5rem #ffd36980;
  }

  .etc-row {
    text-align: center;
    font-style: italic;
    /* any other styling for 'etc' rows */
  }
`;
const columns: any = [
  {
    title: "Position",
    dataIndex: "index",
    key: "index",
    render: (text: string, record: TableLine) => {
      if ("isEtc" in record && record.etcCount !== undefined) {
        return ``;
      }
      return text;
    },
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    render: (text: string, record: TableLine) => {
      if ("isEtc" in record && record.etcCount !== undefined) {
        return `... and ${record.etcCount} more ...`;
      }
      return text;
    },
  },
  {
    title: "Total Score",
    dataIndex: "total_score",
    key: "total_score",
    render: (text: string, record: TableLine) => {
      if (!("isEtc" in record)) {
        return text;
      }
      return "";
    },
  },
  {
    title: "Eligible for next stage",
    dataIndex: "passed",
    key: "passed",
    render: (passed: boolean, record: TableLine) => {
      if (!("isEtc" in record)) {
        return passed ? "Yes" : "No";
      }
      return "";
    },
  },
];

export default LobbyLeaderboard;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";
import { LayoutComponentKey } from "types";

// Selector that returns the layout array filtered by activeComponents
export const selectActiveLayout = createSelector(
  (state: RootState) => state.layout.components,
  (state: RootState) => state.layout.activeComponents,
  (components, activeComponents) => {
    return components.filter((component) => activeComponents[component.i as LayoutComponentKey]);
  }
);

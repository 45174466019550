import { createSlice } from "@reduxjs/toolkit";
import { STRADDLE, OPTION } from "stateConstants";

import { InitialDataPayload, ROOM_TYPES, RoomState } from "types";
import {
  clearRoom,
  retrieveInitialData,
  updateRoom,
  roomPause,
  resumeRoom,
  finishRoom,
  strategyChanged,
  recievePong,
  yieldsUpdated,
} from "./actions";

const initialState: () => RoomState = () => ({
  room_id: "",
  name: "",
  status: 0,
  strategy: STRADDLE,
  duration: 0,
  room_time: 0,
  be_room_time: 0,
  time_left: 0,
  expires_at: undefined,
  morning_brief: "",
  paused: false,
  connected: false,
  created_at_game_date: "",
  game_date: "",
  start_date: "",
  tenors: [],
  yield_curves: { 0: { base_yc: {}, variable_yc: {} } },
  room_type: ROOM_TYPES.SINGLEPLAYER,
  tournament: null,
});

const roomSlice = createSlice({
  initialState,
  name: "room",
  reducers: {
    roomConnectedSuccess: (state) => {
      state.connected = true;
    },
    roomDisconnect: (state) => {
      state.connected = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveInitialData, (state, action) => {
        const { room, morning_brief, yield_curves, tenors } = action.payload;
        state.yield_curves = yield_curves;
        state.room_id = room.room_id;
        state.name = room.name;
        state.status = room.status;
        state.paused = room.status !== 1;
        state.duration = room.duration;
        state.room_time = room.room_time;
        state.time_left = room.time_left;
        state.expires_at = room.expires_at;
        state.start_date = room.start_date;
        state.created_at_game_date = room.created_at_game_date;
        state.game_date = room.game_date;
        state.tournament = room.tournament;
        state.tenors = tenors;
        // Morning brief
        state.morning_brief = morning_brief;
      })
      .addCase(updateRoom, (state, action) => {
        const { status, duration, room_time, time_left, expires_at } =
          action.payload;
        state.status = status;
        state.duration = duration;
        state.room_time = room_time;
        state.time_left = time_left;
        state.expires_at = expires_at;
      })
      .addCase(roomPause, (state) => {
        state.paused = true;
      })
      .addCase(resumeRoom, (state) => {
        state.paused = false;
      })
      .addCase(finishRoom, (state) => {
        state.status = 3;
      })
      .addCase(strategyChanged, (state, action) => {
        state.strategy = action.payload;
      })
      .addCase(recievePong, (state, action) => {
        const { room_time } = action.payload;
        state.be_room_time = room_time;
        // if (state.room_time < room_time)
        //   state.room_time = room_time;
      })
      .addCase(yieldsUpdated, (state, action) => {
        state.yield_curves = { ...action.payload.yield_curves };
      })
      .addCase(clearRoom, initialState);
  },
});

export default roomSlice.reducer;
export const { roomConnectedSuccess, roomDisconnect } = roomSlice.actions;

import { LONG, SHORT, BID, ASK, STRADDLE, OPTION } from "stateConstants";
import { MacroEventPayload } from "./events";
import {
  SpotPositionUpdatedPayload,
  VolEbOrderPayload,
  Tenor,
  OHLCPoint,
} from "./spot";
import { EbOrderPayload, SimpleEbOrderPayload } from "./ebOrders";
import { SpotDealPayload, OptionPayload } from "./blotter";
import { SwiftPayload } from "./swifts";
import { VolChartPointPayload, VolatilityCurves } from "./volatility";
import { RisksPayload } from "./trader";
import { ClientCall, RFQCall, Tournament } from "types";
import { Layout } from "react-grid-layout";

export type RoomState = {
  room_id: string;
  name: string;
  status: number;
  duration: number;
  room_time: number;
  be_room_time: number;
  time_left: number;
  expires_at: unknown;
  created_at_game_date: string;
  room_type: 0 | 1;
  game_date: string;
  start_date: string;

  connected: boolean;
  paused: boolean;
  morning_brief: string;
  tenors: { [key: number]: number[] };

  cbState?: any;
  strategy: ROOM_TYPES;
  yield_curves: SwiftYieldCurve;
  tournament: Tournament | null;
};

export enum ROOM_TYPES {
  SINGLEPLAYER,
  MULTIPLAYER,
}

export type SwiftYieldCurve = {
  [key: string]: YieldCurve;
};

export type YieldCurve = {
  base_yc: { [key: number]: string };
  variable_yc: { [key: number]: string };
};

export enum SellBuy {
  SELL,
  BUY,
}

export interface MarketPriceDTO {
  ask: string;
  bid: string;
  open: string;
  price: string;
  swift_id: number;
}

export type PriceCurveDTO = {
  points: { price: string; room_time: number; room_date: number }[];
  swift_id: number;
};

// TODO: Add {amount, volatility} object... what's its name??
export type Delta = {
  [key: number]: {
    "0": { amount: number; volatility: string; traders: string[] };
    "1": { amount: number; volatility: string; traders: string[] };
  };
  // 0.10: {
  //   "0": { amount: number; volatility: string; traders: string[] };
  //   "1": { amount: number; volatility: string; traders: string[] };
  // };
  // 0.25: {
  //   "0": { amount: number; volatility: string; traders: string[] };
  //   "1": { amount: number; volatility: string; traders: string[] };
  // };
  // 0.50: {
  //   "0": { amount: number; volatility: string; traders: string[] };
  //   "1": { amount: number; volatility: string; traders: string[] };
  // };
  // 0.75: {
  //   "0": { amount: number; volatility: string; traders: string[] };
  //   "1": { amount: number; volatility: string; traders: string[] };
  // };
  // 0.90: {
  //   "0": { amount: number; volatility: string; traders: string[] };
  //   "1": { amount: number; volatility: string; traders: string[] };
  // };
};

export interface RoomPayload {
  room_id: string;
  name: string;
  status: number;
  duration: number;
  room_time: number;
  time_left: number;
  expires_at: unknown;
  created_at_game_date: string;
  game_date: string;
  start_date: string;
  tournament: Tournament | null;
}

export interface SettingsDTO {
  general: {
    TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: number;
    DARKPOOL_ENABLED: number;
    RFQ_ENABLED: number;
    RFQ_STRATEGIES_ENABLED: number;
    RFQ_USER_DECIDES_SECONDS: number;
    CLIENT_CALLS_ENABLED: number;
    CLIENT_CALL_LIFETIME_SECONDS: number;
    IMPACT_VOTE_SECONDS: number;
    PLAYERS_DELTA_LIMIT: number;
    PLAYERS_GAMMA_LIMIT: number;
    PLAYERS_VEGA_LIMIT: number;
    PLAYERS_THETA_LIMIT: number;
    REAL_SECONDS_TO_GAME_DAYS_RATIO: number;
    AUTO_EXERCISE: number;
    PNL_DISPLAY_DIVIDER: number;
  };
  asset_settings: Array<{
    swift_id: number;
    parameters: {
      TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: number;
      PLAYERS_SPOT_MIN_TICKET: number;
      PLAYERS_SPOT_MAX_TICKET: number;
      PLAYERS_VOL_MIN_TICKET: number;
      PLAYERS_VOL_MAX_TICKET: number;
      PLAYERS_TOTALORDERS_LIMIT: number;
      PLAYERS_RFQ_PLAYERS_QUOTE_MAX_SPREAD: number;
      EB_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET: number;
      PNL_DISPLAY_DIVIDER: number;
    };
  }>;
}

export interface MarketSentimentDTO {
  swift_id: number;
  sentiment: number;
}

export type InitialDataPayload = {
  trader_id: string;
  layout: Layout[];
  room: RoomPayload;
  swifts: SwiftPayload[];
  spot_blotter: SpotDealPayload[];
  positions: SpotPositionUpdatedPayload[];
  rfq_calls: RFQCall[];
  client_calls: ClientCall[];
  options_blotter: Array<{
    swift_id: number;
    options: {
      [LONG]: OptionPayload[];
      [SHORT]: OptionPayload[];
    };
  }>;
  eb_orders: Array<{
    swift_id: number;
    orders: {
      [BID]: EbOrderPayload[];
      [ASK]: EbOrderPayload[];
    };
  }>;
  eb_depth: Array<{
    swift_id: number;
    orders: {
      [BID]: SimpleEbOrderPayload[];
      [ASK]: SimpleEbOrderPayload[];
    };
  }>;
  vol_eb_depth: Array<{
    swift_id: number;
    orders: {
      [key: number]: Delta;
    };
  }>;
  vol_eb_orders: [
    {
      swift_id: number;
      orders: VolEbOrderPayload[];
    }
  ];
  market_prices: MarketPriceDTO[];
  price_curves: PriceCurveDTO[];
  candles: { [key: string]: OHLCPoint[] };
  settings: SettingsDTO;
  calendar: unknown;
  events: MacroEventPayload[];
  morning_brief: string;
  impact_votes: {
    [key: number]: {
      [key: number]: {
        spot: number;
        vol: number;
      }
    }
  };
  market_sentiment: MarketSentimentDTO[];
  volatility_surfaces: {
    [swift_id: number]: {
      price_strikes: number[];
      percent_strikes: number[];
      volatility_curves: {
        [key: string]: { [key: string]: VolChartPointPayload[] };
      };
    };
  };
  volatility_curves: {
    [key: string]: { [key: string]: VolChartPointPayload[] };
  };

  risks: Array<{
    risks: RisksPayload;
    swift_id: number;
  }>;
  yield_curves: any;
  tenors: { [key: number]: number[] };
  visible_vol_eb_tenors: { [key: number]: number[] };
  visible_tenors: { [key: number]: number[] };
  vol_eb_strikes: { [key: number]: number[] };
  visible_vol_eb_delta_strike_values: { [key: number]: number[] };
  visible_delta_strike_values: { [key: number]: number[] };
  game_rules: { code: string; value: number }[];
};

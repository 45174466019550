import { Skeleton } from "antd";
import { Row } from "components/reusable/GenericStyledComponents";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectBestBidAndAsk } from "store/newpricer/selectors";
import { RootState } from "store/rootReducer";
import { setDeltaHedge } from "store/spot/reducers";
import { useAppDispatch, getState } from "store/store";
import styled, { css } from "styled-components";
import { convertDelta } from "utils/delta";
import { precision } from "utils/numbers";

type DeltaStrikeButtonsProps = {
  type: "Buy" | "Sell";
};

const decideIsCall = (deltaValue: number, type: string) => {
  if (deltaValue !== 0.5) {
    return deltaValue < 0.5;
  }
  if (type === "Sell") {
    return true;
  }
  return false;
};

const decideDeltaKey = (deltaValue: number, type: string) => {
  if (deltaValue !== 0.5) {
    return deltaValue;
  }
  if (type === "Sell") {
    return 0.4999;
  }
  return 0.5001;
};

const decideSelected = (
  deltaValue: number,
  type: string,
  selectedDeltaKey: number
) => {
  if (deltaValue !== 0.5) {
    return deltaValue === selectedDeltaKey;
  }
  if (type === "Sell") return selectedDeltaKey === 0.4999;
  return selectedDeltaKey === 0.5001;
};

const DeltaStrikeButtons: React.FC<DeltaStrikeButtonsProps> = ({ type }) => {
  const {
    visible_vol_eb_delta_strike_values,
    swiftId,
    initial_state_loaded,
    selectedDeltaKey,
    delta_strike_values,
    selectedDelta,
  } = useSelector(
    (state: RootState) => ({
      visible_vol_eb_delta_strike_values:
        state.spot.visible_vol_eb_delta_strike_values,
      swiftId: state.spot.swiftId,
      initial_state_loaded: state.spot.initial_state_loaded,
      selectedDeltaKey: state.spot.selectedDeltaKey,
      delta_strike_values: state.spot.delta_strike_values,
      selectedDelta: state.spot.selectedDelta,
    }),
    shallowEqual
  );
  const dispatch = useAppDispatch();

  return (
    <DeltaContainer type={type}>
      {visible_vol_eb_delta_strike_values[swiftId]
        .slice()
        .sort((a, b) => a - b)
        .filter((index: number) =>
          type === "Sell"
            ? delta_strike_values[index] < 0.5
            : delta_strike_values[index] > 0.5
        )
        .map((index: number) => {
          const deltaValue = delta_strike_values[index];
          if (deltaValue && initial_state_loaded)
            return (
              <DeltaButton
                isCall={decideIsCall(deltaValue, type)}
                key={deltaValue}
                selected={deltaValue === selectedDelta} // decideSelected(deltaValue, type, selectedDeltaKey)}
                onClick={() => {
                  dispatch(
                    setDeltaHedge({
                      selectedDelta: decideDeltaKey(deltaValue, type),
                      TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: 0,
                    })
                  );
                }}
              >
                {convertDelta[deltaValue]}
              </DeltaButton>
            );
          return <Skeleton />;
        })}
    </DeltaContainer>
  );
};

const OptionStrikeButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const { visible_vol_eb_delta_strike_values, swiftId, selectedDelta } =
    useSelector(
      (state: RootState) => ({
        visible_vol_eb_delta_strike_values:
          state.spot.visible_vol_eb_delta_strike_values,
        swiftId: state.spot.swiftId,
        selectedDelta: state.spot.selectedDelta,
      }),
      shallowEqual
    );
  return (
    <>
      <DeltaContainer type="Sell">
        <Title type="Sell">PUTS</Title>
        <DeltaRow>
          <DeltaStrikeButtons type="Sell" />
        </DeltaRow>
      </DeltaContainer>
      {visible_vol_eb_delta_strike_values[swiftId]
        .slice()
        .sort((a, b) => a - b)
        .includes(2) && (
        <Row
          style={{
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StraddleDeltaButton
            selected={selectedDelta === 0.5}
            onClick={() => {
              dispatch(
                setDeltaHedge({
                  selectedDelta: 0.5,
                  TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: 0,
                })
              );
            }}
          >
            ATM
          </StraddleDeltaButton>
        </Row>
      )}
      <DeltaContainer type="Buy">
        <DeltaRow>
          <DeltaStrikeButtons type="Buy" />
        </DeltaRow>
        <Title type="Buy">CALLS</Title>
      </DeltaContainer>
    </>
  );
};

const Title = styled.div<{ type: "Sell" | "Buy" }>`
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  cursor: default;

  ${(props) => {
    if (props.type === "Buy") {
      return css`
        text-align: right;
      `;
    }
  }}
`;

const DeltaRow = styled.div`
  display: flex;
  padding: 0;
  direction: ltr;
`;

const StraddleDeltaButton = styled.div<{ selected: boolean }>`
  padding: 1px 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;

  ${(props) => {
    if (props.selected) {
      return css`
        background: #15222d;
        border-color: #4d96c1;
      `;
    }
  }}

  &:hover {
    background: rgba(21, 34, 45, 0.5);
  }
`;

const DeltaButton = styled.div<{ selected: boolean; isCall: boolean }>`
  padding: 1px 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  ${(props) => {
    if (props.selected) {
      return css`
        border-color: #4d96c1;
        background: ${props.isCall ? "#c0354890" : "#007e4d90"};
      `;
    }
    return css`
      background: ${props.isCall ? "#c0354830" : "#007e4d30"};
    `;
  }}

  &:hover {
    ${(props) => {
      if (props.selected) {
        return css`
          background: ${props.isCall ? "#c03548" : "#007e4d"};
        `;
      }
      return css`
        background: ${props.isCall ? "#c0354850" : "#007e4d50"};
      `;
    }}
  }
`;

const DeltaContainer = styled.div<{ type: "Buy" | "Sell" }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0.25rem;
  align-items: center;
  padding: 10px 6px;
`;

export default OptionStrikeButtons;

import { shallowEqual, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "store/rootReducer";
import {
  browserVisiblityChange,
  socketConnect,
  socketDisconnect,
} from "store/socket/actions";
import { useAppDispatch } from "store/store";
import settings from "settings";

export const useConnectToWS = (id: string) => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, token } = useSelector(
    (state: RootState) => state.user
  );
  const connected = useSelector(
    (state: RootState) => state.socket.connected,
    shallowEqual
  );

  useEffect(() => {
    if (isAuthenticated && !connected) {
      dispatch(
        socketConnect(
          `${settings.REACT_APP_WS_URL}/sock/?token=${token}&room_id=${id}`
        )
      );
    }

    return () => {
      dispatch(socketDisconnect());
    };
  }, [isAuthenticated]);

  useEffect(() => {
    const handleVisibility = () => {
      dispatch(
        browserVisiblityChange({
          isVisible: !document.hidden,
          socketUrl: `${settings.REACT_APP_WS_URL}/sock/?token=${token}&room_id=${id}`,
        })
      );
    };

    document.addEventListener("visibilitychange", handleVisibility);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibility);
  }, [dispatch]);
};

import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";
import styled from "styled-components";
import StyledText from "components/reusable/StyledText";
import { CaretUpOutlined } from "@ant-design/icons";

type ListEditProps = {
  field: any[];
  title: string;
  label: string;
  Component: React.FC<{
    data: any;
    dataList: any[];
    setDataList: (val: any) => void;
    idx: number;
  }>;
  AddNewComponent?: React.FC<{
    dataList: any[];
    setDataList: (val: any) => void;
    props?: any;
  }>;
  Description?: React.FC;
  AddNewComponentProps?: any;
  setField: (val: any) => void;
  unique_key: string;
  sorted?: boolean;
  sortKey?: string;
};

const ScenarioListEdit: React.FC<ListEditProps> = ({
  field,
  title,
  label,
  sorted,
  sortKey,
  Component,
  setField,
  unique_key,
  AddNewComponent,
  AddNewComponentProps,
  Description,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Column
      style={{
        gap: "2rem",
        width: "80%",
        marginLeft: "20%",
        marginRight: "20%",
        alignItems: "center",
      }}
    >
      <Line />
      <ListTitle
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <h1>{title}</h1>
        <div>
          <CaretUpOutlined
            style={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all ease 300ms",
            }}
          />
        </div>
        <StyledText fontSize={16} fontWeight={16}>
          {field.length} items
        </StyledText>
      </ListTitle>
      {Description && expanded && <Description />}
      {AddNewComponent && expanded && (
        <Row style={{ alignItems: "center" }}>
          <AddNewComponent
            dataList={field}
            setDataList={setField}
            props={AddNewComponentProps}
          />
        </Row>
      )}
      {expanded && (
        <div style={{ alignItems: "center" }}>
          {React.Children.toArray(
            sorted
              ? [...field]
                  .sort((a: any, b: any) => {
                    return a[`${sortKey}`] - b[`${sortKey}`];
                  })
                  .map((item, idx) => {
                    return (
                      <Column>
                        <Component
                          data={item}
                          dataList={field}
                          setDataList={setField}
                          idx={idx}
                        />
                        {idx !== field.length - 1 && <Line />}
                      </Column>
                    );
                  })
              : [...field]
                  .sort((a: any, b: any) => {
                    return a[`${sortKey}`] - b[`${sortKey}`];
                  })
                  .map((item, idx) => {
                    return (
                      <Column>
                        <Component
                          data={item}
                          dataList={field}
                          setDataList={setField}
                          idx={idx}
                        />
                        {idx !== field.length - 1 && <Line />}
                      </Column>
                    );
                  })
          )}
        </div>
      )}
    </Column>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

const ListTitle = styled(Row)`
  z-index: 100;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  position: sticky;
  top: 0;
`;

export default ScenarioListEdit;

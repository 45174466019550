/* eslint-disable no-nested-ternary */
import React from "react";

import styled from "styled-components";

import { shallowEqual, useSelector } from "react-redux";
import {
  PUT_CALL_STR,
  RFQ_STATUS_STR,
  RFQ_WAITING,
  RFQ_ANSWERED,
  RFQ_HIT_ASK,
  RFQ_HIT_BID,
  VOLATILITY_QUOTE,
} from "stateConstants";
import { RootState } from "store/rootReducer";
import { numberWithCommas, percent2Digits, precision } from "utils/numbers";
import { MATURITY, RFQCall, StrategyType, Tenor } from "types";
import OutgoingActions from "./OutgoingActions";
import { timeBEtoPretty } from "utils/time";
import {
  generateClientCallHeader,
  generateRfqHeader,
} from "../ClientCalls/utils";

type Props = {
  rfq: RFQCall;
};

const GetStatus = (call: RFQCall) => {
  const { traderId } = useSelector((state: RootState) => state.user);

  if (call.market_taker_id === traderId && call.status === RFQ_HIT_ASK) {
    return "You bought";
  }
  if (call.market_taker_id === traderId && call.status === RFQ_HIT_BID) {
    return "You sold";
  }

  return RFQ_STATUS_STR[call.status];
};

const OutgoingRFQ: React.FC<Props> = ({ rfq }) => {
  const isWaiting = rfq.status === RFQ_WAITING;
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );

  return (
    <Container>
      <Header>
        <Details>
          <GreySpan>{rfq.market_maker_name}</GreySpan>
          <GreySpan>{GetStatus(rfq)}</GreySpan>
        </Details>
        <Details>
          <YellowSpan>{generateRfqHeader(rfq, sticky_model)}</YellowSpan>

          {!isWaiting &&
            (rfq.quote_type === VOLATILITY_QUOTE ? (
              rfq.strategy.strategy_name === StrategyType.INDIVIDUAL_OPTION ? (
                <GreySpan>
                  Bid:{" "}
                  <YellowSpan>
                    {percent2Digits(rfq.strategy_volatility_bid)}
                  </YellowSpan>{" "}
                  / Ask:{" "}
                  <YellowSpan>
                    {percent2Digits(rfq.strategy_volatility_ask)}
                  </YellowSpan>
                </GreySpan>
              ) : (
                <GreySpan>
                  Bid:{" "}
                  <YellowSpan>
                    {numberWithCommas(precision(rfq.strategy_price_bid, 2))}$
                  </YellowSpan>{" "}
                  / Ask:{" "}
                  <YellowSpan>
                    {numberWithCommas(precision(rfq.strategy_price_ask, 2))}$
                  </YellowSpan>
                </GreySpan>
              )
            ) : (
              <GreySpan>
                Bid:{" "}
                <YellowSpan>
                  {numberWithCommas(precision(rfq.strategy_price_bid, 2))}$
                </YellowSpan>{" "}
                / Ask:{" "}
                <YellowSpan>
                  {numberWithCommas(precision(rfq.strategy_price_ask, 2))}$
                </YellowSpan>
              </GreySpan>
            ))}
        </Details>
      </Header>

      {!isWaiting && <OutgoingActions rfq={rfq} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const YellowSpan = styled.span`
  color: ${(p) => p.theme.primaryDark1};
`;

const GreySpan = styled.span`
  color: #8c8c8c;
`;

export default OutgoingRFQ;

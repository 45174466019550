type SNU = string | number | undefined;

const nr = (val: SNU) => {
  if (typeof val === "number") return val;
  if (typeof val === "string") {
    const parsed = parseFloat(val);

    if (Number.isNaN(parsed)) return 0;

    return parsed;
  }
  return 0;
};

export const number4Digits = (n: SNU) => Number(nr(n).toFixed(4));

export const getClosestValue = (
  value: number,
  array: number[] | string[]
): number | null => {
  if (array.length === 0) {
    return null;
  }

  if (typeof array[0] === "string") {
    array = (array as string[]).map((val: string): number =>
      parseFloat(val)
    ) as number[];
  }

  return (array as number[]).reduce((prev: number, curr: number) =>
    Math.abs(curr - value) < Math.abs(prev - curr) ? curr : prev
  );
};

export const numberWithCommas = (x: SNU, digits = 2) =>
  nr(x).toLocaleString("en", {
    maximumFractionDigits: digits,
  });

export const dateFromSeconds = (seconds: number) => {
  const zeroPrefix = (n: number) => `${n < 10 ? "0" : ""}${n}`;

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainder = seconds - hours * 3600 - minutes * 60;
  return `${zeroPrefix(hours)}:${zeroPrefix(minutes)}:${zeroPrefix(remainder)}`;
};

export const percent2Digits = (x: SNU) => {
  return (
    (nr(x) * 100).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + "%"
  );
};

export const precision = (x: SNU, decimalPlaces: number) => {
  const n = nr(x);
  if (n === 0) return 0;
  const intDigits = Math.floor(Math.log10(n)) + 1;
  return Number(n.toPrecision(intDigits + decimalPlaces));
};

export const div10 = (x: SNU, exp: number) => {
  const stringNumber = nr(x).toString();
  const decimalPlaces = stringNumber.split(".")[1]?.length || 0;
  return Math.round(nr(x) * 10 ** decimalPlaces) / 10 ** (decimalPlaces + exp);
};

export const randint: (min: number, max: number) => number = (
  min: number,
  max: number
) => {
  return Math.random() * (max - min) + min;
};

import { put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { authService } from "services";
import { AUTH_AUTHENTICATE, AUTH_REFRESH, AUTH_REGISTER } from "./constants";
import { userSuccessAuth } from "../user/reducers";

import type { LoginDTO, LoginResponse, RegisterDTO } from "types";
import { userFailAuth, userFailRegister } from "./reducers";
import { message } from "antd";

function* authenticate(action: PayloadAction<LoginDTO>) {
  try {
    const response: LoginResponse = yield authService.login(action.payload);

    yield put(
      userSuccessAuth({
        name: action.payload.username,
        access: response.access,
        refresh: response.refresh,
      })
    );
  } catch (err: any) {
    const errorData = err.response ? err.response.data : null;
    console.log(err, errorData);

    yield put(
      userFailAuth({
        error: errorData.detail || "The username or password is invalid",
      })
    );
  }
}

function* refreshToken(action: PayloadAction<string>) {
  try {
    const response: LoginResponse = yield authService.refreshToken(action.payload);
    console.log({ response });

    yield put(
      userSuccessAuth({
        name: "",
        access: response.access,
        refresh: response.refresh,
      })
    );
  } catch (err: any) {
    const errorData = err.response ? err.response.data : null;
    console.log(err, errorData);

    yield put(
      userFailAuth({
        error: errorData.detail || "The username or password is invalid",
      })
    );
  }
}

function* register(action: PayloadAction<RegisterDTO>) {
  try {
    yield authService.register(action.payload);
    message.info("Registered successfully!");
  } catch (err: any) {
    const errorData = err.response ? err.response.data : null;

    yield put(
      userFailRegister({
        error: errorData.error || "Registration error",
      })
    );
  }
}

export function* watchAuthAsync() {
  yield takeEvery(AUTH_AUTHENTICATE, authenticate);
  yield takeEvery(AUTH_REGISTER, register);
  yield takeEvery(AUTH_REFRESH, refreshToken);
}

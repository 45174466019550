import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import AddNewsModal from "components/Modal/AddNewsModal";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import { Column } from "components/reusable/GenericStyledComponents";
import { Space, Table, Button } from "antd";
import AddNewsExpanded from "./utils/AddNewsExpanded";
import { secondsToTimestampPretty } from "utils/time";
import styled from "styled-components";

const tableColors: { [key: number]: string } = {
  [-2]: "red",
  [-1]: "darkred",
  0: "transparent",
  1: "darkgreen",
  2: "green",
};

const CBMacro: React.FC = () => {
  const events = useSelector((state: RootState) => state.centralbank.events);
  const [newsRows, setNews] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  useEffect(() => {
    if (events && events[0] && events[0].market_impact) {
      const swift = Object.keys(events[0].market_impact)[0];
      setNews(
        events.map((nnn: any) => {
          return {
            id: nnn.id.toString(),
            headline: nnn.event_data.headline,
            body: nnn.event_data.body,
            delta_seconds: nnn.room_time,
            ...nnn.market_impact[swift],
            published: nnn.published,
            passed: nnn.passed,
          };
        })
      );
    }
  }, [events]);

  return events ? (
    <Container>
      {events && newsRows ? (
        <Table
          rowKey="id"
          bordered
          sticky
          scroll={{ x: "max-content" }}
          dataSource={newsRows}
          pagination={false}
          expandIcon={undefined}
          expandable={{
            expandedRowKeys: expandedRows,
            expandedRowRender: (record, index) => (
              <AddNewsExpanded
                prevNews={newsRows[index - 1] || null}
                nextNews={newsRows[index + 1] || null}
                currentNews={record}
                expandedRows={expandedRows}
                setExpandedRows={setExpandedRows}
              />
            ),
            showExpandColumn: false,
          }}
          columns={[
            {
              dataIndex: "headline",
              title: "Headline",
              width: "10rem",
            },
            {
              dataIndex: "body",
              title: "Body",
              width: "20rem",
              render: (_, record) => (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {record.body}
                </div>
              ),
            },
            {
              dataIndex: "delta_seconds",
              title: "Time",
              render: (_, record) => (
                <div>{secondsToTimestampPretty(record.delta_seconds)}</div>
              ),
            },
            {
              dataIndex: "spot",
              title: "Spot impact",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.spot as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.spot}
                    </div>
                  ),
                };
              },
            },
            {
              dataIndex: "vol_parallel",
              title: "Parallel shift",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.vol_parallel as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.vol_parallel}
                    </div>
                  ),
                };
              },
            },
            {
              dataIndex: "put_skew",
              title: "Put skew shift",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.put_skew as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.put_skew}
                    </div>
                  ),
                };
              },
            },
            {
              dataIndex: "call_skew",
              title: "Call skew shift",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.call_skew as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.call_skew}
                    </div>
                  ),
                };
              },
            },
            {
              dataIndex: "lt_steep",
              title: "Short term steepness",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.lt_steep as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.lt_steep}
                    </div>
                  ),
                };
              },
            },
            {
              dataIndex: "st_steep",
              title: "Long term steepness",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.st_steep as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.st_steep}
                    </div>
                  ),
                };
              },
            },
            {
              dataIndex: "id",
              title: "Actions",
              width: "15ch",
              render: (_: any, record: any) => {
                if (record.passed)
                  return (
                    <Space style={{ color: "lightgray" }}>
                      Event passed, no action available
                    </Space>
                  );
                return (
                  <Space>
                    <Button
                      onClick={() => {
                        setExpandedRows([...expandedRows, record.id]);
                      }}
                    >
                      Add bellow
                    </Button>
                  </Space>
                );
              },
            },
          ]}
        />
      ) : (
        <LoadingSpinner message="Fetching news..." />
      )}
      {/* <Column style={{ justifyContent: "center", alignItems: "center" }}>
        <Button onClick={() => setShowModal(true)}>Add news</Button>
      </Column> */}
      <AddNewsModal shown={showModal} setShowModal={setShowModal} />
    </Container>
  ) : (
    <LoadingSpinner message="Fetching events..." />
  );
};

const Container = styled.div``;

export default CBMacro;

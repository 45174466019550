import React, { useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Button, Input, message } from "antd";

import { RootState } from "store/rootReducer";
import { addNews } from "store/events/actions";
import { useAppDispatch } from "store/store";
import GenericModal from "./GenericModal";
import FloatLabel from "components/reusable/FloatLabel";
import { FloatInputNumber } from "components/reusable/GenericStyledComponents";

const { TextArea } = Input;

const AddNewsModal = ({ shown, setShowModal }: any) => {
  const dispatch = useAppDispatch();
  const [newsForm, setNewsForm] = useState({
    room_time: 0,
    body: "",
    headline: "",
    deltaseconds: 0,
    market_impact: {
      call_skew: 0,
      lt_steep: 0,
      put_skew: 0,
      st_steep: 0,
      vol_parallel: 0,
      spot: 0,
    },
  });
  const { id } = useParams<{ id: string }>();
  const swift_ = useSelector((state: RootState) => state.swifts.selectedSwift);
  return (
    <GenericModal
      isShown={shown}
      customClassName="ScenarioModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      overlayBackground="#00000060"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <FloatLabel label="Headline" value={newsForm.headline}>
          <TextArea
            onChange={(e) => {
              setNewsForm({ ...newsForm, headline: e.target.value });
            }}
          />
        </FloatLabel>
        <FloatLabel label="Body" value={newsForm.body}>
          <TextArea
            onChange={(e) => {
              setNewsForm({ ...newsForm, body: e.target.value });
            }}
          />
        </FloatLabel>
        <FloatLabel label="Seconds" value={newsForm.deltaseconds}>
          <FloatInputNumber
            type="number"
            onChange={(value) => {
              setNewsForm({
                ...newsForm,
                deltaseconds: (value as number) || 0,
              });
            }}
            value={newsForm.deltaseconds}
            min={0}
          />
        </FloatLabel>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <FloatLabel label="Spot" value={newsForm.market_impact.spot}>
            <FloatInputNumber
              onChange={(value) => {
                setNewsForm({
                  ...newsForm,
                  market_impact: {
                    ...newsForm.market_impact,
                    spot: (value as number) || 0,
                  },
                });
              }}
              value={newsForm.market_impact.spot}
            />
          </FloatLabel>
          <FloatLabel
            label="Lt. steepness"
            value={newsForm.market_impact.lt_steep}
          >
            <FloatInputNumber
              onChange={(value) => {
                setNewsForm({
                  ...newsForm,
                  market_impact: {
                    ...newsForm.market_impact,
                    lt_steep: (value as number) || 0,
                  },
                });
              }}
              value={newsForm.market_impact.lt_steep}
            />
          </FloatLabel>
          <FloatLabel label="Put skew" value={newsForm.market_impact.put_skew}>
            <FloatInputNumber
              onChange={(value) => {
                setNewsForm({
                  ...newsForm,
                  market_impact: {
                    ...newsForm.market_impact,
                    put_skew: (value as number) || 0,
                  },
                });
              }}
              value={newsForm.market_impact.put_skew}
            />
          </FloatLabel>

          <FloatLabel
            label="St. steepness"
            value={newsForm.market_impact.st_steep}
          >
            <FloatInputNumber
              onChange={(value) => {
                setNewsForm({
                  ...newsForm,
                  market_impact: {
                    ...newsForm.market_impact,
                    st_steep: (value as number) || 0,
                  },
                });
              }}
              value={newsForm.market_impact.st_steep}
            />
          </FloatLabel>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <FloatLabel
            label="Call skew"
            value={newsForm.market_impact.call_skew}
          >
            <FloatInputNumber
              onChange={(value) => {
                setNewsForm({
                  ...newsForm,
                  market_impact: {
                    ...newsForm.market_impact,
                    call_skew: (value as number) || 0,
                  },
                });
              }}
              value={newsForm.market_impact.call_skew}
            />
          </FloatLabel>
          <FloatLabel
            label="Parallel shift"
            value={newsForm.market_impact.vol_parallel}
          >
            <FloatInputNumber
              onChange={(value) => {
                setNewsForm({
                  ...newsForm,
                  market_impact: {
                    ...newsForm.market_impact,
                    vol_parallel: (value as number) || 0,
                  },
                });
              }}
              value={newsForm.market_impact.vol_parallel}
            />
          </FloatLabel>
        </div>
      </div>
      <Button
        style={{ marginTop: "1rem" }}
        onClick={(e) => {
          e.preventDefault();
          dispatch(
            addNews({ room_id: id, swift: swift_, news: { ...newsForm } })
          );
          message.success(
            `News with headline ${newsForm.headline} added at time ${newsForm.deltaseconds}`
          );
          setShowModal(false);
        }}
      >
        Add news
      </Button>
      <Button
        style={{ marginTop: ".5rem" }}
        onClick={() => {
          setShowModal(false);
        }}
      >
        Close
      </Button>
    </GenericModal>
  );
};

export default AddNewsModal;

import React, { useEffect, useState } from "react";

import "chartjs-adapter-date-fns";
import "chartjs-plugin-zoom";
import "hammerjs";
import {
  OhlcElement,
  OhlcController,
  CandlestickElement,
  CandlestickController,
} from "chartjs-chart-financial";
import { Chart as ChartJS } from "chart.js";
import { Chart, Line } from "react-chartjs-2";
import type { ChartData } from "chart.js";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { OHLCPoint, SpotChartPoint } from "types";
import { Select, SelectProps } from "antd";
import styled from "styled-components";
import { calculateOHLCInterval, candleStickOptions } from "./utils";

ChartJS.register(
  OhlcElement,
  OhlcController,
  CandlestickElement,
  CandlestickController
);

const intervals = {
  "1D": 86400, // 1 Day
  "1W": 604800, // 1 Week
  "1M": 2592000, // 1 Month (approx.)
  "3M": 7776000, // 3 Months (approx.)
  "6M": 15552000, // 6 Months (approx.)
  "1Y": 31536000, // 1 Year (approx.)
};

const CandlestickSpotChart: React.FC = () => {
  const { spotChartValues } = useSelector((state: RootState) => state.spot);
  // const interval = 100;
  // const ohlcData = calculateOHLCInterval(spotChartValues, 15);
  // console.log(ohlcData);
  console.log({ spotChartValues });

  const [interval, setTimeInterval] = useState<string>("1D");
  const [ohlcData, setOhlcData] = useState<OHLCPoint[]>([]);
  // console.log({ spotChartOHLC }, spotChartOHLC[interval]);

  const handleIntervalChange = (e: any) => {
    setTimeInterval(e);
  };

  useEffect(() => {
    setOhlcData(
      calculateOHLCInterval(
        spotChartValues,
        intervals[interval as keyof typeof intervals]
      )
    );
    console.log(ohlcData);
  }, [spotChartValues, interval]);
  const data: ChartData<
    "candlestick",
    { x: number; o: number; h: number; l: number; c: number }[]
  > = {
    datasets: [
      {
        label: "Candlestick",
        data: ohlcData,

        type: "candlestick",
      } as any,
    ],
  };
  // let minTime: number | null = null;
  // let maxTime: number | null = null;

  // if (ohlcData.length >= 5) {
  //   minTime = ohlcData[ohlcData.length - 5].x;
  //   maxTime = ohlcData[ohlcData.length - 1].x;
  // }

  const options = {
    scales: {
      x: {
        // borderColor: {
        //   up: "#007e4d",
        //   down: "#c03548",
        //   unchanged: "black",
        // },
        // color: {
        //   up: "#007e4d", // Color of rising body
        //   down: "#c03548", // Color of falling body
        //   unchanged: "black",

        //   // borderColor: "black", // Color of border around body
        //   // borderUpColor: "green", // Color of rising body's border
        //   // borderDownColor: "red", // Color of falling body's border
        // },
        type: "time",
        // min: minTime,
        // max: maxTime,
        time: {
          // unit: "day",
          // displayFormats: {
          //   day: "MMM D",
          // },
          // tooltipFormat: "ll",
        },
        // title: {
        //   display: true,
        //   text: "Date",
        // },
      },
      y: {
        type: "linear",
        // title: {
        //   display: true,
        //   text: "Value",
        // },
      },
    },
    plugins: {
      legend: { display: false },
      //   zoom: {
      //     pan: {
      //       enabled: true,
      //       mode: "x",
      //       speed: 10,
      //       threshold: 10,
      //     },
      //     zoom: {
      //       wheel: {
      //         enabled: true,
      //       },
      //       pinch: {
      //         enabled: true,
      //       },
      //       mode: "x",
      //     },
      //   },
      // },
      // interaction: {
      //   intersect: false,
      //   mode: "nearest",
    },
  } as const;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Dropdown for Interval Selection */}
      <StyledSelect
        dropdownMatchSelectWidth={false}
        options={candleStickOptions}
        onChange={handleIntervalChange}
        defaultValue="1D"
      />

      {/* Candlestick Chart */}
      <Chart type="candlestick" data={data} options={options as any} />
    </div>
  );
};

export default CandlestickSpotChart;

const StyledSelect = styled((props: SelectProps<any>) => <Select {...props} />)`
  .ant-select-selector {
    border-radius: 8px;
    color: white;
    // width: fit-content;
    height: 20px;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .ant-select-selection-item {
    font-size: 10px;
    line-height: 20px; // Added line
    padding: 0 0.25rem;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #ff6f61;
    box-shadow: 0 0 0 2px rgba(255, 111, 97, 0.2);
  }
`;

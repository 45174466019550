import { SpotChartPoint, OHLCPoint } from "types";

export const candleStickOptions = [
  { value: "1D", label: "1 Day" },
  { value: "1W", label: "1 Week" },
  { value: "1M", label: "1 Month" },
  { value: "3M", label: "3 Months" },
  { value: "6M", label: "6 Months" },
  { value: "1Y", label: "1 Year" },
];

export const calculateOHLCInterval = (
  spotChartValues: SpotChartPoint[],
  interval: number
) => {
  const ohlcData: OHLCPoint[] = [];
  let currentIntervalStart = spotChartValues[0].room_date;
  let open = spotChartValues[0].price;
  let high = open;
  let low = open;
  let close = open;

  spotChartValues.forEach((point, index) => {
    // Check if the current point is still within the current interval
    if (point.room_date < currentIntervalStart + interval * 1000) {
      // Update high and low
      high = Math.max(high, point.price);
      low = Math.min(low, point.price);
      close = point.price; // Update close to the latest price
    } else {
      // Interval has ended, push the OHLC data for the interval
      ohlcData.push({
        x: currentIntervalStart,
        o: open,
        h: high,
        l: low,
        c: close,
      });

      // Start a new interval
      currentIntervalStart = point.room_date; // or currentIntervalStart + interval
      open = point.price;
      high = point.price;
      low = point.price;
      close = point.price;
    }
  });

  // Push the last interval if it wasn't already added
  if (
    !ohlcData.length ||
    ohlcData[ohlcData.length - 1].x !== currentIntervalStart
  ) {
    ohlcData.push({
      x: currentIntervalStart,
      o: open,
      h: high,
      l: low,
      c: close,
    });
  }

  return ohlcData.splice(-15);
};

export const calculateOHLCCandles = (
  spotChartValues: SpotChartPoint[],
  numCandles: number
) => {
  if (spotChartValues.length < 2) {
    // Handle this case as appropriate for your use case
    throw new Error("Not enough data points to calculate OHLC");
  }

  // // Sort the values by time, just to be sure
  // spotChartValues.sort((a, b) => a.room_date - b.room_date);

  const totalTimeSpan =
    spotChartValues[spotChartValues.length - 1].room_date -
    spotChartValues[0].room_date;
  const interval = totalTimeSpan / numCandles;

  const ohlcData = [];
  let currentIntervalStart = spotChartValues[0].room_date;
  let open = spotChartValues[0].price;
  let high = open;
  let low = open;
  let close = open;

  spotChartValues.forEach((point) => {
    if (point.room_date >= currentIntervalStart + interval) {
      // Push the previous interval and start a new one
      ohlcData.push({
        x: currentIntervalStart,
        o: open,
        h: high,
        l: low,
        c: close,
      });
      currentIntervalStart = point.room_date;
      open = point.price;
      high = open;
      low = open;
    }
    high = Math.max(high, point.price);
    low = Math.min(low, point.price);
    close = point.price;
  });

  // Push the last interval
  ohlcData.push({
    x: currentIntervalStart,
    o: open,
    h: high,
    l: low,
    c: close,
  });

  return ohlcData;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  TraderState,
  InitialDataPayload,
  SpotPositionUpdatedPayload,
  RisksUpdatedPayload,
} from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";
import { spotPositionPnlUpdated, spotPositionUpdated } from "../spot/actions";
import { risksUpdated } from "./actions";

const initialState: () => TraderState = () => ({
  unrealisedPNL: 0,
  realisedPNL: 0,
  optRealisedPNL: 0,
  optUnrealisedPNL: 0,
  net_premium: 0,
  spotRealisedPNL: 0,
  spotUnrealisedPNL: 0,
  trader: 0,
  positions: 0,
  utilization: 0,

  delta: 0,
  vega: 0,
  gamma: 0,
  theta: 0,
});

const traderSlice = createSlice({
  initialState,
  name: "trader",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        retrieveInitialData,
        (state, action: PayloadAction<InitialDataPayload>) => {
          const {
            positions: [swiftPosition],
            risks,
          } = action.payload;

          // spot
          state.spotRealisedPNL = parseFloat(
            swiftPosition.position.spot_realized_pnl
          );
          state.spotUnrealisedPNL = parseFloat(
            swiftPosition.position.spot_unrealized_pnl
          );
          state.positions = parseFloat(swiftPosition.position.amount);

          // option
          const {
            delta,
            gamma,
            vega,
            theta,
            risks_realized_pnl,
            risks_unrealized_pnl,
            net_premium,
          } = risks[0].risks;
          state.delta = parseFloat(delta);
          state.gamma = parseFloat(gamma);
          state.vega = parseFloat(vega);
          state.theta = parseFloat(theta);
          state.optRealisedPNL = parseFloat(risks_realized_pnl);
          state.optUnrealisedPNL = parseFloat(risks_unrealized_pnl);
          state.net_premium = parseFloat(net_premium);
        }
      )
      .addCase(
        spotPositionPnlUpdated,
        (state, action: PayloadAction<SpotPositionUpdatedPayload>) => {
          const { position } = action.payload;
          state.spotRealisedPNL = parseFloat(position.spot_realized_pnl);
          state.spotUnrealisedPNL = parseFloat(position.spot_unrealized_pnl);
          state.positions = parseFloat(position.amount);
        }
      )
      .addCase(
        spotPositionUpdated,
        (state, action: PayloadAction<SpotPositionUpdatedPayload>) => {
          const { position } = action.payload;
          state.spotRealisedPNL = parseFloat(position.spot_realized_pnl);
          state.spotUnrealisedPNL = parseFloat(position.spot_unrealized_pnl);
          state.positions = parseFloat(position.amount);
        }
      )
      .addCase(
        risksUpdated,
        (state, action: PayloadAction<RisksUpdatedPayload>) => {
          const {
            risks: {
              delta,
              gamma,
              vega,
              theta,
              risks_realized_pnl,
              risks_unrealized_pnl,
              net_premium,
            },
          } = action.payload;
          state.delta = parseFloat(delta);
          state.gamma = parseFloat(gamma);
          state.vega = parseFloat(vega);
          state.theta = parseFloat(theta);
          state.optRealisedPNL = parseFloat(risks_realized_pnl);
          state.optUnrealisedPNL = parseFloat(risks_unrealized_pnl);
          state.net_premium = parseFloat(net_premium);
        }
      )
      .addCase(clearRoom, initialState);
  },
});

export default traderSlice.reducer;

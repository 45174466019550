import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "store/rootReducer";
import NewVolChart from "./NewVolChart";
import Chart from "../../reusable/Chart";
import { ScatterDataPoint } from "chart.js";
import styled from "styled-components";
import CandlestickSpotChart from "./CandlestickSpotChart";
import StackedVolChart from "./StackedVolChart";

const ChartsSection = () => {
  const { spotChartValues } = useSelector((state: RootState) => state.spot);
  const { tradableVols, delta_strike_values, initial_state_loaded } =
    useSelector(
      (state: RootState) => ({
        tradableVols: state.spot.tradableVols,
        delta_strike_values: state.spot.delta_strike_values,
        initial_state_loaded: state.spot.initial_state_loaded,
      }),
      shallowEqual
    );
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);

  const [tenorChoices, setTenorChoices] = useState(
    Object.keys(tradableVols).map(Number)
  );

  useEffect(() => {
    setTenorChoices(Object.keys(tradableVols).map(Number));
  }, [tradableVols]);

  const showVolChart =
    initial_state_loaded &&
    delta_strike_values.length &&
    tenorChoices.length &&
    swift?.volatility_decimal_places;

  return (
    <Container>
      <Col>
        {/* <Chart
          title="Spot Chart"
          graphColor="#22B793"
          chartValues={_.takeRight(
            [
              ...spotChartValues
                .map(
                  (point): ScatterDataPoint => ({
                    x: point.room_time,
                    y: point.price,
                  })
                )
                .sort((a, b) => a.x - b.x),
            ],
            30
          )}
        /> */}
        <CandlestickSpotChart />
      </Col>

      <Col>
        {showVolChart && (
          <NewVolChart
            delta_strike_values={delta_strike_values}
            tenorChoices={tenorChoices}
            decimals={swift.volatility_decimal_places}
          />
          // <StackedVolChart/>
        )}
      </Col>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Col = styled.div`
  width: 50%;
  position: relative;
`;

export default ChartsSection;

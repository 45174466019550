import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { AnimatedLink, Column } from "../reusable/GenericStyledComponents";
import TrainerNavItem from "../reusable/TrainerNavItem";
import Scenarios from "assets/NavIcons/scenarios.svg";
import StyledText from "components/reusable/StyledText";
import { RollbackOutlined } from "@ant-design/icons";

interface ITrainerNavProps {
  activeNav: number;
  changeNav: (idx: number) => void;
}
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;
const AdminNav: React.FC<ITrainerNavProps> = ({ changeNav, activeNav }) => (
  <Container>
    <Column style={{ alignItems: "center" }}>
      <AnimatedLink to="/">
        <Logo src="/logo2.png" alt="logo" />
      </AnimatedLink>
      <Line />
      <AnimatedLink style={{ color: "inherit" }} to="/">
        <RollbackOutlined
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        />
      </AnimatedLink>
      <Line />
      <StyledText
        style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
        fontSize={28}
        fontWeight={400}
      >
        Admin Panel
      </StyledText>

      <Line />
    </Column>
    <TrainerNavItem
      icon={null}
      title="Scenarios"
      active={activeNav === 0}
      changeNav={() => changeNav(0)}
    />
    <TrainerNavItem
      icon={null}
      title="Customers"
      active={activeNav === 1}
      changeNav={() => changeNav(1)}
    />
  </Container>
);

const Container = styled(Column)`
  height: 100vh;
  width: 226px;
  background-color: #232c31;
`;

const Logo = styled.img`
  padding: 20px 30px;
`;

export default AdminNav;

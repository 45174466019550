import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { Line } from "react-chartjs-2";
import _ from "lodash";
import { RootState } from "store/rootReducer";
import { MATURITY, Tenor, VolatilityCurves } from "types";
import StyledText from "../../reusable/StyledText";
import { Column, Row } from "../../reusable/GenericStyledComponents";
import CustomSelect from "../../reusable/CustomSelect";
import { timeUnixToDatePretty } from "utils/time";
import { Select, SelectProps } from "antd";
import styled from "styled-components";

type Props = {
  tenorChoices: number[];
  delta_strike_values: number[];
  decimals: number;
};

const generateKey = (
  firstTenor: any,
  selectedDeltaStrike: any,
  decimals: number
) => {
  return `${firstTenor} ${parseDeltaStrike(selectedDeltaStrike, decimals)}`;
};
const parseDeltaStrike = (p: any, decimals: number) => parseFloat(p); // .toFixed(decimals);

const NewVolChart: React.FC<Props> = ({
  tenorChoices,
  delta_strike_values,
  decimals,
}) => {
  const { volatility_curves } = useSelector(
    (state: RootState) => state.volatility
  );
  const { initial_state_loaded, sticky_model, selectedDelta } = useSelector(
    (state: RootState) => state.spot,
    shallowEqual
  );

  const [firstTenor, setFirstTenor] = useState(tenorChoices[0]);
  const [secondTenor, setSecondTenor] = useState(tenorChoices[1]);
  const [selectedDeltaStrike, setSelectedDeltaStrike] = useState(selectedDelta);

  const [firstGraph, setFirstGraph] = useState<{ x: number; y: number }[]>([]);
  const [secondGraph, setSecondGraph] = useState<{ x: number; y: number }[]>(
    []
  );
  const [isHovered, setIsHovered] = useState(false);
  const [xMin, setXMin] = useState(0);
  const [xMax, setXMax] = useState(0);
  const [yMin, setYMin] = useState(0);
  const [yMax, setYMax] = useState(0);

  useEffect(() => {
    setSelectedDeltaStrike(selectedDelta);
  }, [selectedDelta]);

  useEffect(() => {
    const fg =
      volatility_curves[firstTenor]?.[selectedDeltaStrike]?.map((p, idx) => ({
        x: p.room_time,
        y: p.volatility,
      })) || [];
    const sg =
      volatility_curves[secondTenor]?.[selectedDeltaStrike]?.map((p, idx) => ({
        x: p.room_time,
        y: p.volatility,
      })) || [];
    const allXValues = [...fg, ...sg].map((p) => p.x);
    const allYValues = [...fg, ...sg].map((p) => p.y);
    const minXValue = Math.min(...allXValues);
    const maxXValue = Math.max(...allXValues);
    const minYValue = Math.min(...allYValues);
    const maxYValue = Math.max(...allYValues);

    setXMin(minXValue);
    setXMax(maxXValue);
    setYMin(minYValue);
    setYMax(maxYValue * 1.01);

    setFirstGraph(fg);
    setSecondGraph(sg);
  }, [firstTenor, secondTenor, selectedDeltaStrike, volatility_curves]);

  return (
    <div
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Row
        style={{
          paddingTop: 0,
          paddingLeft: 20,
          paddingBottom: 5,
          position: "absolute",
          left: 10,
          gap: ".25rem",
          zIndex: 2000,
        }}
      >
        <StyledText fontSize={13} fontWeight={500}>
          Vol Chart
        </StyledText>

        {initial_state_loaded && tenorChoices[0] && (
          <>
            <StyledSelect
              dropdownMatchSelectWidth={false}
              color="#d73c5180"
              value={
                sticky_model
                  ? MATURITY[firstTenor]
                  : timeUnixToDatePretty(firstTenor)
              }
              options={tenorChoices.map((t) => ({
                value: t,
                label: sticky_model ? MATURITY[t] : timeUnixToDatePretty(t),
              }))}
              onChange={(val: string) => {
                setFirstTenor(parseFloat(val));
              }}
            />

            <StyledSelect
              dropdownMatchSelectWidth={false}
              color="#0097D880"
              value={
                sticky_model
                  ? MATURITY[secondTenor]
                  : timeUnixToDatePretty(secondTenor)
              }
              options={tenorChoices.map((t) => ({
                value: t,
                label: sticky_model ? MATURITY[t] : timeUnixToDatePretty(t),
              }))}
              onChange={(val: string) => {
                setSecondTenor(parseFloat(val));
              }}
            />
          </>
        )}
      </Row>

      {initial_state_loaded && firstGraph && (
        <div style={{ position: "relative" }}>
          <Line
            data={{
              labels: new Array(firstGraph.length).fill(""),
              datasets: [
                {
                  yAxisID: "A",
                  tension: 0.4,
                  showLine: true,
                  borderWidth: 1,
                  pointBorderWidth: 0,
                  data: firstGraph,
                  fill: true,
                  backgroundColor: (context: any) => {
                    const { ctx } = context.chart;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "#d73c5150");
                    gradient.addColorStop(1, "#d73c5110");
                    return gradient;
                  },
                  borderColor: "#d73c51",
                },
                {
                  yAxisID: "A",
                  tension: 0.4,
                  showLine: true,
                  borderWidth: 1,
                  pointBorderWidth: 0,
                  data: secondGraph,
                  fill: true,
                  backgroundColor: (context: any) => {
                    const { ctx } = context.chart;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "#0097D850");
                    gradient.addColorStop(1, "#0097D810");
                    return gradient;
                  },
                  borderColor: "#0097D8",
                },
              ],
            }}
            height={145}
            width={250}
            options={{
              animation: { duration: 0 },
              plugins: { legend: { display: false } },
              scales: {
                A: {
                  type: "linear",
                  position: "right",
                  min: yMin,
                  max: yMax,
                  grid: {
                    display: true,
                  },
                },
                // x: {
                //   type: "linear",
                //   position: "bottom",
                //   min: xMin,
                //   max: xMax,
                //   // grid: {
                //   //   display: true,
                //   //   color: "#ffffff30",
                //   // },
                // },
              },
              elements: {
                point: {
                  radius: 0,
                },
              },
            }}
            style={{ marginTop: 10 }}
          />
        </div>
      )}
    </div>
  );
};

export default NewVolChart;

const StyledSelect = styled(
  (
    props: SelectProps<any> & {
      color?: string;
    }
  ) => <Select {...props} />
)`
  .ant-select-selector {
    background-color: ${(props) => props.color || "#ffcccb"} !important;
    border-radius: 8px !important;
    color: white !important;
    width: fit-content !important;
    height: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;

    &:hover {
      border-color: ${(props) => props.color + "20" || "#ffcccb"} !important;
    }
  }
  .ant-select-selection-item {
    font-size: 10px !important;
    line-height: 20px !important; // Added line
    padding: 0 0.25rem !important;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #ff6f61 !important;
    box-shadow: 0 0 0 2px rgba(255, 111, 97, 0.2) !important;
  }
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CALL } from "stateConstants";

import { ClientCall, ClientCallPayload, ClientCallsState } from "types";
import { precision, randint } from "utils/numbers";
import { clearRoom, retrieveInitialData } from "../room/actions";
import {
  ccGetVolatility,
  ccPricerData,
  clientCallAccepted,
  clientCallAnswered,
  clientCallCanceled,
  clientCallCreated,
  clientCallDeclined,
  clientCallExpired,
} from "./actions";

const initialState: ClientCallsState = {};
const clientCallsSlice = createSlice({
  name: "clientCalls",
  initialState,
  reducers: {
    removeClientCall(state, action: PayloadAction<number>) {
      const { payload: id } = action;
      delete state[id];
    },
  },
  extraReducers: (builder) => {
    const parseCall: (call: ClientCallPayload) => ClientCall = (call) => {
      return {
        ...call,
        strike: Number(call.strike),
        client_vol_ask: Number(call.client_vol_ask),
        client_vol_bid: Number(call.client_vol_bid),
        trader_vol_ask_quote: Number(call.trader_vol_ask_quote),
        trader_vol_bid_quote: Number(call.trader_vol_bid_quote),
        client_premium_ask: Number(call.client_premium_ask),
        client_premium_bid: Number(call.client_premium_bid),
        trader_premium_ask_quote: Number(call.trader_premium_ask_quote),
        trader_premium_bid_quote: Number(call.trader_premium_bid_quote),
        volatility: Number(call.volatility || 0),
      };
    };

    builder
      .addCase(clientCallCreated, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(clientCallAnswered, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(clientCallAccepted, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(clientCallDeclined, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(clientCallCanceled, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(clientCallExpired, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(retrieveInitialData, (state, action) => {
        const { client_calls } = action.payload;
        client_calls.forEach((call) => {
          state[call.id] = {
            ...call,
            strike: Number(call.strike),
            client_vol_ask: Number(call.client_vol_ask),
            client_vol_bid: Number(call.client_vol_bid),
            trader_vol_ask_quote: Number(call.trader_vol_ask_quote),
            trader_vol_bid_quote: Number(call.trader_vol_bid_quote),
            client_premium_ask: Number(call.client_premium_ask),
            client_premium_bid: Number(call.client_premium_bid),
            trader_premium_ask_quote: Number(call.trader_premium_ask_quote),
            trader_premium_bid_quote: Number(call.trader_premium_bid_quote),
            volatility: Number(call.volatility || 0),
          };
        });
      })
      .addCase(ccPricerData, (state, action) => {
        const { premium, correlation_id } = action.payload.price_data;
        const { call, put } = premium;
        const [key, bid_ask] = correlation_id.split(" ");
        const parsedKey = parseInt(key);
        if (bid_ask === "BID") {
          state[parsedKey].trader_premium_bid_quote =
            state[parsedKey].put_or_call === CALL
              ? Number(call.toFixed(2))
              : Number(put.toFixed(2));
        } else {
          state[parsedKey].trader_premium_ask_quote =
            state[parsedKey].put_or_call === CALL
              ? Number(call.toFixed(2))
              : Number(put.toFixed(2));
        }
      })
      .addCase(ccGetVolatility, (state, action) => {
        const { value, correlation_id } = action.payload;
        const parsedValue = parseFloat(value); // parseInt(Number().toFixed(0)); // TODO: fix ofc
        const bid = precision(parsedValue - 0.005, 2);
        const ask = precision(parsedValue + 0.005, 2);
        state[parseInt(correlation_id)].trader_vol_bid_quote = bid;
        state[parseInt(correlation_id)].trader_vol_ask_quote = ask;
        state[parseInt(correlation_id)].volatility = parsedValue;
      })
      .addCase(clearRoom, () => {
        return initialState;
      });
  },
});

export default clientCallsSlice.reducer;
export const { removeClientCall } = clientCallsSlice.actions;

import { Skeleton } from "antd";
import SkeletonRows from "components/reusable/SkeletonRows";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { setDeltaHedge } from "store/spot/reducers";
import { useAppDispatch } from "store/store";
import styled, { css } from "styled-components";
import { convertDelta } from "utils/delta";

const DeltaStrikeButtons: React.FC = () => {
  const {
    delta_strike_values,
    visible_vol_eb_delta_strike_values,
    swiftId,
    selectedDelta,
    initial_state_loaded,
  } = useSelector(
    (state: RootState) => ({
      delta_strike_values: state.spot.delta_strike_values,
      visible_vol_eb_delta_strike_values:
        state.spot.visible_vol_eb_delta_strike_values,
      swiftId: state.spot.swiftId,
      selectedDelta: state.spot.selectedDelta,
      initial_state_loaded: state.spot.initial_state_loaded,
    }),
    shallowEqual
  );

  const dispatch = useAppDispatch();
  return (
    <DeltaContainer>
      {initial_state_loaded ? (
        visible_vol_eb_delta_strike_values[swiftId]
          .slice()
          .sort((a, b) => a - b)
          .map((index: number) => {
            const deltaValue = delta_strike_values[index];
            if (deltaValue) {
              return (
                <DeltaButton
                  key={deltaValue}
                  selected={deltaValue === selectedDelta}
                  onClick={() => {
                    dispatch(
                      setDeltaHedge({
                        selectedDelta: deltaValue,
                        TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: 1,
                      })
                    );
                  }}
                >
                  {convertDelta[Number(delta_strike_values[index])]}
                </DeltaButton>
              );
            }

            return <></>;
          })
      ) : (
        <SkeletonRows rows={5} />
      )}
    </DeltaContainer>
  );
};

const StraddleDeltaButtons: React.FC = () => {
  return (
    <DeltaContainer>
      <Title type="Sell">DELTA</Title>

      <DeltaRow>
        <DeltaStrikeButtons />
      </DeltaRow>

      <Title type="Buy">DELTA</Title>
    </DeltaContainer>
  );
};

const DeltaContainer = styled.div<{ type?: "Buy" | "Sell" }>`
  display: flex;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
  width: 100%;

  ${(props) => {
    if (props.type === "Buy") {
      return css`
        direction: rtl;
      `;
    }
  }}
`;

const Title = styled.div<{ type: "Sell" | "Buy" }>`
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  cursor: default;

  ${(props) => {
    if (props.type === "Buy") {
      return css`
        text-align: right;
      `;
    }
  }}
`;

const DeltaButton = styled.div<{ selected: boolean }>`
  padding: 1px 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;

  ${(props) => {
    if (props.selected) {
      return css`
        background: #15222d;
        border-color: #4d96c1;
      `;
    }
  }}

  &:hover {
    background: rgba(21, 34, 45, 0.5);
  }
`;

const DeltaRow = styled.div`
  display: flex;
  gap: 10px;
  direction: ltr;
`;

export default StraddleDeltaButtons;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { requestCBState } from "store/centralbank/actions";
import { numberWithCommas } from "utils/numbers";
import {
  GreenCircle,
  RedCircle,
} from "components/reusable/GenericStyledComponents";
import { Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import StyledText from "components/reusable/StyledText";
import { TraderStat } from "types";

const CBTraders: React.FC = () => {
  const { traders_stats } = useSelector(
    (state: RootState) => state.centralbank
  );
  const { PNL_DISPLAY_DIVIDER } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  const [rows, setRows] = useState<TraderStat[]>([]);
  const dispatch = useAppDispatch();
  const getState = () => {
    dispatch(requestCBState());
  };

  useEffect(() => {
    getState();
    const req = setInterval(() => {
      getState();
    }, 4000);
    return () => {
      clearInterval(req);
    };
  }, []);

  useEffect(() => {
    if (traders_stats)
      setRows(
        traders_stats.map((trader) => {
          return {
            ...trader,
            realized_pnl: trader.realized_pnl / PNL_DISPLAY_DIVIDER || 0,
            unrealized_pnl: trader.unrealized_pnl / PNL_DISPLAY_DIVIDER || 0,
            pnl: trader.pnl / PNL_DISPLAY_DIVIDER || 0,
          };
        })
      );
  }, [traders_stats]);

  return (
    <div>
      {PNL_DISPLAY_DIVIDER > 1 && (
        <Typography.Paragraph>
          Note: The PnL values are divided by {PNL_DISPLAY_DIVIDER} (
          PNL_DISPLAY_DIVIDER parameter )
        </Typography.Paragraph>
      )}

      <Typography.Text>
        Traders online: {rows.filter((r) => r.online).length}
      </Typography.Text>

      <Table
        bordered
        sticky
        loading={!!rows && Object.keys(rows).length === 0}
        rowKey="name"
        columns={columns}
        dataSource={rows}
        pagination={false}
      />
    </div>
  );
};

const columns: ColumnsType<TraderStat> = [
  {
    dataIndex: "online",
    title: "Online",
    width: "100px",
    render: (online) => (online ? <GreenCircle /> : <RedCircle />),
    sorter: (a, b) => (a.online ? 1 : 0),
    defaultSortOrder: "descend",
    filters: [
      {
        text: "Online",
        value: true,
      },
      {
        text: "Offline",
        value: false,
      },
    ],
    onFilter: (value: string | number | boolean, record: TraderStat) => {
      return record.online === value;
    },
  },
  {
    dataIndex: "name",
    title: "Trader name",
    sorter: (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
  },
  // {
  //   dataIndex: "pnl_percentile_ranking",
  //   title: "Ranking percentile",
  // },
  {
    dataIndex: "client_deals",
    title: "Client deals",
  },
  {
    dataIndex: "unrealized_pnl",
    title: "Unrealized PnL",
    render: (v) => numberWithCommas(v, 0),
  },
  {
    dataIndex: "realized_pnl",
    title: "Realized PnL",
    render: (v) => numberWithCommas(v, 0),
  },
  {
    dataIndex: "pnl",
    title: "PnL",
    render: (v) => numberWithCommas(v, 0),
    defaultSortOrder: "descend",
    sorter: (a, b) => a.pnl - b.pnl,
  },
];

export default CBTraders;

export type EventState = {
  news: MacroEvent[];
  forecasts: MacroEvent[];
  market_impact: MarketImpactPayload;
  market_impact_loaded: boolean;
  impact_votes: {
    [key: number]: {
      [key: number]: {
        spot: number;
        vol: number;
      }
    }
  };
};

export type MacroEvent = {
  id: number;
  room_time: number;
  body: string;
  headline: string;
  event_type: MacroEventTypes;

  published: boolean;
  disabled: boolean;
  passed: boolean;
};

export type MacroEventPayload = Omit<MacroEvent, "body" | "headline"> & {
  event_data: {
    body: string;
    headline: string;
  };
};

export type EventPublishedPayload = {
  event: MacroEventPayload;
};

export enum MacroEventTypes {
  NEWS,
  FORECAST,
}

export type VolImpact = {
  [key: number]: {
    [key: number]: number;
  };
};

export type MarketImpactPayload = {
  spot: number;
  vol: VolImpact;
};


export type VoteNewsPayload = {
  event_id: number;
  swift_id: number;
  vote: number;
  spot_or_vol: "spot" | "vol";
}
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { numberWithCommas } from "utils/numbers";
import { Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { TraderStat } from "types";

const CBLeaderboard: React.FC = () => {
  const leaderboard = useSelector(
    (state: RootState) => state.centralbank.leaderboard
  );
  const PNL_DISPLAY_DIVIDER = useSelector(
    (state: RootState) => state.settings.parameters.PNL_DISPLAY_DIVIDER
  );

  const [rows, setRows] = useState<TraderStat[]>([]);

  useEffect(() => {
    if (!leaderboard) {
      return;
    }

    setRows(
      leaderboard
        .filter((t: any) => t.pnl_percentile_ranking !== 0)
        .map((trader: any) => {
          return {
            ...trader,
            realized_pnl: trader.realized_pnl / PNL_DISPLAY_DIVIDER || 0,
            unrealized_pnl: trader.unrealized_pnl / PNL_DISPLAY_DIVIDER || 0,
            pnl: trader.pnl / PNL_DISPLAY_DIVIDER || 0,
          };
        })
    );
  }, [leaderboard]);

  if (!leaderboard) {
    return (
      <div>
        <Typography.Title level={5} style={{ textAlign: "center" }}>
          The leaderboard will be displayed after the session is finished!
        </Typography.Title>
      </div>
    );
  }

  return (
    <div>
      {PNL_DISPLAY_DIVIDER > 1 && (
        <Typography.Paragraph>
          Note: The PnL values are divided by {PNL_DISPLAY_DIVIDER} (
          PNL_DISPLAY_DIVIDER parameter )
        </Typography.Paragraph>
      )}

      <Table
        bordered
        sticky
        style={{ width: "90%" }}
        loading={!!rows && Object.keys(rows).length === 0}
        rowKey="name"
        columns={columns}
        dataSource={rows}
        pagination={false}
      />
    </div>
  );
};

const columns: ColumnsType<TraderStat> = [
  // {
  //     dataIndex: "online",
  //     title: "Online",
  //     width: "100px",
  //     render: (online) => (online ? <GreenCircle /> : <RedCircle />),
  //     sorter: (a, b) => (a.online ? 1 : 0),
  //     filters: [
  //         {
  //             text: "Online",
  //             value: true,
  //         },
  //         {
  //             text: "Offline",
  //             value: false,
  //         },
  //     ],
  //     onFilter: (value: string | number | boolean, record: TraderStat) => {
  //         return record.online === value;
  //     },
  // },
  {
    dataIndex: "name",
    title: "Trader name",
    sorter: (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
  },
  {
    dataIndex: "pnl_percentile_ranking",
    title: "Ranking percentile",
  },
  // {
  //     dataIndex: "client_deals",
  //     title: "Client deals",
  // },
  // {
  //     dataIndex: "client_volume",
  //     title: "Client deals",
  // },
  {
    dataIndex: "unrealized_pnl",
    title: "Unrealized PnL",
    render: (v) => numberWithCommas(v, 0),
  },
  {
    dataIndex: "realized_pnl",
    title: "Realized PnL",
    render: (v) => numberWithCommas(v, 0),
  },
  {
    dataIndex: "pnl",
    title: "PnL",
    render: (v) => numberWithCommas(v, 0),
    defaultSortOrder: "descend",
    sorter: (a, b) => a.pnl - b.pnl,
  },
];

export default CBLeaderboard;

import { Button, Card, message } from "antd";
import { Column } from "components/reusable/GenericStyledComponents";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { authService } from "services";
import { authenticate } from "store/auth/actions";
import { useAppDispatch } from "store/store";
import { userSuccessAuth } from "store/user/reducers";
import styled from "styled-components";

const EmailActivation: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<string>("");

  const verifyEmail = async () => {
    try {
      setStatus("Verifying your account...");
      setTimeout(async () => {
        try {
          const res = await authService.verifyEmail(token);
          setStatus("Account verified, redirecting you to login page...");
          dispatch(
            userSuccessAuth({
              access: res.token,
              refresh: res.token,
              name: "user",
            })
          );
          setTimeout(() => {
            history.push("/lobby");
          }, 2000);
        } catch {
          setStatus("There was an error with your account verification...");
        }
      }, 2000);
    } catch {
      message.error("Unknown Error");
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Column>
      <LogoLink to="/" style={{ padding: "1rem" }}>
        <img src="/logo.png" alt="logo" />
      </LogoLink>
      <Container>
        <Card
          style={{
            width: "50%",
            height: "70%",
          }}
        >
          <Column
            style={{
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <LoadingSpinner message={status} />
            {/* <div>Token: {token}</div> */}
            {/* <Button onClick={verifyEmail}>Check</Button> */}
          </Column>
        </Card>
      </Container>
    </Column>
  );
};
const Container = styled(Column)`
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const LogoLink = styled(Link)`
  margin-right: auto;
`;
export default EmailActivation;

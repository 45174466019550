import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { Layout } from "react-grid-layout";
import { retrieveInitialData } from "store/room/actions";
import { InitialDataPayload } from "types";

type LayoutComponentKey =
  | "PortfolioInfo"
  | "ChartsSection"
  | "BrokerArea"
  | "GeneralInfo"
  | "NewPricerSection"
  | "BrokerDealer"
  | "QuoteSection";


type LayoutState = {
  editing: boolean;
  activeComponents: { [K in LayoutComponentKey]: boolean };
  components: Layout[];
};

// Initialize all component keys to false.
const initialActiveComponents: { [K in LayoutComponentKey]: boolean } = {
  PortfolioInfo: true,
  ChartsSection: true,
  BrokerArea: true,
  GeneralInfo: true,
  NewPricerSection: true,
  BrokerDealer: true,
  QuoteSection: true,
};

const initialState: LayoutState = {
  editing: false,
  components: [
    { i: "PortfolioInfo", x: 0, y: 0, w: 2, h: 1 },
    { i: "ChartsSection", x: 0, y: 1, w: 2, h: 2 },
    { i: "BrokerArea", x: 0, y: 2, w: 2, h: 3 },
    { i: "GeneralInfo", x: 0, y: 3, w: 2, h: 2 },
    { i: "NewPricerSection", x: 2, y: 0, w: 2, h: 5 },
    { i: "BrokerDealer", x: 4, y: 0, w: 2, h: 3 },
    { i: "QuoteSection", x: 4, y: 6, w: 2, h: 3 },
  ],
  activeComponents: initialActiveComponents,
};
const layoutSlice = createSlice({
  initialState,
  name: "layout",
  reducers: {
    toggleLayoutComponent(state, action: PayloadAction<{ key: LayoutComponentKey }>) {
      const { key } = action.payload;
      state.activeComponents[key] = !state.activeComponents[key];
    },
    toggleLayoutEdit(state) {
      state.editing = !state.editing;
      if (state.editing) {
        message.info("You can resize, move and remove layout items ");
      }
    },
    setLayout(state, action: PayloadAction<Layout[]>) {
      state.components = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        retrieveInitialData,
        (state, action: PayloadAction<InitialDataPayload>) => {
          const { layout } = action.payload;
          if (layout.length)
            state.components = layout;
        }
      )
  }
});

export const { toggleLayoutComponent, toggleLayoutEdit, setLayout } = layoutSlice.actions;
export default layoutSlice.reducer;

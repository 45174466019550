import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import styled from "styled-components";

import { RootState } from "store/rootReducer";
import { numberWithCommas } from "utils/numbers";
import { Typography } from "antd";

const PortfolioInfo = () => {
  const { spotUnrealisedPNL, spotRealisedPNL, positions } = useSelector(
    (state: RootState) => state.trader
  );
  const {
    delta,
    gamma,
    vega,
    theta,
    optRealisedPNL,
    optUnrealisedPNL,
    net_premium,
  } = useSelector(
    (state: RootState) => ({
      delta: state.trader.delta,
      gamma: state.trader.gamma,
      vega: state.trader.vega,
      theta: state.trader.theta,
      optRealisedPNL: state.trader.optRealisedPNL,
      optUnrealisedPNL: state.trader.optUnrealisedPNL,
      net_premium: state.trader.net_premium,
    }),
    shallowEqual
  );
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);
  const {
    PLAYERS_DELTA_LIMIT,
    PLAYERS_GAMMA_LIMIT,
    PLAYERS_VEGA_LIMIT,
    PLAYERS_THETA_LIMIT,
    PNL_DISPLAY_DIVIDER,
  } = useSelector((state: RootState) => state.settings.parameters);

  const [showPnlInfo, setShowPnlInfo] = useState(false);
  // const spotPosition = positions; // / swift?.vol_ticket_unit || 0;

  const limitExceeded = (greek: number, limit: number) =>
    Math.abs(greek) > Math.abs(limit);

  let greekUnit = "";
  if (swift) {
    greekUnit = swift.sticky_model ? "(M)" : "(#S)";
  }
  const [realized_pnl, setRealizedPnl] = useState(
    spotRealisedPNL + optRealisedPNL
  );
  const [unrealized_pnl, setUnrealizedPnl] = useState(
    spotUnrealisedPNL + optUnrealisedPNL
  );

  useEffect(() => {
    setRealizedPnl(spotRealisedPNL + optRealisedPNL);
    setUnrealizedPnl(spotUnrealisedPNL + optUnrealisedPNL);
  }, [
    spotRealisedPNL,
    optRealisedPNL,
    spotUnrealisedPNL,
    optUnrealisedPNL,
    net_premium,
  ]);

  const deltaSum = Number(delta) + Number(positions);

  return (
    <Container>
      <Column>
        <TextRow
          onClick={() => {
            setShowPnlInfo(!showPnlInfo);
          }}
        >
          <Text>
            <GreySpan>rPnL</GreySpan>
          </Text>
          <Text>
            {numberWithCommas(
              PNL_DISPLAY_DIVIDER
                ? realized_pnl / PNL_DISPLAY_DIVIDER
                : realized_pnl,
              0
            )}
          </Text>
        </TextRow>

        <TextRow
          onClick={() => {
            setShowPnlInfo(!showPnlInfo);
          }}
        >
          <Text>
            <GreySpan>uPnL</GreySpan>
          </Text>
          <Text>
            {numberWithCommas(
              PNL_DISPLAY_DIVIDER
                ? unrealized_pnl / PNL_DISPLAY_DIVIDER
                : unrealized_pnl,
              0
            )}
          </Text>
        </TextRow>
      </Column>

      <Column>
        <Text>
          <GreySpan>Delta {greekUnit}</GreySpan>
        </Text>
        <Text
          color={
            limitExceeded(deltaSum, PLAYERS_DELTA_LIMIT) ? "red" : "#e1e1e1"
          }
        >
          {numberWithCommas(deltaSum, 0)}
        </Text>
      </Column>

      <Column>
        <Text>
          <GreySpan>Gamma {greekUnit}</GreySpan>
        </Text>
        <Text
          color={limitExceeded(gamma, PLAYERS_GAMMA_LIMIT) ? "red" : "#e1e1e1"}
        >
          {numberWithCommas(gamma, 0)}
        </Text>
      </Column>

      <Column>
        <Text>
          <GreySpan>Vega ($)</GreySpan>
        </Text>
        <Text
          color={limitExceeded(vega, PLAYERS_VEGA_LIMIT) ? "red" : "#e1e1e1"}
        >
          {numberWithCommas(vega, 0)}
        </Text>
      </Column>

      <Column>
        <Text>
          <GreySpan>Theta ($)</GreySpan>
        </Text>
        <Text
          color={limitExceeded(theta, PLAYERS_THETA_LIMIT) ? "red" : "#e1e1e1"}
        >
          {numberWithCommas(theta, 0)}
        </Text>
      </Column>

      {showPnlInfo && (
        <AdditionalInfo>
          <TextRow>
            <Text>
              <GreySpan>Opt Unrealised PnL</GreySpan>
            </Text>
            <Text>{numberWithCommas(optUnrealisedPNL)}</Text>
          </TextRow>
          <TextRow>
            <Text>
              <GreySpan>Opt Realised PnL</GreySpan>
            </Text>
            <Text>{numberWithCommas(optRealisedPNL, 0)}</Text>
          </TextRow>
          <TextRow>
            <Text>
              <GreySpan>Net premium</GreySpan>
            </Text>
            <Text>{numberWithCommas(net_premium, 0)}</Text>
          </TextRow>
          <TextRow>
            <Text>
              <GreySpan>Spot Unrealised PnL</GreySpan>
            </Text>
            <Text>{numberWithCommas(spotUnrealisedPNL, 0)}</Text>
          </TextRow>
          <TextRow>
            <Text>
              <GreySpan>Spot Realised PnL</GreySpan>
            </Text>
            <Text>{numberWithCommas(spotRealisedPNL, 0)}</Text>
          </TextRow>
        </AdditionalInfo>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  padding: 0.5rem;

  & div:nth-child(odd) {
    background: #141414;
  }

  & > div {
    flex: 1;
    padding: 5px 10px;
  }

  & > div:first-child {
    flex: 1.5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & > div:nth-last-child(2) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled(Typography.Text)`
  margin: 0;
  font-size: inherit;
`;

const GreySpan = styled.span`
  color: #bfbfbf;
`;

const TextRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 220px;

  border-radius: 5px;
  background: ${(p) => p.theme.colors.body};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 999999999;

  padding: 0 !important;
  & > div {
    padding: 1px 10px;
    z-index: 999999999;
  }
`;

export default PortfolioInfo;

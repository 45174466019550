import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { clearRoom } from "store/room/actions";

import { CentralBankState, GeneralParameters, ParameterTypes } from "types";
import {
  getCBState,
  requestCBState,
  updateParam,
  eventAddedCB,
} from "./actions";

const initialState: CentralBankState = {
  settings: {
    groups: [],
    parameters: [],
  },
  traders_stats: [],
  events: [],
  cb_state_loaded: false,
  parameter_tags: [],
};

const centralBankSlice = createSlice({
  initialState,
  name: "centralbank",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestCBState, () => {})
      .addCase(getCBState, (state, action) => {
        state.settings = action.payload.settings;
        state.events = action.payload.macro_events;
        const stats = action.payload.traders_stats;
        const swift: string = Object.keys(stats)[0];
        if (action.payload.leaderboard)
          state.leaderboard = action.payload.leaderboard[swift];
        else state.leaderboard = undefined;
        state.traders_stats = Object.values(stats[parseInt(swift)]);
        state.parameter_tags = [
          ...new Set(
            Object.values(action.payload.settings.parameters).map((s) => s.tag)
          ),
        ];

        const tagMins: { [tag: string]: number } =
          action.payload.settings.parameters.reduce(
            (acc: { [tag: string]: number }, param: GeneralParameters) => {
              if (acc[param.tag]) {
                acc[param.tag] = Math.min(acc[param.tag], param.display_index);
              } else {
                acc[param.tag] = param.display_index;
              }
              return acc;
            },
            {}
          );

        state.parameter_tags.sort(
          (a: string, b: string) => tagMins[a] - tagMins[b]
        );
        state.cb_state_loaded = true;
      })
      .addCase(updateParam, (state, action) => {
        const { id, val } = action.payload;
        const idx = state.settings.parameters.findIndex(
          (param) => param.id === id
        );
        if (idx !== -1) {
          state.settings.parameters[idx].value = val;
        }
      })
      .addCase(eventAddedCB, (state, action) => {
        const { event } = action.payload;
        state.events.push({
          id: event.id,
          room_id: event.room_id,
          room_time: event.room_time,
          market_impact: { ...event.market_impact },
          event_data: { ...event.event_data },
          delta_seconds: event.delta_seconds,
          passed: event.passed,
          published: event.published,
        });
        state.events = state.events.sort(
          (e1, e2) => e1.room_time - e2.room_time
        );
      })
      .addCase(clearRoom, (state, action) => {
        state = _.cloneDeep(initialState);
      });
  },
});

export default centralBankSlice.reducer;

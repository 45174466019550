import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Select, Button, InputNumber, Typography } from "antd";
import { CaretUpOutlined } from "@ant-design/icons";

import { Column, Row } from "../reusable/GenericStyledComponents";
import StyledText from "../reusable/StyledText";
import { TrainerClassesRoom, TrainerScenarioBE } from "types";
import { RootState } from "store/rootReducer";
import { roomsService } from "services/rooms.service";
import { clearRoom } from "store/room/actions";
import { useAppDispatch } from "store/store";
import LoadingSpinner from "../reusable/LoadingSpinner";
import { secondsToTimestamp } from "../../utils/time";
import ActiveTenors from "./ActiveTenors";
import ActiveStrikes from "./ActiveStrikes";

const DataRow: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => (
  <Row className="justify-content-between">
    <StyledText
      fontSize={13}
      fontWeight={500}
      color="#F8F7F780"
      fontFamily="IBMPlexSans"
    >
      {title}
    </StyledText>
    <StyledText
      fontSize={13}
      fontWeight={400}
      color="#F4F4F470"
      fontFamily="IBMPlexSans"
    >
      {value}
    </StyledText>
  </Row>
);

const TrainerCreateSession = () => {
  const history = useHistory();
  const { classes, selectedScenario } = useSelector(
    (state: RootState) => state.trainer
  );
  const [selectedClass, setSelectedClass] = useState<TrainerClassesRoom>(
    classes[0]
  );
  const [expanded, setExpanded] = useState(false);
  const [modifiedScenarioParameters, setModifiedScenarioParameters] = useState([
    ...selectedScenario.parameters.parameters,
  ]);
  const [modifiedScenario, setModifiedScenario] =
    useState<TrainerScenarioBE>(selectedScenario);

  useEffect(() => {
    setModifiedScenario(selectedScenario);
    setModifiedScenarioParameters([...selectedScenario.parameters.parameters]);
  }, [selectedScenario]);

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const createRoom = async () => {
    const roomId = await roomsService.createRoom(
      selectedClass.id,
      selectedClass.assigned_students
        .filter((student) => student.id !== selectedClass.owner.id)
        .map((student) => ({
          id: student.id,
          username: student.username,
        })),
      [
        {
          id: selectedClass.owner.id,
          username: selectedClass.owner.username,
        },
      ],
      selectedClass.owner.id,
      {
        ...modifiedScenario,
        parameters: { parameters: modifiedScenarioParameters },
      }
    );

    return roomId;
  };

  const { duration, swift } = selectedScenario;

  return loading ? (
    <Container style={{ justifyContent: "center", alignItems: "center" }}>
      <LoadingSpinner message="Room is starting. Please wait ..." />
    </Container>
  ) : (
    <Container>
      <Line />

      <DataRow title="Duration:" value={secondsToTimestamp(duration)} />
      <DataRow title="Market:" value={swift[0]?.asset_class || ""} />
      <DataRow title="Security:" value={swift[0]?.name || ""} />

      <Line style={{ marginBottom: ".5rem" }} />

      <Row
        className="align-items-center"
        style={{ justifyContent: "space-between" }}
      >
        <StyledText
          fontSize={13}
          fontWeight={500}
          color="#F8F7F780"
          fontFamily="IBMPlexSans"
        >
          Class:
        </StyledText>

        <Select
          showSearch
          dropdownMatchSelectWidth={false} // Make the dropdown width independent from the parent
          dropdownStyle={{ zIndex: 100001 }}
          style={{ width: "fit-content" }}
          defaultValue={0}
          options={classes.map((cls, idx) => {
            return { label: cls.name, value: idx };
          })}
          onChange={(idx) => {
            setSelectedClass(classes[idx]);
          }}
          filterOption={(input, option) =>
            ((option?.label.toLocaleLowerCase() ?? "") as string).includes(
              input.toLocaleLowerCase()
            )
          }
          filterSort={(optionA: any, optionB: any) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
        />
      </Row>
      <Row
        className="align-items-center"
        style={{ justifyContent: "space-between" }}
      >
        <StyledText
          fontSize={13}
          fontWeight={500}
          color="#F8F7F780"
          fontFamily="IBMPlexSans"
        >
          Real seconds to game days ratio:
        </StyledText>

        <InputNumber
          defaultValue={
            selectedScenario.parameters.parameters.find(
              (p) => p.code === "REAL_SECONDS_TO_GAME_DAYS_RATIO"
            ).value
          }
          // value={selectedScenario.parameters.parameters.filter((p) => p.code === "REAL_SECONDS_TO_GAME_DAYS_RATIO")[0].value}
          onChange={(value) => {
            setModifiedScenarioParameters(
              modifiedScenarioParameters.map((p: any) => {
                if (p.code === "REAL_SECONDS_TO_GAME_DAYS_RATIO") {
                  return {
                    ...p,
                    min_value: value,
                    max_value: value,
                    value,
                  };
                }
                return p;
              })
            );
          }}
        />
      </Row>

      <Line />

      <AdvancedSection>
        <AdvancedTitle
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Typography.Title level={5}>Advanced Settings</Typography.Title>
          <CaretUpOutlined
            style={{
              transform: !expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all ease 300ms",
            }}
          />
        </AdvancedTitle>

        {expanded && (
          <Column>
            {/* <Row
              className="align-items-center"
              style={{ justifyContent: "space-between" }}
            >
              <StyledText
                fontSize={13}
                fontWeight={500}
                color="#F8F7F780"
                fontFamily="IBMPlexSans"
              >
                Settlement:
              </StyledText>
              <Select
                style={{ width: "100px" }}
                options={[
                  { label: "Cash", value: 1 },
                  { label: "Physical", value: 2 },
                ]}
                value={selectedScenario.parameters.parameters.filter((p) => p.code === "OPTIONS_EXERCISE_TYPE")[0].value}
                defaultValue={selectedScenario.parameters.parameters.filter((p) => p.code === "OPTIONS_EXERCISE_TYPE")[0].value}
                onChange={(opt) => {
                  setModifiedScenarioParameters(
                    modifiedScenarioParameters.map((p: any) => {
                      if (p.code === "OPTIONS_EXERCISE_TYPE") {
                        return {
                          ...p,
                          min_value: opt,
                          max_value: opt,
                          value: opt,
                        };
                      }
                      return p;
                    })
                  );
                }}
              />
            </Row> */}
            <ActiveTenors
              sticky_model={modifiedScenario.swift[0].sticky_model}
              modifiedScenario={modifiedScenario}
              setModifiedScenario={setModifiedScenario}
            />
            <ActiveStrikes
              sticky_model={modifiedScenario.swift[0].sticky_model}
              modifiedScenario={modifiedScenario}
              setModifiedScenario={setModifiedScenario}
            />
          </Column>
        )}
      </AdvancedSection>

      <Row
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <Button
          onClick={async () => {
            setLoading(true);
            // What happens if this fails?
            const roomId = await createRoom();

            // TODO: This is not ok, make a callback!
            // pass a function to redux or set some state and wait for it
            setTimeout(() => {
              dispatch(clearRoom());
              history.push("/centralbank/" + roomId);
            }, 1000);
          }}
        >
          Start session
        </Button>
      </Row>
    </Container>
  );
};

const Container = styled(Column)`
  gap: 8px;

  overflow-y: auto;
  overflow-x: hidden;
  min-height: 70vh;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #434343;
  ${(p) => p.theme.grey9};
`;

const AdvancedSection = styled.div`
  //
`;

const AdvancedTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  .ant-typography {
    margin-bottom: 0;
    //color: inherit;
  }

  &:hover {
    color: ${(p) => p.theme.primary};
  }
`;

export default TrainerCreateSession;

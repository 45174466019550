import { Table, TableProps, Typography } from "antd";
import { Row } from "components/reusable/GenericStyledComponents";
import { useRoomTimeContext } from "contexts/RoomTimeProvider";
import React, { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Toggle from "react-toggle";
import {
  CALL,
  OPT_EXERCISED,
  OPT_EXPIRED,
  OPT_PENDING,
  TOGL_EXECUTE,
  TOGL_IGNORE,
} from "stateConstants";
import { optionToggle } from "store/blotter/actions";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import styled from "styled-components";
import { MATURITY, Option } from "types";
import { numberWithCommas } from "utils/numbers";
import { secondsToTimestamp, timeUnixToDatePretty } from "utils/time";

type Props = {
  options: Option[];
};

const OptionsBlotterTable: React.FC<Props> = ({ options }) => {
  const dispatch = useAppDispatch();
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  const traderId = useSelector((state: RootState) => state.user.traderId);
  const AUTO_EXERCISE = useSelector(
    (state: RootState) => state.settings.parameters.AUTO_EXERCISE
  );
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);

  const handleToggleChange = useCallback(
    (event, recordId) => {
      dispatch(
        optionToggle({
          optionId: recordId,
          toggle: event.target.checked ? TOGL_EXECUTE : TOGL_IGNORE,
        })
      );
    },
    [dispatch]
  );

  const dynamicColumns = useMemo(
    () => [
      {
        dataIndex: "market_taker_id",
        title: "Bought/Sold",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text
            style={{
              color: record.is_bought ? "#00B287" : "#F84960",
            }}
          >
            {record.is_bought ? "Bought" : "Sold"}
          </Text>
        ),
      },
      {
        dataIndex: "market_taker_id",
        title: "Exercise",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <div>
            <OptionText isBought={record.is_bought}>
              {record.status === OPT_EXERCISED &&
                (record.is_bought ? "Exercised" : "Assigned")}
            </OptionText>
            {record.status === OPT_EXPIRED && <Text>Expired</Text>}

            {record.status === OPT_PENDING && record.is_bought && (
              <Row className="align-items-center" style={{ gap: 5 }}>
                <Toggle
                  checked={!!record.toggle}
                  disabled={AUTO_EXERCISE === 1}
                  icons={false}
                  onChange={(event) => handleToggleChange(event, record.id)}
                />
                <Text>{record.toggle ? "Yes" : "No"}</Text>
              </Row>
            )}
          </div>
        ),
      },
      {
        title: "Call/Put",
        dataIndex: "put_or_call",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>{record.put_or_call === CALL ? "Call" : "Put"}</Text>
        ),
      },
      {
        title: "Strike",
        dataIndex: "strike",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => <Text>{record.strike}</Text>,
      },
      {
        title: "Premium",
        dataIndex: "premium",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>
            {numberWithCommas(
              (record.is_bought ? -1 : 1) *
                record.initial_unit_premium *
                record.amount *
                (swift?.vol_ticket_unit || 0),
              0
            )}
          </Text>
        ),
      },
      {
        title: "Notional",
        dataIndex: "amount",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>{`${numberWithCommas(record.amount)}/${numberWithCommas(
            record.amount * (swift?.vol_ticket_unit || 1)
          )}`}</Text>
        ),
      },

      {
        title: "Tenor",
        dataIndex: "tenor",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>
            {sticky_model
              ? MATURITY[record.tenor]
              : timeUnixToDatePretty(record.tenor)}
          </Text>
        ),
      },

      {
        title: "Timestamp",
        dataIndex: "created_at_room_time",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>
            {/* {Math.floor(option.room_time_tenor / REAL_SECONDS_TO_GAME_DAYS_RATIO) } */}
            {secondsToTimestamp(record.created_at_room_time)}
          </Text>
        ),
      },
    ],
    [traderId, swift, AUTO_EXERCISE, sticky_model]
  );

  return (
    <BlotterTable
      size="small"
      rowKey="id"
      dataSource={options}
      pagination={false}
      style={{ height: "100%" }}
      columns={[...dynamicColumns]}
    />
  );
};

const OptionText: React.FC<{ isBought: boolean; children: React.ReactNode }> =
  React.memo(({ isBought, children }) => (
    <Text style={{ color: isBought ? "#00B287" : "#F84960" }}>{children}</Text>
  ));

const Text = styled(Typography.Text)`
  display: block;
  margin: 0;
  font-size: 10px;
  font-weight: 600;
`;

const BlotterTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table {
    font-size: 10px !important;

    .ant-table-container th:first-child {
      border-start-start-radius: 3px !important;
    }
    .ant-table-container th:last-child {
      border-start-end-radius: 3px !important;
    }

    .ant-table-cell {
      padding: 5px 1px !important;
    }
  }
`;

export default OptionsBlotterTable;

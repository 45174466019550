import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MacroEventTypes,
  InitialDataPayload,
  MacroEvent,
  MacroEventPayload,
  EventState,
  EventPublishedPayload,
  VolImpact,
  NewsVotedConfimationPayload,
} from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";
import {
  DirectionConfidenceSpotPayload,
  directionConfidenceSpotRefreshed,
  DirectionConfidenceVolPayload,
  directionConfidenceVolRefreshed,
  eventPublished,
  newsVotedAction,
} from "./actions";

const initialState: () => EventState = () => ({
  news: [],
  forecasts: [],
  market_impact: {
    spot: 0,
    vol: {
      0: {
        0: 0,
      },
    },
  },
  impact_votes: {},
  market_impact_loaded: false,
});

export const eventSlice = createSlice({
  initialState,
  name: "events",
  reducers: {},
  extraReducers: (builder) => {
    const parseEvent: (payload: MacroEventPayload) => MacroEvent = (
      payload
    ) => {
      console.log({ payload });

      const { event_data } = payload;
      const macroEvent: MacroEvent &
        Partial<Pick<MacroEventPayload, "event_data">> = {
        ...payload,
        ...event_data,
      };
      delete macroEvent.event_data;

      return macroEvent;
    };

    const sorter = (a: MacroEvent, b: MacroEvent) => b.room_time - a.room_time;

    builder
      .addCase(
        retrieveInitialData,
        (state, action: PayloadAction<InitialDataPayload>) => {
          const { events, swifts, tenors, impact_votes } = action.payload;
          const [{ sticky_model, swift_id }] = swifts;
          const vol: VolImpact = {};
          state.impact_votes = impact_votes;
          console.log({ events });

          if (sticky_model) {
            for (const t of tenors[swift_id]) {
              vol[t] = {};
              for (const d of [0.1, 0.25, 0.5, 0.75, 0.9]) {
                vol[t][d] = 0;
              }
            }
          } else {
            const { vol_eb_strikes } = action.payload;
            const strikes = vol_eb_strikes[swift_id];

            for (const t of tenors[swift_id]) {
              vol[t] = {};
              for (const s of strikes) {
                vol[t][Number(s)] = 0;
              }
            }
          }

          const news = events
            .filter((event) => event.event_type === MacroEventTypes.NEWS)
            .map(parseEvent)
            .sort(sorter);

          const forecasts = events
            .filter((event) => event.event_type === MacroEventTypes.FORECAST)
            .map(parseEvent)
            .sort(sorter);

          state.news = news;
          state.forecasts = forecasts;
          state.market_impact = {
            spot: 0,
            vol,
          };
          state.market_impact_loaded = true;
        }
      )
      .addCase(
        eventPublished,
        (state, action: PayloadAction<EventPublishedPayload>) => {
          const { event } = action.payload;

          const type =
            event.event_type === MacroEventTypes.NEWS ? "news" : "forecasts";
          state[type].push(parseEvent(event));
          state[type].sort(sorter);
        }
      )
      .addCase(
        directionConfidenceSpotRefreshed,
        (state, action: PayloadAction<DirectionConfidenceSpotPayload>) => {
          const { payload } = action.payload;
          state.market_impact.spot = parseFloat(
            Object.values(payload.direction_confidence_values)[0]
          );
          state.market_impact_loaded = true;
        }
      )
      .addCase(
        directionConfidenceVolRefreshed,
        (state, action: PayloadAction<DirectionConfidenceVolPayload>) => {
          const { payload } = action.payload;
          const swift_id = Object.keys(payload.direction_confidence_values)[0];
          // eslint-disable-next-line
          for (const tenor of Object.keys(
            payload.direction_confidence_values[swift_id]
          )) {
            // eslint-disable-next-line
            for (const delta_strike of Object.keys(
              payload.direction_confidence_values[swift_id][tenor]
            )) {
              state.market_impact.vol[parseFloat(tenor)][
                parseFloat(delta_strike)
              ] = parseFloat(
                payload.direction_confidence_values[swift_id][tenor][
                delta_strike
                ]
              );
            }
          }
          // state.market_impact.vol =
          state.market_impact_loaded = true;
        }
      )
      .addCase(newsVotedAction, (state, action: PayloadAction<NewsVotedConfimationPayload & { spot_or_vol: "spot" | "vol" }>) => {
        const { event_id, swift_id, spot_or_vol, vote } = action.payload;
        if (!Object.keys(state.impact_votes[event_id]).length) state.impact_votes[event_id] = {};
        if (!Object.keys(state.impact_votes[event_id][swift_id]).length) state.impact_votes[event_id][swift_id] = { spot: 0, vol: 0 };
        if (spot_or_vol === "spot") {
          state.impact_votes[event_id][swift_id].spot = vote;
        }
        if (spot_or_vol === "vol") {
          state.impact_votes[event_id][swift_id].vol = vote;
        }

      })
      .addCase(clearRoom, initialState);
  },
});

export default eventSlice.reducer;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Button, Space, Table, Typography } from "antd";

import { Column, Row } from "../reusable/GenericStyledComponents";
import StyledText from "../reusable/StyledText";
import { Permission, RoomStatus } from "types";
import { RootState } from "store/rootReducer";
import { formatTime } from "utils/time";
import { trainerService } from "services";

const TrainerHistory: React.FC = () => {
  const { historySessions, scenarios, instruments } = useSelector(
    (state: RootState) => state.trainer
  );
  const { permission } = useSelector((state: RootState) => state.user);

  const history = useHistory();

  const joinScenario = (scenarioId: number) => {
    const scenario = scenarios.find((s) => scenarioId === s.id);
    if (scenario !== undefined) {
      return scenario;
    }
    return { name: "", swift: [{ code: "" }] };
  };

  return (
    <Container>
      <Typography.Title level={3}>Session history</Typography.Title>

      <DataContainer>
        <Table
          sticky
          loading={!historySessions.length}
          rowKey="created"
          style={{ width: "100%", height: "100%" }}
          columns={[
            {
              dataIndex: "name",
              title: "Scenario name",
              render: (_, record) => (
                <Space size="middle">
                  {joinScenario(parseInt(record.scenario_id)).name}
                </Space>
              ),
            },
            {
              dataIndex: "training_class_n",
              title: "Class",
              render: (_, record) => (
                <Space size="middle">{record.training_class.name}</Space>
              ),
            },
            {
              dataIndex: "training_class_fn",
              title: "Trainer",
              render: (_, record) => (
                <Space size="middle">
                  {record.training_class.owner.first_name}
                </Space>
              ),
            },
            {
              filters: instruments.map((ac) => {
                return { text: ac, value: ac };
              }),
              onFilter: (value: string | number | boolean, record) => {
                return (
                  joinScenario(parseInt(record.scenario_id)).swift[0].code ===
                  value
                );
              },
              dataIndex: "scenario_id",
              title: "Instrument",
              render: (_, record) => (
                <Space size="middle">
                  {joinScenario(parseInt(record.scenario_id)).swift[0].code}
                </Space>
              ),
            },
            {
              sorter: (a, b) => {
                const dateA = new Date(a.created);
                const dateB = new Date(b.created);
                // eslint-disable-next-line no-nested-ternary
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
              },
              defaultSortOrder: "ascend",
              dataIndex: "created",
              title: "Created",
              render: (_, record) => (
                <Space size="middle">
                  {formatTime(new Date(record.created))}
                </Space>
              ),
            },
            {
              title: "Actions",
              render: (_, record) => (
                <div>
                  <Button
                    onClick={() => {
                      history.push(`/reports/${record.uuid}`);
                    }}
                  >
                    View reports
                  </Button>
                  <Button
                    onClick={() => {
                      history.push(`/centralbank/${record.uuid}`);
                    }}
                  >
                    Central bank
                  </Button>
                  {permission === Permission.Admin && (
                    <Button
                      onClick={async () => {
                        const response = await trainerService.historical_vols(
                          record.uuid
                        );

                        const volBlob = new Blob([response.volatility], {
                          type: "text/csv",
                        });
                        const spotBlob = new Blob([response.spot], {
                          type: "text/csv",
                        });

                        const volUrl = window.URL.createObjectURL(volBlob);
                        const volLink = document.createElement("a");
                        volLink.style.display = "none";
                        volLink.href = volUrl;
                        volLink.download = `historical_volatilities_${record.uuid}.csv`;
                        document.body.appendChild(volLink);
                        volLink.click();
                        window.URL.revokeObjectURL(volUrl);

                        const spotUrl = window.URL.createObjectURL(spotBlob);
                        const spotLink = document.createElement("a");
                        spotLink.style.display = "none";
                        spotLink.href = spotUrl;
                        spotLink.download = `historical_spot_price_${record.uuid}.csv`;
                        document.body.appendChild(spotLink);
                        spotLink.click();
                        window.URL.revokeObjectURL(spotUrl);
                      }}
                    >
                      Spot/Vol data
                    </Button>
                  )}
                </div>
              ),
            },
          ]}
          dataSource={historySessions}
          pagination={false}
        />
      </DataContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const DataContainer = styled.div`
  margin-top: 1rem;
  max-height: 90vh;
  overflow-y: auto;
`;

export default TrainerHistory;

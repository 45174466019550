import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";

import { RootState } from "store/rootReducer";
import { authService } from "services";
import { useServerStatusContext } from "contexts/ServerStatusProvider";
import {
  GreenCircle,
  RedCircle,
  YellowCircle,
} from "./GenericStyledComponents";
import EditUserModal from "components/Lobby/EditUserModal";
import { ModifiedUserType } from "types";

const Header: React.FC<{ showServerStatus: (val: boolean) => void }> = ({
  showServerStatus,
}) => {
  const {
    isAuthenticated,
    permission,
    first_name,
    last_name,
    username,
    email,
    persistentId,
  } = useSelector((state: RootState) => state.user);
  const { workersUp, loading } = useServerStatusContext();
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [modifiedUser, setModifiedUser] = useState<ModifiedUserType>({
    first_name,
    last_name,
    email,
    username,
    password: "",
    confirmPassword: "",
    oldPassword: "",
    userId: persistentId,
  });

  return (
    <Container>
      <Modal
        open={showUserModal}
        title="Edit your profile"
        onCancel={() => {
          setShowUserModal(false);
        }}
        onOk={async () => {
          try {
            await authService.editUser(modifiedUser);
            setShowUserModal(false);
          } catch (e: any) {
            alert(e.response.data.message);
          }
        }}
      >
        <EditUserModal
          modifiedUser={modifiedUser}
          setModifiedUser={setModifiedUser}
        />
      </Modal>

      <LogoLink to="/">
        <img src="/logo.png" alt="logo" />
      </LogoLink>

      <Menu>
        {permission === "Admin" && <NavLink to="/admin">Admin panel</NavLink>}
        {(permission === "Trainer" || permission === "Admin") && (
          <NavLink to="/trainer">Trainer Dashboard</NavLink>
        )}
        {permission === "Admin" && <NavLink to="/admin">Admin panel</NavLink>}
        {(permission === "Trainer" || permission === "Admin") && (
          <NavLink to="/tournament">Create Tournament</NavLink>
        )}
        {(permission === "Admin" || permission === "Trainer") && (
          <Tooltip
            title="Please start the workers before creating a room"
            open={!workersUp}
          >
            <ServerStatusButton
              onClick={() => {
                showServerStatus(true);
              }}
            >
              <div>Server status</div>
              {loading ? (
                <div>
                  <YellowCircle animate />
                </div>
              ) : (
                <div>{workersUp ? <GreenCircle /> : <RedCircle />}</div>
              )}
            </ServerStatusButton>
          </Tooltip>
        )}
      </Menu>

      {isAuthenticated ? (
        <div style={{ display: "flex", gap: "1.5rem", alignItems: "center" }}>
          <NavButton
            onClick={() => {
              setShowUserModal(true);
            }}
          >
            <UserOutlined />
            {username}
          </NavButton>

          <NavButton
            onClick={async () => {
              await authService.logout();
              window.location.reload();
            }}
          >
            <LogoutOutlined /> Log out
          </NavButton>
        </div>
      ) : (
        <NavLink to="/">
          <div
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
            }}
          >
            <UserOutlined />
            Account
          </div>
        </NavLink>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  background: #000;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  position: relative;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color3};
    text-decoration: none;
  }

  & a {
    color: ${({ theme }) => theme.color3};
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ServerStatusButton = styled.div`
  display: flex;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color3};
  }

  & a {
    color: ${({ theme }) => theme.color3};
  }
`;

const LogoLink = styled(Link)`
  margin-right: auto;
`;

const NavButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.color3};
  }

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export default Header;

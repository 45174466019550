import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CALL } from "stateConstants";

import { RFQCall, RFQCallPayload, RFQState } from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";
import {
  rfqAnswerCall,
  rfqCallAnswered,
  rfqCallCreated,
  rfqCallExpired,
  rfqCallHit,
  rfqCallRejected,
  rfqError,
  rfqGetVolatility,
  rfqPricerData,
} from "./actions";
import { precision } from "utils/numbers";

const initialState: () => RFQState = () => ({});

const rfqSlice = createSlice({
  name: "rfq",
  initialState,
  reducers: {
    removeRfq: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      delete state[id];
    },
  },
  extraReducers: (builder) => {
    const parseCall: (call: RFQCallPayload) => RFQCall = (call) => {
      const {
        strike,
        strategy_price_bid,
        strategy_price_ask,
        strategy_volatility_bid,
        strategy_volatility_ask,
        volatility,
      } = call;
      const parsedOptions = { ...call.strategy.options };
      Object.keys(parsedOptions).forEach((option_key) => {
        parsedOptions[parseInt(option_key)].volatility_ask *= 100;
        parsedOptions[parseInt(option_key)].volatility_bid *= 100;
        parsedOptions[parseInt(option_key)].premium_bid = precision(
          parsedOptions[parseInt(option_key)].premium_bid,
          2
        );
        parsedOptions[parseInt(option_key)].premium_ask = precision(
          parsedOptions[parseInt(option_key)].premium_ask,
          2
        );
      });

      return {
        ...call,
        strike: Number(strike),
        premium_bid: Number(strategy_price_bid),
        premium_ask: Number(strategy_price_ask),
        volatility_bid: Number(strategy_volatility_bid),
        volatility_ask: Number(strategy_volatility_ask),
        volatility: Number(volatility || 0),
        strategy: {
          ...call.strategy,
          options: parsedOptions,
        },
      };
    };

    builder
      .addCase(rfqCallCreated, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(rfqCallAnswered, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(rfqCallHit, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(rfqCallRejected, (state, action) => {
        const { call } = action.payload;
        state[call.id] = parseCall(call);
      })
      .addCase(rfqCallExpired, (state, action) => {
        const { call } = action.payload;
        if (Object.keys(state).includes(call.id.toString()))
          state[call.id] = parseCall(call);
      })
      .addCase(retrieveInitialData, (state, action) => {
        const { rfq_calls } = action.payload;
        rfq_calls.forEach((call) => {
          state[call.id] = parseCall(call);
        });
      })
      .addCase(rfqPricerData, (state, action) => {
        const { premium, correlation_id } = action.payload.price_data;
        const { call, put } = premium;
        const [key, bid_ask] = correlation_id.split(" ");
        const parsedKey = parseInt(key);
        if (bid_ask === "BID") {
          state[parsedKey].strategy_price_bid =
            state[parsedKey].put_or_call === CALL
              ? Number(call.toFixed(2))
              : Number(put.toFixed(2));
        } else {
          state[parsedKey].strategy_price_ask =
            state[parsedKey].put_or_call === CALL
              ? Number(call.toFixed(2))
              : Number(put.toFixed(2));
        }
      })
      .addCase(rfqGetVolatility, (state, action) => {
        const { value, correlation_id } = action.payload;
        const parsedValue = parseFloat(value);
        const bid = Number(Number(parsedValue - 0.005).toFixed(2));
        const ask = Number(Number(parsedValue + 0.005).toFixed(2));
        state[parseInt(correlation_id)].strategy_volatility_bid = bid;
        state[parseInt(correlation_id)].strategy_volatility_ask = ask;
        state[parseInt(correlation_id)].volatility = parsedValue;
      })
      .addCase(rfqError, (state, action) => {
        const { call, message } = action.payload;
        state[call.id].errorMessage = message;
      })
      .addCase(rfqAnswerCall, (state, action) => {
        const { callId } = action.payload;
        state[callId].errorMessage = "";
      })
      .addCase(clearRoom, initialState);
  },
});

export default rfqSlice.reducer;
export const { removeRfq } = rfqSlice.actions;

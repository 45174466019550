import React, { useEffect, useState } from "react";
import GenericModal from "./GenericModal";
import { Swift, TrainerScenarioBE } from "types";
import styled from "styled-components";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import StyledText from "../reusable/StyledText";
import CloseButton from "assets/NavIcons/clear-button.svg";
import CreateScenario from "components/Admin/Scenario/CreateScenario/CreateScenario";
import { adminService } from "services";
import EditScenario from "components/Admin/Scenario/EditScenario/EditScenario";

type AdminScenarioProps = {
  scenarioModalShown: boolean;
  selectedScenario: TrainerScenarioBE;
  setSelectedScenario: React.Dispatch<React.SetStateAction<TrainerScenarioBE>>;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  fetchScenarios: () => Promise<void>;
};

const AdminScenarioModal: React.FC<AdminScenarioProps> = ({
  scenarioModalShown,
  selectedScenario,
  setSelectedScenario,
  setIsShown,
  fetchScenarios,
}) => {
  const [useForm, setUseForm] = useState<boolean>(true);
  const [selectedSwift, setSelectedSwift] = useState<Swift | null>(null);
  const [swiftList, setSwiftList] = useState<Swift[]>([]);
  const [swiftEditView, setSwiftEditView] = useState<boolean>(true);

  const closeModal = () => {
    setIsShown(false);
    setSelectedSwift(null);
    setSwiftEditView(true);
  };

  const fetchSwiftList = async () => {
    const res = await adminService.fetchSwifts();
    setSwiftList(res);
  };

  useEffect(() => {
    fetchSwiftList();
  }, []);
  return (
    <GenericModal
      isShown={scenarioModalShown}
      onRequestClose={closeModal}
      customClassName="AdminScenarioModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      overlayBackground="#00000060"
    >
      <Container style={{ overflowY: "auto" }}>
        <Row className="justify-content-between align-items-center">
          <StyledText
            fontSize={14}
            fontWeight={500}
            fontFamily="IBMPlexSans"
            color="#FFFFFF50"
          >
            {selectedScenario.name}
          </StyledText>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <img
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            src={CloseButton}
            alt="delete"
            onClick={() => {
              closeModal();
            }}
          />
        </Row>
        {selectedScenario.id === -1 && swiftEditView ? (
          // Create scenario view, needs selected swift first
          <CreateScenario
            closeModal={closeModal}
            swiftList={swiftList}
            selectedSwift={selectedSwift}
            setSelectedSwift={setSelectedSwift}
            setSwiftEditView={setSwiftEditView}
            setSelectedScenario={setSelectedScenario}
            selectedScenario={selectedScenario}
            fetchScenarios={fetchScenarios}
          />
        ) : (
          // Edit scenario view
          <EditScenario
            closeModal={closeModal}
            useForm={useForm}
            selectedScenario={selectedScenario}
            setUseForm={setUseForm}
            fetchScenarios={fetchScenarios}
          />
        )}
      </Container>
    </GenericModal>
  );
};

const Container = styled(Column)`
  width: 100%;
`;
export default AdminScenarioModal;

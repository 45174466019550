import React, { ChangeEvent, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import styled, { css, keyframes } from "styled-components";

import {
  setSpotQuantity,
  setVolQuantity,
  setValidSpotQuantity,
  setValidVolQuantity,
} from "store/spot/reducers";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { Row, Column } from "../../reusable/GenericStyledComponents";
import StyledText from "../../reusable/StyledText";
import NumberInput from "../../reusable/NumberInput";
import DltskArea from "./Vols/DltskArea";
import { Typography } from "antd";

const { Text } = Typography;

const EBHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { spotQuantity, validSpotQuantity, volQuantity, validVolQuantity } =
    useSelector(
      (state: RootState) => ({
        spotQuantity: state.spot.spotQuantity,
        validSpotQuantity: state.spot.validSpotQuantity,
        volQuantity: state.spot.volQuantity,
        validVolQuantity: state.spot.validVolQuantity,
      }),
      shallowEqual
    );
  const { settingsLoaded } = useSelector((state: RootState) => state.settings);
  const {
    PLAYERS_SPOT_MAX_TICKET,
    PLAYERS_SPOT_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
    PLAYERS_VOL_MIN_TICKET,
  } = useSelector((state: RootState) => state.settings.parameters);
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);
  const [spotLot, setSpotLot] = useState<string>(`${spotQuantity}`);
  const [volLot, setVolLot] = useState<string>(`${volQuantity}`);

  const parseLot = (value: string) => {
    return Number(value.replaceAll(",", ""));
  };

  useEffect(() => {
    setSpotLot(`${spotQuantity}`);
  }, [spotQuantity]);

  useEffect(() => {
    setVolLot(`${volQuantity}`);
  }, [volQuantity]);

  useEffect(() => {
    setVolLot(`${volQuantity}`);
  }, [volQuantity]);

  useEffect(() => {
    if (settingsLoaded === 1) {
      const numberValue = parseLot(spotLot);
      if (
        numberValue < PLAYERS_SPOT_MIN_TICKET ||
        numberValue > PLAYERS_SPOT_MAX_TICKET
      ) {
        dispatch(setValidSpotQuantity(false));
      } else {
        dispatch(setValidSpotQuantity(true));
        dispatch(setSpotQuantity(numberValue));
      }
    }
  }, [spotLot]);
  useEffect(() => {
    if (settingsLoaded === 1) {
      const numberValue = parseLot(spotLot);
      if (
        numberValue < PLAYERS_SPOT_MIN_TICKET ||
        numberValue > PLAYERS_SPOT_MAX_TICKET
      ) {
        dispatch(setValidSpotQuantity(false));
      } else {
        dispatch(setValidSpotQuantity(true));
        dispatch(setSpotQuantity(numberValue));
      }
    }
  }, [spotLot]);
  useEffect(() => {
    if (settingsLoaded === 1) {
      const numberValue = parseLot(volLot);
      if (
        numberValue < PLAYERS_VOL_MIN_TICKET ||
        numberValue > PLAYERS_VOL_MAX_TICKET
      ) {
        dispatch(setValidVolQuantity(false));
      } else {
        dispatch(setValidVolQuantity(true));
        dispatch(setVolQuantity(numberValue));
      }
    }
  }, [volLot]);

  const handleKeyDownSpot = (keyCode: number) => {
    switch (keyCode) {
      case 38: // Arrow UP
        setSpotLot(`${parseLot(spotLot) + 10}`);
        break;

      case 40: // Arrow DOWN
        const value = parseLot(spotLot) - 10;
        if (value < 0) {
          break;
        }
        setSpotLot(`${value}`);
        break;
    }
  };
  const handleKeyDownVol = (keyCode: number) => {
    switch (keyCode) {
      case 38: // Arrow UP
        setVolLot(`${parseLot(volLot) + 10}`);
        break;

      case 40: // Arrow DOWN
        const value = parseLot(volLot) - 10;
        if (value < 0) {
          break;
        }
        setVolLot(`${value}`);
        break;
    }
  };

  return (
    <HeaderContainer>
      <TopContainer>
        {validSpotQuantity && validVolQuantity && <Text>{swift?.name}</Text>}

        <QuantityContainer>
          <QuantityWrapper>
            <QuantityText fontSize={15} fontWeight={600}>
              Spot Qty:
            </QuantityText>
            <QuantityInput
              fontSize={20}
              fontWeight={400}
              isNumericString
              value={spotLot}
              allowNegative={false}
              thousandSeparator
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                setSpotLot(value);
              }}
              onKeyDown={(e) => {
                handleKeyDownSpot(e.keyCode);
              }}
            />
          </QuantityWrapper>
          <QuantityWrapper>
            <QuantityText fontSize={15} fontWeight={600}>
              Vol Qty:
            </QuantityText>
            <QuantityInput
              fontSize={20}
              fontWeight={400}
              isNumericString
              value={volLot}
              allowNegative={false}
              thousandSeparator
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                setVolLot(value);
              }}
              onKeyDown={(e) => {
                handleKeyDownVol(e.keyCode);
              }}
            />
          </QuantityWrapper>
          <ErrorArea>
            {!validSpotQuantity && (
              <ErrorText>
                Spot Qty Between{" "}
                {`${PLAYERS_SPOT_MIN_TICKET} - ${PLAYERS_SPOT_MAX_TICKET} `}
              </ErrorText>
            )}
            {!validVolQuantity && (
              <ErrorText>
                Vol Qty Between{" "}
                {`${PLAYERS_VOL_MIN_TICKET} - ${PLAYERS_VOL_MAX_TICKET} `}
              </ErrorText>
            )}
          </ErrorArea>
        </QuantityContainer>
        {validSpotQuantity && validVolQuantity && <Text>{swift?.name}</Text>}
      </TopContainer>
      <DltskArea />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  // background: #1d313d;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  width: 100%;
  padding-bottom: 0.5rem;
`;
// const TopContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// `;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* takes up the rest of the space */
  padding: 16px;
`;

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px;
`;

const QuantityInput = styled(NumberInput)`
  font-family: IBMPlexSans, sans-serif;
  margin-left: 5px;
  width: 6ch;

  background: #141414;
  border-radius: 3px;
  padding: 0 5px;
  border: 1px solid #1f1f1f;
  &:active,
  &:focus {
    border: 1px solid ${(p) => p.theme.primary};
  }
`;

const QuantityText = styled(StyledText)`
  font-family: IBMPlexSans, sans-serif;
  color: #f8f7f7;
  text-align: right;
`;

const popup = keyframes`
  from {
    transform: scale(140%);
  }
  to {
    transform: scale(100%);
  }
`;

const ErrorArea = styled.div`
  margin: 0;
  color: red;
`;

const ErrorText = styled.div`
  font-size: 10px;
  font-weight: 600;
  animation: ${popup} 0.25s ease-in-out;
  background-color: ${(p) => p.theme.colors.body};
  padding: 5px;
`;

export default React.memo(EBHeader);

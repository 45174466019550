import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import MarketImpactSliders from "./MarketImpactSliders";
import { MarketImpactPayload } from "types";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import {
  updateDirectionConfidenceSpot,
  updateDirectionConfidenceVol,
} from "store/events/actions";

type Props = {
  showSliders: boolean;
  setShowSliders: React.Dispatch<React.SetStateAction<boolean>>;
};

const MarketImpactSlidersModal: React.FC<Props> = ({
  showSliders,
  setShowSliders,
}) => {
  const dispatch = useAppDispatch();
  const swifts = useSelector((state: RootState) => state.swifts);
  const swift_id = Object.keys(swifts)[0];
  const { market_impact, market_impact_loaded } = useSelector(
    (state: RootState) => state.events
  );
  const { sticky_model } = useSelector((state: RootState) => state.spot);
  const [marketImpact, setMarketImpact] =
    useState<MarketImpactPayload>(market_impact);
  const [marketShift, setMarketShift] = useState<MarketImpactPayload>({
    ...marketImpact,
  });
  useEffect(() => {
    setMarketImpact(market_impact);
  }, [market_impact]);
  if (!market_impact_loaded) return null;
  return (
    <Modal
      open={showSliders}
      title="Market impact"
      okText="Submit"
      onOk={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(
          updateDirectionConfidenceSpot({ swift_id, impact: marketShift.spot })
        );
        const volShift = marketShift.vol;
        Object.keys(volShift)
          .map((x) => Number(x))
          .forEach((tenor) => {
            Object.keys(volShift[tenor])
              .map((x) => Number(x))
              .forEach((delta_strike) => {
                const impact = volShift[tenor][delta_strike];
                if (impact !== 0) {
                  dispatch(
                    updateDirectionConfidenceVol({
                      swift_id,
                      impact,
                      tenor,
                      delta_strike,
                    })
                  );
                }
              });
          });

        setShowSliders(false);
      }}
      onCancel={() => {
        setShowSliders(false);
      }}
      style={{ minWidth: "90vw" }}
    >
      <MarketImpactSliders
        marketImpact={marketImpact}
        setMarketImpact={setMarketImpact}
        marketShift={marketShift}
        setMarketShift={setMarketShift}
        sticky_model={sticky_model}
      />
    </Modal>
  );
};

export default MarketImpactSlidersModal;

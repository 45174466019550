import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import Close from "assets/close.svg";
import { ASK, BID, PUT, CALL } from "stateConstants";
import { RootState } from "store/rootReducer";
import { Column, Row } from "../reusable/GenericStyledComponents";
import StyledText from "../reusable/StyledText";
import GenericModal from "./GenericModal";
import SpotOrderModal from "./SpotOrderModal";
import OptionOrderModal from "./OptionOrderModal";
import { numberWithCommas, percent2Digits } from "utils/numbers";
import { timeUnixToDatePretty } from "utils/time";
import { Tenor } from "types";
import { getState } from "store/store";
import { selectBestBidAndAsk } from "store/newpricer/selectors";

type Props = {
  isShown: boolean;
  onClose: () => void;
  mode: typeof BID | typeof ASK;
};

const straddleButtonMessage = (selectedTenor: number, sticky_model: number) => {
  return `${
    sticky_model
      ? Tenor[selectedTenor!]
      : timeUnixToDatePretty(parseInt(selectedTenor as any))
  } Straddle`;
};

const optionButtonMessage = (
  selectedTenor: number,
  sticky_model: number,
  put_or_call: string
) => {
  return `${
    sticky_model
      ? Tenor[selectedTenor!]
      : timeUnixToDatePretty(parseInt(selectedTenor as any))
  } ${put_or_call}`;
};

const PlaceAskBidModal: React.FC<Props> = ({ isShown, onClose, mode }) => {
  const { selectedSellTenor, selectedBuyTenor, selectedDelta, sticky_model } =
    useSelector(
      (state: RootState) => ({
        selectedSellTenor: state.spot.selectedSellTenor,
        selectedBuyTenor: state.spot.selectedBuyTenor,
        selectedDelta: state.spot.selectedDelta,
        sticky_model: state.spot.sticky_model,
      }),
      shallowEqual
    );
  const { TRADABLE_VOLS_PUT_CALL_OR_STRADDLE } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  const [put_or_call, setPutOrCall] = useState<0 | 1>(0);
  const { bestBid, bestAsk } = selectBestBidAndAsk(getState());

  useEffect(() => {
    if (sticky_model) {
      setPutOrCall(selectedDelta < 0.5 ? PUT : CALL);
    } else {
      const middlePoint =
        bestBid && bestAsk ? (bestBid?.price + bestAsk?.price) / 2 : 0;
      setPutOrCall(selectedDelta < middlePoint ? PUT : CALL);
    }
  }, [bestBid, bestAsk]);
  const selectedTenor = mode === BID ? selectedSellTenor : selectedBuyTenor;

  const isSpot = selectedTenor === undefined;

  const getTitle = () => {
    if (isSpot) return "SPOT";
    if (TRADABLE_VOLS_PUT_CALL_OR_STRADDLE)
      return straddleButtonMessage(selectedTenor, sticky_model);
    return optionButtonMessage(
      selectedTenor,
      sticky_model,
      put_or_call ? "CALL" : "PUT"
    );
  };

  return (
    <GenericModal
      isShown={isShown}
      customClassName="MiddleModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      onRequestClose={onClose}
      overlayBackground="#00000060"
    >
      <Column style={{ width: "30vw" }}>
        <Row className="justify-content-between">
          <StyledText fontSize={15} fontWeight={500}>
            {getTitle()}
          </StyledText>
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={onClose}
          >
            <img src={Close} alt="close button" />
          </div>
        </Row>

        {isSpot && <SpotOrderModal onClose={onClose} mode={mode} />}
        {!isSpot && <OptionOrderModal onClose={onClose} mode={mode} />}
      </Column>
    </GenericModal>
  );
};

export default PlaceAskBidModal;

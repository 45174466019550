import {
  call,
  fork,
  put,
  take,
  takeEvery,
  delay,
  race,
} from "redux-saga/effects";
import { EventChannel } from "redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { createSocketChannel, createWebSocketConnection } from "utils/socket";
import {
  PING_CMD,
  PING_INTERVAL,
  WS_CONNECT,
  WS_DISCONNECT,
  WS_SEND_MESSAGE,
} from "./constants";
import {
  socketConnectFailure,
  socketConnectSuccess,
  socketDisconnected,
} from "./actions";
import { requestInitialData, retrieveInitialData } from "store/room/actions";

let socket: WebSocket;
let socketChannel: EventChannel<unknown>;

const IGNORED_KEYWORDS = ["type", "data"];

const MAX_UNANSWERED_PINGS = 3;
const NB_WORKERS = 4;

let unansweredPings = 0;

function* handleRequest(channel: EventChannel<unknown>, worker_id: number) {
  while (true) {
    const data: string = yield take(channel);
    try {
      const parsedData: any = JSON.parse(data);
      // const payloadData: any = {};
      const payloadData = _.omit(parsedData, IGNORED_KEYWORDS);
      // console.log("handle", parsedData.type, " on worker ", worker_id);
      // console.log("QUEUE LOAD:", messageQueue.length);

      // Object.keys(parsedData).forEach((key: string) => {
      //   if (_.find(IGNORED_KEYWORDS, (el) => el === key) === undefined) {
      //     payloadData[key as any] = parsedData[key] as any;
      //   }
      // });

      // Handle socket message by dispatching an action
      yield put({
        type: parsedData.type,
        payload: { ...payloadData, ...parsedData.data },
      });
    } catch (e: any) {
      console.error("Handle request socket:", e.message, data);
    }
  }
}

export function* sendMessage(action: PayloadAction<any>) {
  try {
    socket.send(action.payload);
  } catch (e) {
    yield put(socketDisconnected());
  }
}

function sendPing() {
  if (socket.readyState === WebSocket.OPEN) {
    const request = {
      headers: {
        command: PING_CMD,
      },
      body: {},
    };
    socket.send(JSON.stringify(request));
  }
}

function* watchPong(channel: EventChannel<unknown>) {
  while (true) {
    const data: string = yield take(channel);
    const parsedData: any = JSON.parse(data);

    if (parsedData && parsedData.type === "traders/PONG") {
      unansweredPings = 0;
    }
  }
}

function* pingInterval() {
  while (true) {
    yield call(sendPing);

    const { timeout } = yield race({
      pong: take("traders/PONG"),
      timeout: delay(10000),
    });

    if (timeout) {
      unansweredPings += 1;
      if (unansweredPings >= MAX_UNANSWERED_PINGS) {
        yield put(socketDisconnected());
        break;
      }
    }

    yield delay(PING_INTERVAL);
  }
}

export function* connect({ payload: socketUrl }: PayloadAction<string>) {
  try {
    socket = yield call(createWebSocketConnection, socketUrl);
    socketChannel = yield call(createSocketChannel, socket);

    yield put(socketConnectSuccess());

    for (let i = 0; i < NB_WORKERS; i += 1) {
      yield fork(handleRequest, socketChannel, i);
    }
  } catch (error) {
    console.error("Connect error", error);

    if (error instanceof Error) {
      yield put(socketConnectFailure(error.message));
    }
    return;
  }

  // // Start ping-pong mechanism
  yield fork(pingInterval);
  // yield fork(watchPong, socketChannel);

  // Close socket
  yield take(WS_DISCONNECT);

  // Disconnect socket
  socket.close();
}

export function* sendPingOnce() {
  sendPing();
  yield null;
}

function* handleVisibilityChange(action: PayloadAction<any>) {
  const { socketUrl, isVisible } = action.payload;
  console.log("vischange");
  if (isVisible) yield put(requestInitialData());
  if (isVisible && !socket?.OPEN) {
    console.log("RECOONECT AND RETRIEVE INITIAL DATA");

    yield put({ type: WS_CONNECT, payload: socketUrl });
  }
}

export function* watchSocketAsync() {
  yield takeEvery(WS_CONNECT, connect);
  yield takeEvery(WS_SEND_MESSAGE, sendMessage);
  yield takeEvery("BROWSER_VISIBILITY_CHANGE", handleVisibilityChange);
}

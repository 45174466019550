import { Button, Typography, message } from "antd";
import { Column, Line, Row } from "components/reusable/GenericStyledComponents";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import { log } from "console";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { roomService, trainerService } from "services";
import { setSelectedTournament } from "store/lobby/reducers";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import styled from "styled-components";
import { Permission, Tournament, TournamentStatus } from "types";
import { percent2Digits } from "utils/numbers";
import { secondsToHHMM, timeUnixToDatePretty } from "utils/time";

type Props = {
  tournament: Tournament;
  fetchTournaments: () => Promise<void>;
};

const LobbyTournamentCard: React.FC<Props> = ({
  tournament,
  fetchTournaments,
}) => {
  const dispatch = useAppDispatch();
  const { permission } = useSelector((state: RootState) => state.user);
  const [loadingNewSession, setLoadingNewSession] = useState<boolean>(false);
  const history = useHistory();
  const { selectedTournament } = useSelector((state: RootState) => state.lobby);

  if (loadingNewSession)
    return <LoadingSpinner message="A new session is starting..." />;
  return (
    <Container
      isSelected={selectedTournament?.id === tournament.id}
      onClick={() => {
        dispatch(setSelectedTournament({ ...tournament }));
      }}
    >
      <Column style={{ justifyContent: "center", alignItems: "center" }}>
        <Typography.Title level={3}>{tournament.name}</Typography.Title>
        <Line style={{ marginBottom: "2rem" }} />

        <div
          style={{
            padding: "1rem 1rem",
            boxShadow: "0 0 .24rem .24rem #ffffff10",
            width: "100%",
          }}
        >
          <Typography.Text>{tournament.description}</Typography.Text>
        </div>
      </Column>

      {tournament.status === TournamentStatus.Finished ? (
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Typography.Title
            level={4}
            style={{ fontWeight: "900", fontSize: "24", marginTop: "2rem" }}
          >
            Tournament ended, check leaderboard!
          </Typography.Title>
        </Column>
      ) : (
        <StageContainer>
          <SessionsContainer>
            {tournament.current_stage === null ? (
              <Column
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                {" "}
                <Typography.Text>
                  The tournament did not start yet
                </Typography.Text>
              </Column>
            ) : (
              <Column
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                {/* <Typography.Title level={4} style={{ margin: ".5rem .5rem" }}>
                  Start date:{" "}
                  {timeUnixToDatePretty(tournament.current_stage.start_date)}
                </Typography.Title> */}
                <Typography.Text>
                  You need to rank in the top{" "}
                  <Typography.Text style={{ fontWeight: "800" }}>
                    {percent2Digits(tournament.current_stage.pass_rate / 100)}
                  </Typography.Text>{" "}
                  of traders in order to pass to the next stage
                </Typography.Text>
                <Typography.Text style={{ fontWeight: "900", fontSize: "24" }}>
                  Available sessions:
                </Typography.Text>
                {React.Children.toArray(
                  tournament.current_stage.sessions.map((session) => {
                    return (
                      <Column
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          gap: ".5rem",
                          border: "1px solid #ffffff30",
                          borderRadius: "1rem",
                          padding: "1rem 1rem",
                          boxShadow: "inset 0 0 1rem 0.5rem #ffffff10",
                        }}
                      >
                        <TournamentSessionCard>
                          <Typography.Text style={{ fontWeight: "700" }}>
                            Scenario
                          </Typography.Text>
                          <Typography.Text style={{ fontWeight: "700" }}>
                            Asset class
                          </Typography.Text>
                          <Typography.Text style={{ fontWeight: "700" }}>
                            Instrument
                          </Typography.Text>
                          <Typography.Text style={{ fontWeight: "700" }}>
                            Duration
                          </Typography.Text>
                          <Typography.Text>
                            {session.scenario.name}
                          </Typography.Text>
                          <Typography.Text>
                            {session.scenario.asset_class}
                          </Typography.Text>
                          <Typography.Text>
                            {session.scenario.instrument}
                          </Typography.Text>
                          <Typography.Text>
                            {" "}
                            {secondsToHHMM(parseInt(session.scenario.duration))}
                          </Typography.Text>
                        </TournamentSessionCard>
                        {permission !== Permission.Trainer && (
                          <Button
                            type="primary"
                            disabled={
                              tournament.is_player_eliminated ||
                              session.extra.played ||
                              loadingNewSession
                            }
                            onClick={async (e) => {
                              e.stopPropagation();
                              try {
                                setLoadingNewSession(true);
                                const roomId =
                                  await roomService.createTournamentSinglePlayerSession(
                                    session.id
                                  );
                                if (roomId) {
                                  history.push("/game/" + roomId);
                                  setLoadingNewSession(false);
                                } else {
                                  setLoadingNewSession(false);
                                  message.warning(
                                    "Could not start the session!"
                                  );
                                }
                              } catch (error) {
                                setLoadingNewSession(false);
                                message.warning("Could not start the session!");
                              }
                            }}
                          >
                            Start session
                          </Button>
                        )}
                      </Column>
                    );
                  })
                )}
              </Column>
            )}
          </SessionsContainer>
        </StageContainer>
      )}
      {(permission === Permission.Admin || permission === Permission.Trainer) &&
        tournament.status !== TournamentStatus.Finished && (
          <Button
            onClick={async () => {
              await trainerService.advanceTournament(tournament.id);
              await fetchTournaments();
            }}
          >
            Advance to next stage
          </Button>
        )}
      {tournament.is_player_eliminated && (
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Typography.Text>
            You have been eliminated from this tournament
          </Typography.Text>
        </Column>
      )}
      <Line style={{ margin: "1rem 0" }} />
      <Column style={{ justifyContent: "center", alignItems: "center" }}>
        <Typography.Title level={4}>
          Join code:{" "}
          <span style={{ color: "white" }}>{tournament.join_code}</span>
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: "10px" }} // Add some space between the text and the button
            onClick={() => {
              navigator.clipboard.writeText(tournament.join_code).then(
                () => {
                  // This is asynchronous, so we use .then() to respond after the text has been copied
                  message.success("Join code copied to clipboard!");
                },
                () => {
                  message.error("Failed to copy join code");
                }
              );
            }}
          >
            Copy
          </Button>
        </Typography.Title>
      </Column>
    </Container>
  );
};

const TournamentSessionCard = styled.div`
  margin-top: 0.5rem;
  margin-left: 0.75rem;
  gap: 0.75rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
const SessionsContainer = styled(Column)`
  margin: 1rem 1rem;
`;

const StageContainer = styled(Column)`
  margin: 1rem 1rem;
`;

const Container = styled(Column)<{ isSelected: boolean }>`
  border: 1px solid #ffffff20;
  border-radius: 0.75rem;
  padding: 1.5rem 2.5rem;
  background: #0c0d0d;
  box-shadow: ${(props) =>
    props.isSelected
      ? "inset 0 0 1rem 0.75rem #373b3b70"
      : "inset 0 0 1rem 0.5rem #373b3b50"};

  &:hover {
    box-shadow: inset 0 0 1rem 1rem #373b3b70;
  }

  transition: all ease-in-out 0.3s;
`;
export default LobbyTournamentCard;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CloseOutlined,
  DownOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Modal, Button, Tag, Checkbox, Menu, Dropdown, message } from "antd";

import { dateFromSeconds } from "utils/numbers";
import { useAppDispatch } from "store/store";
import { RootState } from "store/rootReducer";
import { resumeRoom, roomPause, finishRoom } from "store/room/actions";
import GameRulesModal from "components/Modal/GameRulesModal";
import PositionGridModal from "components/Modal/PositionGridModal";
import { useRoomTimeContext } from "contexts/RoomTimeProvider";
import { Permission, ROOM_TYPES } from "types";
import { requestCBState } from "store/centralbank/actions";
import useModal from "../../hooks/useModal";
import { toggleLayoutComponent, toggleLayoutEdit } from "store/layout/reducers";
import { adminService } from "services";

const STATUS_TEXT = {
  0: "Game starting",
  1: "Game running",
  2: "Game paused",
  3: "Game finished",
};

const GameRoomHeader: React.FC = () => {
  const { paused, status, room_type, room_id, tournament } = useSelector(
    (state: RootState) => state.room
  );
  const { options_blotter } = useSelector((state: RootState) => state.blotter);
  const { permission } = useSelector((state: RootState) => state.user);

  const [showPositionGridModal, setShowPositionGridModal] =
    useState<boolean>(false);
  const [positionGridTitle, setPositionGridTitle] =
    useState<string>("Position Grid");

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getRoomDate, gameTime } = useRoomTimeContext();
  const { REAL_SECONDS_TO_GAME_DAYS_RATIO } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const { editing, components } = useSelector(
    (state: RootState) => state.layout
  );
  const roomDate = getRoomDate();
  useEffect(() => {
    setPositionGridTitle(
      options_blotter.length
        ? "Position grid"
        : "Position grid (no options yet)"
    );
  }, [options_blotter]);

  const [RulesModal, showRulesModal] = useModal(GameRulesModal);

  return (
    <Container>
      {RulesModal}

      <Modal
        open={showPositionGridModal}
        title={positionGridTitle}
        onCancel={() => {
          setShowPositionGridModal(false);
        }}
        footer={null}
        destroyOnClose
        bodyStyle={{ padding: "2rem 1rem", width: "auto" }}
        width="60vw"
      >
        <PositionGridModal />
      </Modal>

      <Tag>
        {`Game date: `}
        {roomDate?.toDateString() ?? "-"}
      </Tag>

      <Tag>
        {`Game time: `}
        {dateFromSeconds(gameTime)} (
        {Math.floor(gameTime / REAL_SECONDS_TO_GAME_DAYS_RATIO)} days)
      </Tag>

      <Tag color={status === 3 ? "red" : undefined}>
        {STATUS_TEXT[status as keyof typeof STATUS_TEXT]}
      </Tag>

      <Actions>
        <Button
          size="small"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => setShowPositionGridModal(true)}
        >
          Position Grid
          <LineChartOutlined />
        </Button>

        <Button
          size="small"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => showRulesModal({})}
        >
          Game rules
          <InfoCircleOutlined />
        </Button>
        <Button
          size="small"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            history.push("/");
          }}
        >
          Exit session
          <CloseOutlined />
        </Button>
        {editing && <LayoutComponentToggleDropdown />}
        <Button
          size="small"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            dispatch(toggleLayoutEdit());
          }}
        >
          {editing ? "Save changes" : "Change layout"}
          <CloseOutlined />
        </Button>
        {editing && permission === Permission.Admin && (
          <Button
            size="small"
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid green",
              color: "green",
            }}
            onClick={() => {
              try {
                adminService.setScenarioLayout(components, room_id);
                dispatch(toggleLayoutEdit());
                message.info("Layout saved!");
              } catch (e) {
                message.error(`Clould not save layout: ${e}`);
              }
            }}
          >
            Set scenario layout
            <SaveOutlined />
          </Button>
        )}

        {(permission === Permission.Trainer ||
          permission === Permission.Admin ||
          room_type === ROOM_TYPES.SINGLEPLAYER) &&
          status !== 3 && (
            <Button
              size="small"
              onClick={() => {
                if (paused) dispatch(resumeRoom());
                else dispatch(roomPause());
              }}
              style={{
                borderColor: paused ? "#b7eb8f" : "#FFD369",
                color: paused ? "#b7eb8f" : "#FFD369",
              }}
            >
              {paused ? "Resume session" : "Pause session"}
            </Button>
          )}

        {(permission === Permission.Trainer ||
          permission === Permission.Admin ||
          // (room_type === ROOM_TYPES.SINGLEPLAYER && tournament === null)) &&
          room_type === ROOM_TYPES.SINGLEPLAYER) &&
          status !== 3 && (
            <Button
              size="small"
              danger
              onClick={() => {
                dispatch(finishRoom());
                setTimeout(() => {
                  dispatch(requestCBState());
                }, 2000);
              }}
            >
              Finish session
            </Button>
          )}
      </Actions>
    </Container>
  );
};

const LayoutComponentToggleDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeComponents = useSelector(
    (state: RootState) => state.layout.activeComponents
  );

  const handleMenuClick = (e: any) => {
    dispatch(toggleLayoutComponent({ key: e.key }));
  };

  const menu = (
    <Menu>
      {Object.entries(activeComponents).map(([key, isActive]) => (
        <Menu.Item key={key} onClick={handleMenuClick}>
          <Checkbox checked={isActive}>{key}</Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button size="small">
        Toggle Components <DownOutlined />
      </Button>
    </Dropdown>
  );
};

const Container = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  gap: 5px;
  margin-left: auto;
`;

export default GameRoomHeader;

import React, { useState, useRef, useEffect } from "react";
import { Table, message, Radio, Input } from "antd";
import { SaveOutlined } from "@ant-design/icons";

import { useAppDispatch } from "store/store";
import { updateSettings } from "store/settings/actions";
import { updateParam } from "store/centralbank/actions";
import { GeneralParameters, Permission } from "types";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import HoverButton from "components/reusable/HoverButton";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

type Props = {
  settings: GeneralParameters[];
  parameter_tags: string[];
};

const CBParameters: React.FC<Props> = ({ settings, parameter_tags }) => {
  const dispatch = useAppDispatch();
  const permission = useSelector((state: RootState) => state.user.permission);
  const [selectedTag, setSelectedTag] = useState<string>("All");
  const [selectedSettings, setSelectedSettings] = useState<GeneralParameters[]>(
    selectedTag === "All"
      ? settings
      : settings.filter((s) => s.tag === selectedTag)
  );
  const oldSettings = useRef([...settings]);

  const handleSubmit = () => {
    let errors = false;
    settings.forEach(({ value, min_value, max_value, id, code }, index) => {
      if (oldSettings.current[index].value !== value) {
        if (
          permission !== Permission.Admin &&
          (value < min_value || value > max_value)
        ) {
          message.error(
            `Parameter ${code} out of bounds ${min_value} ${max_value}, not setting!`
          );
          errors = true;
        } else if (Number.isNaN(value) || value.toString().length === 0) {
          message.error(`Parameter ${code} is not set, skipping this one!`);
          errors = true;
        } else {
          dispatch(updateSettings({ param_id: id, value }));
        }
      }
    });

    if (!errors) message.success("All modified parameters set!");
  };

  const [inputValues, setInputValues] = useState<Record<number, number>>({});

  useEffect(() => {
    const newInputValues = selectedSettings.reduce<Record<number, number>>(
      (acc, cur) => {
        acc[cur.id] = cur.value || 0;
        return acc;
      },
      {}
    );
    setInputValues(newInputValues);
  }, [selectedSettings]);

  useEffect(() => {
    setSelectedSettings(
      selectedTag === "All"
        ? settings
        : settings.filter((s) => s.tag === selectedTag)
    );
  }, [selectedTag]);

  const handleInputChange = (id: number, val: number) => {
    setInputValues((prev) => ({ ...prev, [id]: val }));
  };

  useEffect(() => {
    setSelectedSettings(
      selectedTag === "All"
        ? settings
        : settings.filter((s) => s.tag === selectedTag)
    );
  }, [settings]);

  const columnData: any = [
    {
      dataIndex: "code",
      title: "Code",
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a: GeneralParameters, b: GeneralParameters) =>
        a.display_index - b.display_index,
    },
    {
      dataIndex: "description",
      title: "Description",
    },
    {
      dataIndex: "min_value",
      title: "Minimum value",
    },
    {
      dataIndex: "max_value",
      title: "Maximum value",
    },
    {
      dataIndex: "value",
      title: "Value",
      width: "100px",
      render: (code: any, record: GeneralParameters, idx: number) => {
        return (
          <Input
            key={record.code + "_input"}
            value={inputValues[record.id] || 0}
            min={record.min_value}
            max={record.max_value}
            onChange={(event) => {
              const e = Number(event.target.value);
              if (Number.isNaN(e)) {
                dispatch(updateParam({ id: record.id, val: 0 }));
              }
              dispatch(updateParam({ id: record.id, val: e || 0 }));
              handleInputChange(record.id, e || 0);
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Column style={{ alignItems: "center", marginBottom: "0.5rem" }}>
        <Row>
          <Radio.Group
            onChange={(e) => setSelectedTag(e.target.value)}
            value={selectedTag}
          >
            <Radio.Button value="All">All</Radio.Button>
            {React.Children.toArray(
              parameter_tags.map((tag) => {
                return <Radio.Button value={tag}>{tag}</Radio.Button>;
              })
            )}
          </Radio.Group>
        </Row>
      </Column>

      <Table
        bordered
        sticky
        rowKey="code"
        columns={columnData}
        dataSource={selectedSettings}
        pagination={false}
        scroll={{ y: 500 }}
      />

      <HoverButton
        Icon={SaveOutlined}
        onClick={handleSubmit}
        tooltipText="Save parameters"
      />
    </div>
  );
};

export default CBParameters;

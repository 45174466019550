import { ROOM_CB_DATA, ROOM_REQUEST_CB_DATA } from "./constants";
import { createAction } from "@reduxjs/toolkit";
import { CBEvent, CentralBankPayload } from "types";

export const getCBState = createAction<CentralBankPayload>(ROOM_CB_DATA);
export const requestCBState = createAction(ROOM_REQUEST_CB_DATA);
export const updateParam = createAction<{ id: number; val: number }>(
  "centralbank/updateParam"
);
export const eventAdded = createAction<any>("macro/eventAdded");
export const eventAddedCB = createAction<{
  event: CBEvent;
}>("macro/eventAddedCB");

import { TrainerState, RoomStatus } from "types";

export const initialState: () => TrainerState = () => ({
  classes: [],
  scenarios: [],
  liveSessions: [],
  historySessions: [],
  assetClasses: [],
  instruments: [],
  scenarioModalShown: false,
  selectedScenario: {
    visible: true,
    id: 0,
    description: "",
    name: "",
    duration: 0,
    created: "",
    forecasts: [],
    price_curves: { "": [] },
    pylons: {},
    parameters: { parameters: [] },
    morning_brief: "",
    news: [],
    swift: [],
    relative_tenors: [],
    yield_curves: { base_yc: [], variable_yc: [] },
    visible_vol_eb_delta_strike_values: [],
    visible_delta_strike_values: [],
    visible_vol_eb_tenors: [],
    visible_tenors: [],
  },
  currentLiveSession: {
    id: 0,
    uuid: "0",
    scenario_id: "",
    trainer: "",
    instrument: "",
    created: "",
    description: "",
    type: 0, // 1 - Practice 2 - Real session
    status: RoomStatus.PENDING,
    duration: 0,
    training_class: {
      assigned_students: [
        {
          id: 0,
          uuid: "0",
          first_name: "",
          last_name: "",
          username: "",
        },
      ],
      created: "",
      id: 0,
      name: "",
      owner: {
        uuid: "0",
        id: 0,
        first_name: "",
        last_name: "",
        username: "",
      },
    },
    trader_stats: [
      {
        client_deals: 0,
        name: "",
        online: false,
        pnl: 0,
        realized_pnl: 0,
        unrealized_pnl: 0,
      },
    ],
  },
});

import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";
import styled from "styled-components";
import { TrainerEditPayload } from "types";
import * as XLSX from "xlsx";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

const EditClassXlsx: React.FC<{
  selectedFile: Blob;
  setSelectedFile: React.Dispatch<React.SetStateAction<Blob>>;
}> = ({ selectedFile, setSelectedFile }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      setSelectedFile(file as Blob);
      return false;
    },
    fileList,
  };

  return (
    <Column style={{ gap: ".5rem", alignItems: "center" }}>
      <Dragger {...props} accept=".csv">
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          The header for the excel document should contain the following cells,
          case and space sensitive:
        </p>
        <p>first name, last name, username, email, password</p>
      </Dragger>
    </Column>
  );
};

export default EditClassXlsx;

import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  // eslint-disable-next-line react/require-default-props
  component?: any;
  // tslint:disable-next-line:no-any
  // eslint-disable-next-line react/require-default-props
  children?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, children, ...rest } = props;

  const user = useSelector((state: RootState) => state.user);
  const { isAuthenticated, email_verified } = user;

  console.log("private route", { email_verified, isAuthenticated });

  if (isAuthenticated && !email_verified)
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect
            to={{
              pathname: "/email-verify",
              state: { from: routeProps.location, user },
            }}
          />
        )}
      />
    );

  console.log("No need for verify", { isAuthenticated });

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        // eslint-disable-next-line no-nested-ternary
        isAuthenticated ? (
          Component ? (
            <Component {...routeProps} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;

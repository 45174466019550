import React from "react";
import {
  ClientCall,
  MATURITY,
  RFQCall,
  StateStrategy,
  StrategyType,
} from "types";
import { numberWithCommas } from "utils/numbers";
import {
  timeBEtoPretty,
  timeUnixToDate,
  timeUnixToDatePretty,
} from "utils/time";
import { PUT_CALL_STR } from "stateConstants";
import { Column } from "components/reusable/GenericStyledComponents";

export const generateClientCallHeader = (
  call: ClientCall,
  sticky_model: number
) => {
  return `${PUT_CALL_STR[call.put_or_call]} ${
    sticky_model ? MATURITY[call.tenor] : timeUnixToDatePretty(call.tenor)
  } / ${call.strike} / x${numberWithCommas(call.amount)} `;
};

export const generateRfqHeader = (call: RFQCall, sticky_model: number) => {
  if (call.strategy.strategy_name === StrategyType.INDIVIDUAL_OPTION) {
    const { options: _optionsObject } = call.strategy;
    const options = Object.values(_optionsObject);
    if (options)
      return (
        <Column>
          {PUT_CALL_STR[options[0].put_or_call]}
          <div>{`${
            sticky_model
              ? MATURITY[options[0].tenor]
              : timeUnixToDatePretty(options[0].tenor)
          } / ${Number(options[0].strike)}-${
            PUT_CALL_STR[options[0].put_or_call]
          } / x${numberWithCommas(options[0].amount)} `}</div>
        </Column>
      );
  }
  if (call.strategy.strategy_name === StrategyType.STRADDLE) {
    const { options: _optionsObject } = call.strategy;
    const options = Object.values(_optionsObject);
    if (options) {
      return (
        <Column>
          STRADDLE
          <div>{`${
            sticky_model
              ? MATURITY[options[0].tenor]
              : timeUnixToDatePretty(options[0].tenor)
          } / ${Number(options[0].strike)} / x${numberWithCommas(
            options[0].amount
          )} `}</div>
        </Column>
      );
    }
  }
  if (call.strategy.strategy_name === StrategyType.STRANGLE) {
    const { options: _optionsObject } = call.strategy;
    const options = Object.values(_optionsObject);

    if (options)
      return (
        <Column>
          STRANGLE
          {Object.values(options).map((option) => {
            return (
              <div>
                {`${
                  sticky_model
                    ? MATURITY[option.tenor]
                    : timeUnixToDatePretty(option.tenor)
                } / ${Number(option.strike)} / x${numberWithCommas(
                  option.amount
                )} `}
              </div>
            );
          })}
        </Column>
      );
  }
  if (
    call.strategy.strategy_name === StrategyType.CALL_CALENDAR_SPREAD ||
    call.strategy.strategy_name === StrategyType.PUT_CALENDAR_SPREAD
  ) {
    const { options: _optionsObject } = call.strategy;
    const options = Object.values(_optionsObject);

    if (options)
      return (
        <Column>
          {call.strategy.strategy_name === StrategyType.CALL_CALENDAR_SPREAD
            ? "Call calendar spread"
            : "Put calendar spread"}
          {Object.values(options).map((option) => {
            return (
              <div>
                {`${
                  sticky_model
                    ? MATURITY[option.tenor]
                    : timeUnixToDatePretty(option.tenor)
                } / ${Number(option.strike)} / x${numberWithCommas(
                  option.amount
                )} `}
              </div>
            );
          })}
        </Column>
      );
  }
};

export const generateOptionHeader = (
  tenor: number,
  strike: number,
  amount: number,
  sticky_model: number,
  put_or_call: 0 | 1
) => {
  return (
    <div>{`${PUT_CALL_STR[put_or_call]} ${
      sticky_model ? MATURITY[tenor] : timeUnixToDatePretty(tenor)
    } / ${strike} / x${numberWithCommas(amount)} `}</div>
  );
};

import React from "react";
import CreateTournament from "components/Tournament/CreateTournament";
import { Column } from "components/reusable/GenericStyledComponents";

const Tournament: React.FC = () => {
  return (
    <Column
      style={{
        alignItems: "center",
        height: "100vh",
        overflowX: "scroll",
        paddingBottom: "5%",
      }}
    >
      <CreateTournament />
    </Column>
  );
};

export default Tournament;

export enum TournamentStatus {
    Created = 0,
    Ongoing = 1,
    Finished = 2
}

export type TournamentScenario = {
    name: string;
    asset_class: string;
    instrument: string;
    duration: string;
}

export type StageMetric = {
    id: number;
    field: string;
}

export type StageMetricPayload = {
    field: string;
}

export type TournamentSession = {
    id: number;
    scenario: TournamentScenario;
    status: TournamentStatus; // Assuming status is similar to TournamentStatus
    metrics: StageMetric[];
    extra: any;
}
export type TournamentSessionPayload = {
    scenario: 0;
    status: TournamentStatus; // Assuming status is similar to TournamentStatus
    metrics: StageMetricPayload[];
}

export type TournamentStage = {
    id: number;
    scenario: TournamentScenario;
    status: TournamentStatus;
    pass_rate: number;
    start_date: number; // Unix timestamp
    sessions: TournamentSession[];
}

export type TournamentStagePayload = {
    status: TournamentStatus;
    pass_rate: number;
    start_date: number; // Unix timestamp
    sessions: TournamentSessionPayload[]; // Assuming sessions are needed in the payload
}

export type Tournament = {
    id: number;
    name: string;
    description: string;
    status: TournamentStatus;
    join_code: string;
    customer_id: number;
    trainer: number;
    current_stage: TournamentStage | null;
    is_player_eliminated: boolean;
    stages: { [key: number]: TournamentStage };
}

export type TournamentPayload = {
    name: string;
    status: TournamentStatus;
    description: string;
    customer_id: number;
    trainer: number;
    stages: TournamentStagePayload[];
}

export type NewsVotedConfimationPayload = {
    event_id: number;
    swift_id: number;
    vote: number;
}

export type TournamentLeaderboardLineType = {
    first_name: string
    id: number
    last_name: string
    total_score: number
    username: string
}

// Factory methods remain essentially the same
export function createEmptyStageMetricPayload(): StageMetricPayload {
    return {
        field: '',
    };
}

export function createEmptyTournamentStagePayload(): TournamentStagePayload {
    return {
        status: TournamentStatus.Created,
        pass_rate: 0,
        start_date: Date.now(),
        sessions: [] // Initialize empty sessions array
    };
}

export function createEmptyTournamentPayload(): TournamentPayload {
    return {
        name: '',
        description: '',
        status: TournamentStatus.Created,
        customer_id: 0,
        trainer: 0,
        stages: []
    };
}

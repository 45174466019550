import { combineReducers } from "@reduxjs/toolkit";

import { socketReducer } from "./socket/reducers";
import userReducer from "./user/reducers";
import eventsReducer from "./events/reducers";
import authReducer from "./auth/reducers";
import traderReducer from "./trader/reducers";
import spotReducer from "./spot/reducers";
import roomReducer from "./room/reducers";
import blotterReducer from "./blotter/reducers";
import clientCallsReducer from "./clientCalls/reducers";
import rfqReducer from "./rfq/reducers";
import volatilityReducer from "./volatility/reducers";
import settingsReducer from "./settings/reducers";
import swiftsReducer from "./swifts/reducers";
import trainerReducer from "./trainer/reducers";
import centralBankSlice from "./centralbank/reducers";
import newpricerSlice from "./newpricer/reducers";
import callQuoteSlice from "./callQuote/reducers";
import layoutSlice from "./layout/reducers";
import lobbySlice from "./lobby/reducers";

const rootReducer = combineReducers({
  socket: socketReducer,
  user: userReducer,
  events: eventsReducer,
  auth: authReducer,
  trader: traderReducer,
  spot: spotReducer,
  room: roomReducer,
  blotter: blotterReducer,
  clientCalls: clientCallsReducer,
  rfq: rfqReducer,
  volatility: volatilityReducer,
  settings: settingsReducer,
  swifts: swiftsReducer,
  trainer: trainerReducer,
  centralbank: centralBankSlice,
  newpricer: newpricerSlice,
  callQuotes: callQuoteSlice,
  layout: layoutSlice,
  lobby: lobbySlice,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
